import { useFormik } from "formik";
import React, { useEffect } from "react";
import { IAddress } from "src/models";
import { useAddress, useAuth } from "src/redux/hooks";
import * as Yup from "yup";
import swal from "sweetalert";
import TextInput from "../TextInput";
import { MapComponent2, MapComponent3 } from "../Map";

interface IProps {
  address?: IAddress;
  title?: string;
}

const EditAddressContent: React.FC<IProps> = ({
  address: currentAddress,
  title,
}) => {
  const styleMarginTop = { marginTop: 10 };
  const { user } = useAuth();
  const { loading, saveAddress, editAddress } = useAddress();
  const initialValues: IAddress = {
    id: 0,
    name: user?.name || "",
    phone: user?.phone || "",
    address: "",
    city: "",
    email: "",
    latitude: 0,
    longitude: 0,
    apartment: "",
    isDefault: false,
    UserId: user?.id!,
    gender: "Mr",
    type: "Home",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phone: Yup.string().required("Phone number is required"),
    city: Yup.string().required("City is required"),
    apartment: Yup.string().required("Apartment detail is required"),
    address: Yup.string().required("Address is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      //save
      if (currentAddress === undefined) {
        //save
        swal({
          title: `Are you sure to save this address?`,
          text: values.address,
          icon: "warning",
          buttons: [true, true],
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            await saveAddress(values, () => {
              swal(`Success! Your address has been saved successfully!`, {
                icon: "success",
              });
            });
          }
        });
      } else {
        swal({
          title: `Are you sure to edit this address?`,
          text: values.address,
          icon: "warning",
          buttons: [true, true],
          dangerMode: true,
        }).then(async (willDelete) => {
          if (willDelete) {
            await editAddress(values, () => {
              swal(`Success! Your address has been edited successfully!`, {
                icon: "success",
              });
            });
          }
        });
      }
    },
  });

  const {
    errors,
    touched,
    values,
    setFieldValue,
    getFieldProps,
    handleSubmit,
  } = formik;

  useEffect(() => {
    if (currentAddress) {
      formik.setValues({
        ...formik.values,
        ...currentAddress,
      });
    } else {
      formik.resetForm();
    }

    return () => {
      formik.resetForm();
    };
  }, [currentAddress]);

  const onAddressChange = (address: IAddress) => {
    formik.setValues({
      ...formik.values,
      ...address,
    });
  };

  const getFieldError = (fieldName: string) => {
    const hasError = (errors as any)[fieldName];
    const hastouched = (touched as any)[fieldName];

    return hasError && hastouched ? (
      <span style={{ fontSize: 12, color: "red" }}>{hasError}</span>
    ) : (
      <></>
    );
  };
  return (
    <div className="row align-items-center">
      <div>
        <h3>{title || "Edit Address"}</h3>
      </div>

      <form onSubmit={handleSubmit} noValidate>
        <div className="row" style={styleMarginTop}>
          <div className="col-lg-6 col-md-6">
            <div className="form-group">
              <label>Gender Type</label>

              <div className="select-box">
                <select className="form-control" {...getFieldProps("type")}>
                  {["Mr", "Mrs", "Miss"].map((gender, index) => (
                    <option value={gender} key={index}>
                      {gender}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6">
            <div className="form-group">
              <label>Address Type</label>

              <div className="select-box">
                <select className="form-control" {...getFieldProps("gender")}>
                  {["Home", "Office", "Other"].map((t, index) => (
                    <option value={t} key={index}>
                      {t}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-6" style={styleMarginTop}>
            <TextInput
              label="Display Name"
              showRequired
              isError={errors.name !== undefined && touched.name !== undefined}
              errorMessage={errors.name}
              {...getFieldProps("name")}
            />
          </div>

          <div className="col-lg-6 col-md-6" style={styleMarginTop}>
            <TextInput
              label="Phone Number"
              showRequired
              isError={
                errors.phone !== undefined && touched.phone !== undefined
              }
              errorMessage={errors.phone}
              {...getFieldProps("phone")}
            />
          </div>

          <div className="col-lg-6 col-md-6" style={styleMarginTop}>
            <TextInput
              label="City"
              showRequired
              isError={errors.city !== undefined && touched.city !== undefined}
              errorMessage={errors.city}
              {...getFieldProps("city")}
            />
          </div>
          <div className="col-lg-6 col-md-6" style={styleMarginTop}>
            <TextInput
              label="House No/Apartment"
              showRequired
              isError={
                errors.apartment !== undefined &&
                touched.apartment !== undefined
              }
              errorMessage={errors.apartment}
              {...getFieldProps("apartment")}
            />
          </div>
          <div
            className="col-lg-12 col-md-12 col-sm-12 "
            style={styleMarginTop}
          >
            <div className="form-group">
              <label>
                Address <span className="required">*</span>
              </label>
              <textarea
                rows={3}
                className="form-control"
                {...getFieldProps("address")}
              />

              {getFieldError("address")}
            </div>
          </div>

          {/* <div
            className="col-lg-2 col-md-2 col-sm-2"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              type="button"
              className="default-btn"
              data-bs-toggle="modal"
              data-bs-target="#mapModal"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <i className="flaticon-trolley"></i> Map
            </button>
          </div> */}

          <div className="col-lg-12 col-md-12" style={{ marginTop: 10 }}>
            <MapComponent3
              address={currentAddress}
              onAddressChange={onAddressChange}
            />
          </div>
          <div className="col-lg-12 col-md-12" style={{ marginTop: 20 }}>
            <div className="col-md-12">
              <button
                disabled={loading}
                type="submit"
                className="default-btn"
                name="submit"
              >
                {currentAddress ? "Edit Address" : "Save new address"}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditAddressContent;
