/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Fragment } from "react";
import { PageTitleArea } from "src/components";
import { useDeliveryCharges } from "src/redux/hooks";
import "./style.css";
const About = () => {
  const {
    deliveryCharge: { minAmount, charges },
  } = useDeliveryCharges();
  return (
    <Fragment>
      <PageTitleArea
        title="About"
        pages={[{ to: "/", title: "Home" }, { title: "About" }]}
      />
      <section className="about-area ptb-70">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="about-content">
                <span className="sub-title">We believe in less but better</span>

                <p>
                  Through Najeeb pharmacy, getting the desired medicine in
                  Islamabad is simple. Download the easy to use Najeeb Pharmacy
                  app/website to conveniently order your medicines, at any time
                  as we provide 24/7 day &amp; night express delivery service at
                  your doorstep. Just tap the app or website link search your
                  required medications or upload your prescription, your order
                  will be delivered at your doorstep. Use Najeeb Pharmacy
                  healthcare online service to order or Refill your medicines,
                  Store Your Medical Records, Track Your Order &amp; Cash on
                  delivery.Free attainable Health Information. Get an in depth
                  vision of medicine that is either needed or prescribed by
                  doctor. Simply upload the prescription our pharmacist will
                  analyze as they receive it, your medicines will be dispatched
                  or you can consult pharmacist online through Najeeb Pharmacy
                  app or website. Check our Pharmacy app, order medicines
                  through application in Islamabad with 24/7 free delivery. We
                  have detailed drug information with picture and its uses in a
                  convenient way. Medicine Price are shown accurately. Explore
                  the wealth of trustworthy information regarding health issues
                  that&#39;s designed to help you, to make better decisions
                  about your health. When you are running out of your regular
                  medicines, Najeeb pharmacy app will send you the medicines
                  with express delivery service without any additional delivery
                  charges to restock your medicines. It’s easy to use website or
                  app search medicines, explore health issues by condition.
                </p>
                {/* <div className="quotes-box">
                  <span>A message from our founders</span>
                  <p>
                    We’ve been able to create a platform that customers can get
                    their hands on whenever they want. It is always our
                    intention to serve you.{" "}
                  </p>
                </div> */}
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="about-image">
                <img src="/assets/img/about/about-img1.jpg" alt="image" />

                <div className="shape">
                  <img src="/assets/img/about/about-shape1.png" alt="image" />
                  <img src="/assets/img/about/about-shape2.png" alt="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="funfacts-area pb-40">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-3 col-6">
              <div className="single-funfacts-box">
                <h3>10M</h3>
                <span>Happy Clients</span>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-3 col-6">
              <div className="single-funfacts-box">
                <h3>20M</h3>
                <span>Global Customers</span>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-3 col-6">
              <div className="single-funfacts-box">
                <h3>99+</h3>
                <span>Experts Employee</span>
              </div>
            </div>

            <div className="col-lg-3 col-md-3 col-sm-3 col-6">
              <div className="single-funfacts-box">
                <h3>30+</h3>
                <span>Awards Win</span>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="why-choose-us-area pb-40">
        <div className="container">
          <div className="section-title">
            <h2>Why Choose Us</h2>
          </div>

          <div className="row">
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="single-why-choose-box">
                <div className="icon">
                  <i className="flaticon-fast-delivery"></i>
                </div>
                <h3>Free Home Delivery</h3>
                <p>
                  No delivery charges on any kind of items only for order RS{" "}
                  {minAmount} and above, order below RS {minAmount} will be
                  charged RS {charges}. We keep our promises and our first
                  promise is to deliver your order on time!
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-md-6">
              <div className="single-why-choose-box">
                <div className="icon">
                  <i className="flaticon-secure-payment"></i>
                </div>
                <h3>Money back guarantee</h3>
                <p>
                  Don’t worry! If you get a faulty product, we will replace it
                  for you or you can get your money back!
                </p>
              </div>
            </div>

            <div className="col-lg-4 col-sm-6 col-md-6 offset-lg-0 offset-sm-3 offset-md-3">
              <div className="single-why-choose-box">
                <div className="icon">
                  <i className="flaticon-supply-chain-management"></i>
                </div>
                <h3>All at one Place</h3>
                <p>
                  Have an entire supermarket at your fingertips. Shop desired
                  products from a wide variety of medicines all at a single
                  click of button.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="feedback-area ptb-70 bg-f7f8fa">
        <div className="container">
          <div className="section-title">
            <h2>What Our Members Are Saying</h2>
          </div>

          <div className="feedback-slides-two owl-carousel owl-theme">
            <div className="single-feedback-box">
              <p>
                “I ordered Saftey equipment at Drodo site. Within 24 hours of
                ordering, they delivered my products, the quality of the product
                is very good. I am very happy! ”
              </p>
              <div className="client-info">
                <div className="d-flex align-items-center justify-content-center">
                  <img src="/assets/img/user1.jpg" alt="image" />
                  <div className="title">
                    <h3>Dominick Eakin</h3>
                    <span>Counselor</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="single-feedback-box">
              <p>
                “I ordered Saftey equipment at Drodo site. Within 24 hours of
                ordering, they delivered my products, the quality of the product
                is very good. I am very happy! ”
              </p>
              <div className="client-info">
                <div className="d-flex align-items-center justify-content-center">
                  <img src="/assets/img/user2.jpg" alt="image" />
                  <div className="title">
                    <h3>Sarah Taylor</h3>
                    <span>Counselor</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="single-feedback-box">
              <p>
                “I ordered Saftey equipment at Drodo site. Within 24 hours of
                ordering, they delivered my products, the quality of the product
                is very good. I am very happy! ”
              </p>
              <div className="client-info">
                <div className="d-flex align-items-center justify-content-center">
                  <img src="/assets/img/user3.jpg" alt="image" />
                  <div className="title">
                    <h3>James Andy</h3>
                    <span>Counselor</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="doctors-area pt-70 pb-40">
        <div className="container">
          <div className="section-title">
            <h2>Meet Our Dedicated Doctors</h2>
          </div>

          <div className="row">
            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-doctors-box">
                <div className="image">
                  <img src="/assets/img/doctors/doctors-img1.jpg" alt="image" />

                  <ul className="social">
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-facebook-square"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-instagram-alt"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-linkedin-square"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-pinterest"></i>
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="content">
                  <h3>Lauren Wells</h3>
                  <span>Director</span>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-doctors-box">
                <div className="image">
                  <img src="/assets/img/doctors/doctors-img2.jpg" alt="image" />

                  <ul className="social">
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-facebook-square"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-instagram-alt"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-linkedin-square"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-pinterest"></i>
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="content">
                  <h3>Francis Milligan</h3>
                  <span>CEO & Founder</span>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-doctors-box">
                <div className="image">
                  <img src="/assets/img/doctors/doctors-img3.jpg" alt="image" />

                  <ul className="social">
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-facebook-square"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-instagram-alt"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-linkedin-square"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-pinterest"></i>
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="content">
                  <h3>Kristin Taylor</h3>
                  <span>Administrator</span>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-doctors-box">
                <div className="image">
                  <img src="/assets/img/doctors/doctors-img4.jpg" alt="image" />

                  <ul className="social">
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-facebook-square"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-instagram-alt"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-linkedin-square"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" target="_blank">
                        <i className="bx bxl-pinterest"></i>
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="content">
                  <h3>Henry Woods</h3>
                  <span>Chief Officer</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </Fragment>
  );
};

export default About;
