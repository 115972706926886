import React from "react";
import { Link } from "react-router-dom";
import config from "src/config/config";
import Utils from "src/helper";
import { IBrand } from "src/models";

interface IProps {
  brand: IBrand;
  showTitle?: boolean;
}

const BrandItem: React.FC<IProps> = ({ brand, showTitle }) => {
  return (
    <div className="single-brands-item">
      <Link to={`/products?brand=${brand.id}`} className="d-block">
        <img
          src={Utils.getImagesPath(brand.image)}
          alt={brand.title}
          style={{ height: 68 }}
        />
      </Link>
      {showTitle && <h3>{brand.title}</h3>}
    </div>
  );
};

export default BrandItem;
