import React from "react";
import { useNavigate } from "react-router-dom";
import Utils from "src/helper";
import { IDrug, ISaleType } from "src/models";
import { useCart } from "src/redux/hooks";
import swal from "sweetalert";
interface IProps {
  drug: IDrug;
  saleType: ISaleType;
  quantity: string;
}
const BuyNow: React.FC<IProps> = ({ drug, saleType, quantity }) => {
  const navigate = useNavigate();
  const { addCartItem, isLoggedIn } = useCart();
  const addToCart = () => {
    const q = Utils.getIntQuantity(quantity);

    addCartItem({
      drug: drug,
      quantity: q,
      saleType,
    });
    navigate(isLoggedIn ? "/checkout" : "/login");
    // swal(`Success! "${drug.name}" is added to your cart!`, {
    //   icon: "success",
    // });
  };
  return (
    <button
      className="default-btn"
      style={{ width: "100%" }}
      onClick={addToCart}
    >
      Buy it now!
    </button>
  );
};

export default BuyNow;
