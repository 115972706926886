import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import EditAddressContent from 'src/components/AddressModal/EditAddressContent'
import { IAddress } from 'src/models'
import { useAddress } from 'src/redux/hooks'

const EditAddress = () => {
    const {id} = useParams()
    const addressId= parseInt(""+id)
    const {findById , loading ,isLoaded, addresses} = useAddress()
    const [address , setAddress] = useState<IAddress>()

    useEffect(() => {
        (async () => {
          if(!isLoaded){
            await findById(addressId)
          }else{
            const existingaddress = addresses.find(
              (address: IAddress) => address.id === addressId
            );
    
            setAddress(existingaddress)
          }
        })()
      }, [addressId, isLoaded]);
    
      useEffect(() => {
        const address = addresses.find((o) => o.id ===addressId)
        setAddress(address)
      } , [addresses])
  return (
    <div className="bhoechie-tab-content active">
        <EditAddressContent address={address}/>
    </div>
  )
}

export default EditAddress