import { IAxiosResponse, IBrand, IBrandPagination } from "src/models";
import axiosInstance from "./axios";

export default class BrandService {
  private static readonly url: string = "/pharmacy/brand";
  public static async findAll(): Promise<IAxiosResponse<IBrand[]>> {
    const response = await axiosInstance.get(`${this.url}`);
    return response.data;
  }

  public static async findAllByPagination(
    page: number,
    size: number
  ): Promise<IAxiosResponse<IBrandPagination>> {
    let pathUrl = `${this.url}?page=${page}&size=${size}`;
    const response = await axiosInstance.get(pathUrl);
    return response.data;
  }

  public static async findAllTopBrands(
    size: number
  ): Promise<IAxiosResponse<IBrandPagination>> {
    let pathUrl = `${this.url}?page=1&size=${size}`;
    const response = await axiosInstance.get(pathUrl);
    return response.data;
  }
}
