/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

const PrivacyPolicy = () => {
  return (
    <section className="privacy-policy-area ptb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="privacy-policy-content">
              {/* <img src="assets/img/blog/blog-img2.jpg" alt="image" /> */}
              <p>
                <i>This Privacy Policy was last updated on August 01, 2022.</i>
              </p>

              <p>
                Najeeb Pharmacy online service takes user privacy very
                seriously, using location information to provide our best
                services that the user request, and the Gallery permission for
                uploading the user profile picture this privacy terms and
                statement applies to the products Najeeb Pharmacy App and
                website provide to our users. Najeeb Pharmacy is very seriously
                committed to protecting the user’s privacy of any kind of info.
                For this, Najeeb Pharmacy has made a Privacy Policy terms.
                Please take a look to acquaint yourself.
              </p>
              <p>
                These Privacy Policy of Service ("Policy") to help you
                understand our policy and procedure for collection, use and
                disclosure of personal data accessed by us from users of Website
                and services or software provided by Najeeb Pharmacy.
              </p>
              <p>
                Please see the "Company" Terms of Service, so you may find more
                information about how to manage the data you choose to submit to
                the "Najeeb Pharmacy" that provides free delivery in Islamabad.
              </p>
              <p>
                The "Policy" is applicable to the data submitted by users to us
                via the "Najeeb Mart &amp; Pharmacy - Free Delivery Islamabad"
                and the open information displayed by us on the user interface
                when the user accesses to the "Najeeb Pharmacy".
              </p>
              <p>
                The "Policy" may be amended from time to time. In the case of
                any material change in the Policy, we will post the new Policy
                on the "Website" or notify you of the same via the "Najeeb
                Pharmacy Application". We suggest that you should view the
                "Policy" periodically with a view to confirming whether any
                change in the Policy takes place.
              </p>
              <h3>Permission Information</h3>
              <p>
                Najeeb Pharmacy collect some information like permissions about
                the services that we acquire for the use and how to use them,
                such as when you aspect and interact with our context and
                content. Najeeb Pharmacy collects some of user’s information
                (such as Location, and Gallery). Najeeb Pharmacy not share that
                personal info and any other information with third parties.
              </p>
              <p>
                For the purpose of the Najeeb Pharmacy Services, any data shared
                with Najeeb Pharmacy will only be used to get the location for
                the delivery of services and the gallery permission for user to
                upload the profile picture. Entirely his/her option. See further
                details in the Najeeb Pharmacy Privacy Policy.
              </p>
              <p>
                The Najeeb Pharmacy Privacy Policy further describes what
                information You may share with Najeeb Pharmacy and other Users
                how they use and share such information.
              </p>
              <h3>Data Collected and Usage</h3>
              <p>
                When you register, download, install, access and use the
                Service, the following Collected Information regarding the
                Contacts will be collected and processed by Najeeb Pharmacy
                Islamabad to provide, user a best search result:
              </p>
              <ul>
                <li>To improve the Service</li>
                <li>
                  To provide you with information and services that are
                  available only to registered users of the Service
                </li>
                <li>
                  To provide you with information about the Service and allow us
                  to respond to your queries
                </li>
              </ul>
              <p>
                You further agree that, You participate in the Enhanced Search
                functionality, we may collect, use location information for
                providing best services of delivery and get permission from the
                user of gallery (Gallery permission) so the user can upload his
                profile picture. In accordance with the Najeeb Pharmacy Privacy
                Policy order to provide the Services. By allowing Location
                Information &amp; Gallery excess to be collected, you give
                Najeeb Pharmacy a right to use that Information as a part of the
                Service and you guarantee that you have any and all permissions
                required with us.
              </p>
              <h3>How We Use Collected Data</h3>
              <p>
                What do we do with the information we collect? The answer is:
                Provide you with the best products and services that we
                relentlessly improve. Here are the ways we do that:
              </p>
              <ul>
                <li>
                  We use all the information we have to help us operate,
                  provide, improve, understand, customize, support, and market
                  our Services.
                </li>
                <li>
                  Location data we are collecting to provide best search result
                  to the users on our app
                </li>
                <li>
                  Gallery images we are collecting to provide profile picture
                  feature to the users on our app
                </li>
                <li>
                  the "Compiled Data" and "Non-identifiable Data" the "Company"
                  will not share with any third party for any purpose, its
                  Completely secure.
                </li>
              </ul>
              <h3>Third-party disclosure</h3>
              <p>
                We do not sell, trade, or otherwise transfer to outside parties
                your Personally Identifiable Information, unless we provide
                users with advance notice. The "Company" will keep the "Compiled
                Data", "Non-identifiable Data", the "Company" will not share
                your "Personal Data" with any third party for any purpose.
              </p>
              <p>
                In order to improve the "Najeeb Pharmacy Service" quality, you
                agree that the "Company" may make your "Personal Data" available
                to the external technical developers who have entered into the
                non-disclosure agreement with the "Company".
              </p>
              <p>
                This does not include website hosting partners and other parties
                who assist us in operating our website, conducting our business,
                or serving our users, so long as those parties agree to keep
                this information confidential. We may also release information
                when its release is appropriate to comply with the law, enforce
                our site policies, or protect ours or other's rights, property
                or safety.
              </p>

              <h3>Safety</h3>
              <p>
                You acknowledge and agree that Najeeb Pharmacy Islamabad uses
                your location information and Gallery permission for providing
                the best services and features as described above for purposes
                consistent with this Privacy Policy. We take all reasonable
                precautions to protect personal information from misuse, loss
                and unauthorized access. Najeeb Pharmacy has implemented
                physical, electronic, and procedural safeguards in order to
                protect the information, including that the information will be
                stored on secured servers and protected by secured networks to
                which access is limited to a few authorized employees and
                personnel. However, no method of transmission over the Internet
                or method of electronic storage is 100% secure.
              </p>
              <p>
                The "Company" cares about the protection of your "Personal
                Data". In order to protect your data from unauthorized access,
                Najeeb Pharmacy will apply the safety standards.
              </p>
              <h3>Alteration or deletion of your data</h3>
              <p>
                The user who wishes to delete or hide his "Profile Picture"
                shall e-mail to experiatech.com@gmail.com and then follow the
                procedure and file a complaint per instruction.
              </p>
              <h3>Child Policy</h3>
              <p>
                Najeeb Pharmacy will not collect the personal information of any
                children less than 13 years old intentionally. If the parents or
                guardians of any children find that the children have already
                provided their personal information to Najeeb Pharmacy without
                approval from them, they shall contact us via
                xperiatech.com@gmail.com
              </p>
            </div>
          </div>

          {/* <div className="col-lg-4 col-md-12">
            <aside className="widget-area">
              <section className="widget widget_insight">
                <ul>
                  <li>
                    <a href="about.html">About Us</a>
                  </li>
                  <li>
                    <a href="contact.html">Contact Us</a>
                  </li>
                  <li>
                    <a href="purchase-guide.html">Purchase Guide</a>
                  </li>
                  <li className="active">
                    <a href="privacy-policy.html">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="terms-of-service.html">Terms of Service</a>
                  </li>
                </ul>
              </section>
            </aside>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
