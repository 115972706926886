/* eslint-disable @typescript-eslint/no-unused-vars */
import { IRootState } from "..";
import { IBrand, IBrandPagination } from "src/models";
import * as Types from "src/redux/types";

export default class BrandActions {
  public static load =
    (pageData: IBrandPagination) =>
    async (
      dispatch: DispatchType<Action<IBrandPagination>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.BRAND_PAGE_LOAD,
        payload: pageData,
      });

  public static topBrandsLoad =
    (brands: IBrand[]) =>
    async (
      dispatch: DispatchType<Action<IBrand[]>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.TOP_BRAND_LOAD,
        payload: brands,
      });
}
