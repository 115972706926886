/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from "react";
import { PageTitleArea } from "src/components";
import Utils from "src/helper";
import { useAuth } from "src/redux/hooks";
import OtpInput from "react-otp-input";

const Login = () => {
  const {
    login,
    loading,
    loginResponse,
    sendOTP,
    setLoginResponse,
    setLoginUser,
  } = useAuth();
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isValid, setValid] = useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");
  const [toggleResend, setToggleResend] = useState(false);
  const [seconds, setSeconds] = useState<number>(60);
  const [generatedOTP, setGeneratedOTP] = useState<string>();

  useEffect(() => {
    setValid(Utils.isValidatePhoneNumber(phoneNumber));
  }, [phoneNumber]);

  const onSubmitHandler = async () => {
    if (isValid) {
      const number = Utils.getValidPhoneNumberWithCountryCode(phoneNumber);
      await login(number.fullNumber);
      //navigation.navigate('VerifyPhone');
    }
  };

  useEffect(() => {
    if (loginResponse) {
      sendOTPTopPhoneNumber(loginResponse.response?.phone);
    }
  }, [loginResponse]);

  React.useEffect(() => {
    if (seconds > 0)
      var timer = setTimeout(() => setSeconds(seconds - 1), 1000);
    else setToggleResend(true);

    return () => {
      clearInterval(timer);
    };
  }, [seconds]);

  const sendOTPTopPhoneNumber = async (phoneNumber?: string) => {
    const gotp = Utils.generateOTP();
    setGeneratedOTP(gotp);
    setSeconds(60);
    await sendOTP(phoneNumber!, gotp);
  };

  const resendOTP = () => {
    if (loginResponse) {
      sendOTPTopPhoneNumber(loginResponse.response?.phone);
    }
  };
  return (
    <Fragment>
      <PageTitleArea
        title="Login"
        pages={[{ to: "/", title: "Home" }, { title: "Login" }]}
      />
      <section className="profile-authentication-area ptb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 offset-lg-3">
              <div className="login-form">
                <h2>
                  {loginResponse === undefined ? "Login" : "Verify Phone "}
                </h2>

                {loginResponse === undefined ? (
                  <form>
                    <div className="form-group">
                      <label>Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        required
                        name="phonenumber"
                        placeholder="Enter your phone number *"
                        value={phoneNumber}
                      />
                      {!isValid && (
                        <span className="error">Invalid phone number</span>
                      )}
                    </div>
                    <button
                      type="button"
                      disabled={loading || !isValid}
                      onClick={onSubmitHandler}
                    >
                      Log In
                    </button>
                  </form>
                ) : (
                  <form>
                    <div className="form-group">
                      <label>Please enter 4 digit code sent on number</label>
                      <OtpInput
                        separator={
                          <span>
                            <strong>.</strong>
                          </span>
                        }
                        value={otp}
                        onChange={(text: string) => {
                          setOtp(text);
                          if (text === generatedOTP) {
                            setLoginUser(
                              loginResponse.response,
                              loginResponse.accessToken
                            );
                          }
                        }}
                        inputStyle={{
                          width: "4rem",
                          height: "3rem",
                          margin: "0 1rem",
                          fontSize: "2rem",
                          borderRadius: 4,
                          border: "1px solid rgba(0,0,0,0.3)",
                          color: "#000",
                          textAlign: "center",
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <p>
                        Resend verification code :{" "}
                        {toggleResend ? (
                          <a onClick={resendOTP} style={{ color: "green" }}>
                            Resend
                          </a>
                        ) : (
                          <a style={{ color: "green" }}>{`${seconds} sec`}</a>
                        )}{" "}
                      </p>
                    </div>
                    <button
                      type="button"
                      disabled={loading}
                      onClick={() => setLoginResponse(undefined)}
                    >
                      Use Other Number
                    </button>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Login;
