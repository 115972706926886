import { IAddress, IAxiosResponse } from "src/models";
import axiosInstance from "./axios";

export default class AddressService {
  private static readonly url: string = "/address";
  public static async findAll(): Promise<IAxiosResponse<IAddress[]>> {
    const response = await axiosInstance.get(this.url);
    return response.data;
  }

  public static async save(
    address: IAddress
  ): Promise<IAxiosResponse<IAddress>> {
    const response = await axiosInstance.post(this.url, address);
    return response.data;
  }

  public static async removeById(
    id: number
  ): Promise<IAxiosResponse<IAddress>> {
    const response = await axiosInstance.delete(`${this.url}/${id}`);
    return response.data;
  }

  public static async findById(
    id: number
  ): Promise<IAxiosResponse<IAddress>> {
    const response = await axiosInstance.get(`${this.url}/${id}`);
    return response.data;
  }

  public static async editAddress(
    address: IAddress
  ): Promise<IAxiosResponse<IAddress>> {
    const response = await axiosInstance.put(this.url, address);
    return response.data;
  }

  public static async setDefaultAddress(
    address: IAddress
  ): Promise<IAxiosResponse<IAddress>> {
    const response = await axiosInstance.post(`${this.url}/default`, address);
    return response.data;
  }

  public static async getDefaultAddress(): Promise<IAxiosResponse<IAddress>> {
    const response = await axiosInstance.get(`${this.url}/default`);
    return response.data;
  }
}
