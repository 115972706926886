/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "src/redux/hooks";

const TopHeader = () => {
  const { isLoggedIn } = useAuth();
  return (
    <div className="top-header">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-8 col-md-9">
            <ul className="top-header-contact-info">
              <li>
                <i className="bx bx-phone-call"></i>{" "}
                <a href="tel:0512107676">051-2107676</a>
              </li>
              <li>
                <i className="bx bx-map"></i>{" "}
                <a href="#" target="_blank">
                  Ground Floor, jamal plaza, F-10 Markaz, islamabd. Markaz,
                  Pakistan
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-md-3">
            <ul className="top-header-menu">
              {/* <li>
                <div className="dropdown language-switcher d-inline-block">
                  <button
                    className="dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      src="/assets/img/flag/us-flag.jpg"
                      className="shadow-sm"
                      alt="image"
                    />
                    <span>
                      Eng <i className="bx bx-chevron-down"></i>
                    </span>
                  </button>

                  <div className="dropdown-menu">
                    <a
                      href="#"
                      className="dropdown-item d-flex align-items-center"
                    >
                      <img
                        src="/assets/img/flag/germany-flag.jpg"
                        className="shadow-sm"
                        alt="flag"
                      />
                      <span>Ger</span>
                    </a>
                    <a
                      href="#"
                      className="dropdown-item d-flex align-items-center"
                    >
                      <img
                        src="/assets/img/flag/france-flag.jpg"
                        className="shadow-sm"
                        alt="flag"
                      />
                      <span>Fre</span>
                    </a>
                    <a
                      href="#"
                      className="dropdown-item d-flex align-items-center"
                    >
                      <img
                        src="/assets/img/flag/spain-flag.jpg"
                        className="shadow-sm"
                        alt="flag"
                      />
                      <span>Spa</span>
                    </a>
                    <a
                      href="#"
                      className="dropdown-item d-flex align-items-center"
                    >
                      <img
                        src="/assets/img/flag/russia-flag.jpg"
                        className="shadow-sm"
                        alt="flag"
                      />
                      <span>Rus</span>
                    </a>
                    <a
                      href="#"
                      className="dropdown-item d-flex align-items-center"
                    >
                      <img
                        src="/assets/img/flag/italy-flag.jpg"
                        className="shadow-sm"
                        alt="flag"
                      />
                      <span>Ita</span>
                    </a>
                  </div>
                </div>
              </li>

              <li>
                <div className="dropdown currency-switcher d-inline-block">
                  <button
                    className="dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <span>
                      USD <i className="bx bx-chevron-down"></i>
                    </span>
                  </button>

                  <div className="dropdown-menu">
                    <a href="#" className="dropdown-item">
                      USD
                    </a>
                    <a href="#" className="dropdown-item">
                      INR
                    </a>
                    <a href="#" className="dropdown-item">
                      GBP
                    </a>
                  </div>
                </div>
              </li> */}

              <li>
                <Link to={isLoggedIn ? "/my-account" : "/login"}>
                  My Account
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
