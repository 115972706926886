import React from "react";
import { Link } from "react-router-dom";
import { PriceText } from "src/components";
import config from "src/config/config";
import Utils from "src/helper";
import { ICartItem } from "src/models";
import { useContextProvider } from "src/provider/AppContextProvider";
import { useCart } from "src/redux/hooks";
interface IProps {
  item: ICartItem;
}

const CartModalItem: React.FC<IProps> = ({ item }) => {
  const { setDrug } = useContextProvider();
  const { removeItem } = useCart();
  const {
    drug: { id, name, images, measurement },
    saleType,
    quantity,
  } = item;
  const linkTo = `/products/${id}`;

  return (
    <div className="products-cart d-flex align-items-center">
      <div className="products-image">
        <Link to={linkTo} onClick={() => setDrug?.(item.drug)}>
          <img src={config.BASE_URL + images[0].path} alt="image" />
        </Link>
      </div>

      <div className="products-content">
        <h3>
          <Link to={linkTo} onClick={() => setDrug?.(item.drug)}>
            {name}
          </Link>
        </h3>
        <div className="products-price">
          <span>{quantity}</span>
          <span>x</span>
          {saleType.discount > 0 && (
            <span
              className="old-price"
              style={{ textDecoration: "line-through" }}
            >
              Rs{saleType.price}
            </span>
          )}
          <span className="price">
            Rs
            {Utils.getDicountPrice(saleType.price, saleType.discount)}
          </span>
        </div>
      </div>
      <a href="#" className="remove-btn" onClick={() => removeItem(id)}>
        <i className="bx bx-trash"></i>
      </a>
    </div>
  );
};

export default CartModalItem;
