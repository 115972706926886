/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Faq = () => {
  return (
    <section className="faq-area ptb-70">
      <div className="container">
        <div className="tab faq-accordion-tab">
          <ul className="tabs d-flex flex-wrap justify-content-center">
            <li>
              <a href="#">
                <i className="bx bx-flag"></i> <span>Getting Started</span>
              </a>
            </li>

            <li>
              <a href="#">
                <i className="bx bxs-badge-dollar"></i>{" "}
                <span>Pricing & Planes</span>
              </a>
            </li>

            <li>
              <a href="#">
                <i className="bx bx-shopping-bag"></i>{" "}
                <span>Sales Question</span>
              </a>
            </li>

            <li>
              <a href="#">
                <i className="bx bx-book-open"></i> <span>Usage Guides</span>
              </a>
            </li>

            <li>
              <a href="#">
                <i className="bx bx-info-circle"></i> <span>General Guide</span>
              </a>
            </li>
          </ul>

          <div className="tab-content">
            <div className="tabs-item">
              <div className="faq-accordion">
                <ul className="accordion">
                  <li className="accordion-item">
                    <a
                      className="accordion-title active"
                      href="javascript:void(0)"
                    >
                      <i className="bx bx-chevron-down"></i>
                      Q1. What is Data Science? List the differences between
                      supervised and unsupervised learning.
                    </a>

                    <div className="accordion-content show">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </li>

                  <li className="accordion-item">
                    <a className="accordion-title" href="javascript:void(0)">
                      <i className="bx bx-chevron-down"></i>
                      Q2. What is Selection Tracer.com?
                    </a>

                    <div className="accordion-content">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </li>

                  <li className="accordion-item">
                    <a className="accordion-title" href="javascript:void(0)">
                      <i className="bx bx-chevron-down"></i>
                      Q3. What is tracer-variance trade-off?
                    </a>

                    <div className="accordion-content">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </li>

                  <li className="accordion-item">
                    <a className="accordion-title" href="javascript:void(0)">
                      <i className="bx bx-chevron-down"></i>
                      Q4. What is a confusion matrix?
                    </a>

                    <div className="accordion-content">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </li>

                  <li className="accordion-item">
                    <a className="accordion-title" href="javascript:void(0)">
                      <i className="bx bx-chevron-down"></i>
                      Q5. What is the difference between “long” and “wide”
                      format data?
                    </a>

                    <div className="accordion-content">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="tabs-item">
              <div className="faq-accordion">
                <ul className="accordion">
                  <li className="accordion-item">
                    <a className="accordion-title" href="javascript:void(0)">
                      <i className="bx bx-chevron-down"></i>
                      Q1. What is Data Science? List the differences between
                      supervised and unsupervised learning.
                    </a>

                    <div className="accordion-content">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </li>

                  <li className="accordion-item">
                    <a className="accordion-title" href="javascript:void(0)">
                      <i className="bx bx-chevron-down"></i>
                      Q2. What is Selection Tracer.com?
                    </a>

                    <div className="accordion-content">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </li>

                  <li className="accordion-item">
                    <a className="accordion-title" href="javascript:void(0)">
                      <i className="bx bx-chevron-down"></i>
                      Q3. What is tracer-variance trade-off?
                    </a>

                    <div className="accordion-content">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </li>

                  <li className="accordion-item">
                    <a className="accordion-title" href="javascript:void(0)">
                      <i className="bx bx-chevron-down"></i>
                      Q4. What is a confusion matrix?
                    </a>

                    <div className="accordion-content">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </li>

                  <li className="accordion-item">
                    <a className="accordion-title" href="javascript:void(0)">
                      <i className="bx bx-chevron-down"></i>
                      Q5. What is the difference between “long” and “wide”
                      format data?
                    </a>

                    <div className="accordion-content">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="tabs-item">
              <div className="faq-accordion">
                <ul className="accordion">
                  <li className="accordion-item">
                    <a className="accordion-title" href="javascript:void(0)">
                      <i className="bx bx-chevron-down"></i>
                      Q1. What is Data Science? List the differences between
                      supervised and unsupervised learning.
                    </a>

                    <div className="accordion-content">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </li>

                  <li className="accordion-item">
                    <a className="accordion-title" href="javascript:void(0)">
                      <i className="bx bx-chevron-down"></i>
                      Q2. What is Selection Tracer.com?
                    </a>

                    <div className="accordion-content">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </li>

                  <li className="accordion-item">
                    <a className="accordion-title" href="javascript:void(0)">
                      <i className="bx bx-chevron-down"></i>
                      Q3. What is tracer-variance trade-off?
                    </a>

                    <div className="accordion-content">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </li>

                  <li className="accordion-item">
                    <a className="accordion-title" href="javascript:void(0)">
                      <i className="bx bx-chevron-down"></i>
                      Q4. What is a confusion matrix?
                    </a>

                    <div className="accordion-content">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </li>

                  <li className="accordion-item">
                    <a className="accordion-title" href="javascript:void(0)">
                      <i className="bx bx-chevron-down"></i>
                      Q5. What is the difference between “long” and “wide”
                      format data?
                    </a>

                    <div className="accordion-content">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="tabs-item">
              <div className="faq-accordion">
                <ul className="accordion">
                  <li className="accordion-item">
                    <a className="accordion-title" href="javascript:void(0)">
                      <i className="bx bx-chevron-down"></i>
                      Q1. What is Data Science? List the differences between
                      supervised and unsupervised learning.
                    </a>

                    <div className="accordion-content">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </li>

                  <li className="accordion-item">
                    <a className="accordion-title" href="javascript:void(0)">
                      <i className="bx bx-chevron-down"></i>
                      Q2. What is Selection Tracer.com?
                    </a>

                    <div className="accordion-content">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </li>

                  <li className="accordion-item">
                    <a className="accordion-title" href="javascript:void(0)">
                      <i className="bx bx-chevron-down"></i>
                      Q3. What is tracer-variance trade-off?
                    </a>

                    <div className="accordion-content">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </li>

                  <li className="accordion-item">
                    <a className="accordion-title" href="javascript:void(0)">
                      <i className="bx bx-chevron-down"></i>
                      Q4. What is a confusion matrix?
                    </a>

                    <div className="accordion-content">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </li>

                  <li className="accordion-item">
                    <a className="accordion-title" href="javascript:void(0)">
                      <i className="bx bx-chevron-down"></i>
                      Q5. What is the difference between “long” and “wide”
                      format data?
                    </a>

                    <div className="accordion-content">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>

            <div className="tabs-item">
              <div className="faq-accordion">
                <ul className="accordion">
                  <li className="accordion-item">
                    <a className="accordion-title" href="javascript:void(0)">
                      <i className="bx bx-chevron-down"></i>
                      Q1. What is Data Science? List the differences between
                      supervised and unsupervised learning.
                    </a>

                    <div className="accordion-content">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </li>

                  <li className="accordion-item">
                    <a className="accordion-title" href="javascript:void(0)">
                      <i className="bx bx-chevron-down"></i>
                      Q2. What is Selection Tracer.com?
                    </a>

                    <div className="accordion-content">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </li>

                  <li className="accordion-item">
                    <a className="accordion-title" href="javascript:void(0)">
                      <i className="bx bx-chevron-down"></i>
                      Q3. What is tracer-variance trade-off?
                    </a>

                    <div className="accordion-content">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </li>

                  <li className="accordion-item">
                    <a className="accordion-title" href="javascript:void(0)">
                      <i className="bx bx-chevron-down"></i>
                      Q4. What is a confusion matrix?
                    </a>

                    <div className="accordion-content">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </li>

                  <li className="accordion-item">
                    <a className="accordion-title" href="javascript:void(0)">
                      <i className="bx bx-chevron-down"></i>
                      Q5. What is the difference between “long” and “wide”
                      format data?
                    </a>

                    <div className="accordion-content">
                      <p>
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book.
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Faq;
