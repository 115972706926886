import { useContextProvider } from "src/provider/AppContextProvider";
import EditAddressContent from "./EditAddressContent";

const AddressModal = () => {
  const { address } = useContextProvider();
  return (
    <div
      className={`modal fade productsQuickView`}
      id="addressModal"
      tabIndex={-1}
      role="dialog"
      aria-hidden={"false"}
      aria-modal={"true"}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <button
            type="button"
            className="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i className="bx bx-x"></i>
            </span>
          </button>

          <EditAddressContent address={address} />
        </div>
      </div>
    </div>
  );
};

export default AddressModal;
