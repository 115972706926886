import { IAxiosResponse, IOrder, IOrderItem } from "src/models";
import axiosInstance from "./axios";

export default class OrderService {
  private static readonly url: string = "/pharmacy/user/order";
  public static async findAllByUserId(): Promise<IAxiosResponse<IOrder[]>> {
    const response = await axiosInstance.get(`${this.url}`);
    return response.data;
  }

  public static async findById(orderId: number): Promise<IAxiosResponse<IOrder>> {
    const response = await axiosInstance.get(`${this.url}/${orderId}`);
    return response.data;
  }

  public static async save(order: IOrder): Promise<IAxiosResponse<IOrder>> {
    const response = await axiosInstance.post(`${this.url}`, order);
    return response.data;
  }

  public static async editItem(data: {
    order: IOrder;
    orderItem: IOrderItem;
  }): Promise<IAxiosResponse<IOrder>> {
    const response = await axiosInstance.put(`${this.url}/item/edit`, data);
    return response.data;
  }

  public static async removeItem(data: {
    order: IOrder;
    orderItem: IOrderItem;
  }): Promise<IAxiosResponse<IOrder>> {
    const response = await axiosInstance.put(`${this.url}/item/remove`, data);
    return response.data;
  }

  public static async cancel(orderid: number): Promise<IAxiosResponse<IOrder>> {
    const response = await axiosInstance.delete(`${this.url}/${orderid}`);
    return response.data;
  }

  public static async received(order: IOrder): Promise<IAxiosResponse<IOrder>> {
    const response = await axiosInstance.put(`${this.url}/received`, order);
    return response.data;
  }
}
