import React from "react";
import { Link } from "react-router-dom";
interface IProps {
  title: string;
  pages: { to?: string; title: string }[];
}

const PageTitleArea: React.FC<IProps> = ({ title, pages }) => {
  return (
    <section className="page-title-area">
      <div className="container">
        <div className="page-title-content">
          <h1>{title}</h1>
          <ul>
            {pages.map((page, index) => {
              return page.to ? (
                <li key={index}>
                  <Link to={page.to}>{page.title}</Link>
                </li>
              ) : (
                <li key={index}>{page.title}</li>
              );
            })}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default PageTitleArea;
