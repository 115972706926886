import {
  Home,
  Shop,
  Cart,
  Contacts,
  About,
  Checkout,
  ErrorPage,
  Faq,
  History,
  PurchaseGuide,
  TermOfServices,
  PrivacyPolicy,
  SingleProduct,
  Login,
  Wishlist,
  Brands,
  MyAccount,
  Inovice,
  UploadPrescription,
} from "src/pages";
import { Routes, Route, Outlet } from "react-router-dom";

import React, { Fragment } from "react";
import RequireAuth from "./RequireAuth";
import CustomerService from "src/pages/CustomerService";
import { AddressModal, EditOrderItemModal, Footer, HeaderSearch, NavBar, NewAddressModal, OrderTrackModal, PrescriptionModalDetail, ProductFilterModal, ProductQuickViewModal, SearchOverlay, ShoppingCartModal, ShoppingWishlistModal, TopHeader } from "src/components";
import InoviceModal from "src/components/InoviceModal";
import TabDashboard from "src/pages/MyAccount/TabDashboard";
import TabProfile from "src/pages/MyAccount/TabProfile";
import TabAddresses from "src/pages/MyAccount/TabAddresses";
import TabOrders from "src/pages/MyAccount/TabOrders";
import TabPrescriptions from "src/pages/MyAccount/TabPrescriptions";
import AddNewAddress from "src/pages/MyAccount/AddNewAddress";
import EditAddress from "src/pages/MyAccount/EditAddress";

const Layout = () => {
  return  <Fragment>
  <TopHeader />
  <HeaderSearch />
  <NavBar />
  <SearchOverlay />
  <Outlet />
  <Footer />
  <ProductQuickViewModal />
  <ShoppingCartModal />
  <ShoppingWishlistModal />
  <ProductFilterModal />
  <AddressModal />
  <NewAddressModal />
  <OrderTrackModal />
  <InoviceModal />
  <EditOrderItemModal />
  <PrescriptionModalDetail />
  
</Fragment>
}

const AppRoutes = () => {
  return (
    <Routes >
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />}/>
        <Route path="about-us" element={<About />}/>
        <Route path="login" element={<Login />} />
        <Route path="contact-us" element={<Contacts />} />
        <Route path="brands" element={<Brands />} />
        <Route path="cart" element={<Cart />} />
        <Route path="wishlist" element={<Wishlist />} />
        <Route path="compare" element={<Cart />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="purchase-guide" element={<PurchaseGuide />} />
        <Route path="customer-service" element={<CustomerService />} />
        <Route path="terms-condition" element={<TermOfServices />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="upload-prescription" element={<RequireAuth><UploadPrescription /></RequireAuth>} />
        <Route
          path="checkout"
          element={
            <RequireAuth>
              <Checkout />
            </RequireAuth>
          }
        />
        <Route path="products">
          <Route index element={<Shop />}/>
          <Route path=":id" element={<SingleProduct />}/>
        </Route>
        <Route path="my-account" element={<RequireAuth><MyAccount /></RequireAuth>}>
          <Route index element={<RequireAuth><TabDashboard /></RequireAuth>} />
          <Route path="dashboard" element={<RequireAuth><TabDashboard /></RequireAuth>} />
          <Route path="profile" element={<RequireAuth><TabProfile /></RequireAuth>} />
          <Route path="addresses">
            <Route index element={<RequireAuth><TabAddresses /></RequireAuth>} />
            <Route path="new" element={<AddNewAddress />}/>
            <Route path="edit">
              <Route path=":id" element={<EditAddress />}/>
            </Route>
            </Route>
          <Route path="orders" >
            <Route index element={<RequireAuth><TabOrders /></RequireAuth>} />
            <Route path="invoice">
              <Route path=":id" element={<Inovice />}/>
            </Route>
            </Route>
          <Route path="prescriptions"  >
            <Route index element={<RequireAuth><TabPrescriptions /></RequireAuth>}/>
           
          </Route>
          <Route path="*" element={<ErrorPage />} />
        </Route>
        
        <Route path="/*" element={<ErrorPage />} />
      </Route>
     
    </Routes>
  );
};

export default AppRoutes;
