import { ICartItem } from "src/models";
import * as Types from "src/redux/types";

type State = {
  items: ICartItem[];
  isLoaded: boolean;
};

const initialState: State = {
  items: [],
  isLoaded: false,
};

const CartReducer = (
  state = initialState,
  action: Action<ICartItem[] | ICartItem | number>
): State => {
  const { type, payload } = action;
  switch (type) {
    case Types.PHAR_CART_LOAD: {
      const data = payload as ICartItem[];

      return {
        ...state,
        items: data,
        isLoaded: true,
      };
    }

    case Types.PHAR_CART_ADD_ITEM: {
      const data = payload as ICartItem;
      return {
        ...state,
        items: addOrUpdateItemInCart(state.items, data),
      };
    }
    case Types.PHAR_CART_UPDATE_ITEM: {
      const data = payload as ICartItem;
      return {
        ...state,
        items: addOrUpdateItemInCart(state.items, data),
      };
    }

    case Types.PHAR_CART_REMOVE: {
      const id = payload as number;
      return {
        ...state,
        items: state.items.filter((item) => item.drug.id !== id),
      };
    }

    case Types.PHAR_CART_CLEAR: {
      return {
        ...initialState,
      };
    }

    case Types.PHAR_CART_CHANGE_SALE_TYPE: {
      const data = payload as ICartItem;
      return {
        ...state,
        items: addOrUpdateItemInCart(state.items, data),
      };
    }

    default:
      return state;
  }
};

const addOrUpdateItemInCart = (
  cartItems: ICartItem[],
  cartItem: ICartItem
): ICartItem[] => {
  const existedItem = cartItems.find(
    (item) => item.drug.id === cartItem.drug.id
  );
  if (existedItem) {
    return cartItems.map((item) =>
      item.drug.id === cartItem.drug.id ? cartItem : item
    );
  }
  return [...cartItems, cartItem];
};

export default CartReducer;
