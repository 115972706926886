/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { Empty, PageTitleArea } from "src/components";
import Utils from "src/helper";
import { CartItem } from "src/items";
import { useCart } from "src/redux/hooks";

const Cart = () => {
  const { items, total, size, deliveryChargeAmount, deliveryChargeMessage } =
    useCart();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Fragment>
      <PageTitleArea
        title="Cart"
        pages={[{ to: "/", title: "Home" }, { title: "Cart" }]}
      />
      {size > 0 ? (
        <section className="cart-area ptb-70">
          <div className="container">
            <form>
              <div className="cart-table table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Product</th>
                      <th scope="col">Name</th>
                      <th scope="col">Unit Price</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Sale Type</th>
                      <th scope="col">Total</th>
                    </tr>
                  </thead>

                  <tbody>
                    {items.map((item) => (
                      <CartItem item={item} key={item.drug.id} />
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="cart-totals">
                <h3>Cart Totals</h3>

                <ul>
                  <li>
                    Subtotal <span>Rs {Utils.roundTo1Digit(total)}</span>
                  </li>
                  <li>
                    Delivery Charges {deliveryChargeMessage !== "" && <br />}
                    <p className="delivery-charge">{deliveryChargeMessage}</p>
                    <span>
                      {deliveryChargeAmount > 0
                        ? `Rs ${deliveryChargeAmount}`
                        : "FREE"}
                    </span>
                  </li>
                  <li>
                    Total{" "}
                    <span>
                      Rs {Utils.roundTo1Digit(total + deliveryChargeAmount)}
                    </span>
                  </li>
                </ul>

                <Link to="/checkout" className="default-btn">
                  <i className="flaticon-trolley"></i> Proceed to Checkout
                </Link>
              </div>
            </form>
          </div>
        </section>
      ) : (
        <Empty
          title="Your cart is empty!"
          description="Look like you haven't add any drugs into your cart."
        />
      )}
    </Fragment>
  );
};

export default Cart;
