import { IAxiosResponse, IDrug, IDrugPagination } from "src/models";
import axiosInstance from "./axios";

export default class DrugService {
  private static readonly url: string = "/pharmacy/drugs";
  public static async findAllDrugs(
    ids: number[]
  ): Promise<IAxiosResponse<IDrug[]>> {
    const response = await axiosInstance.post(`${this.url}/ids`, { ids });
    return response.data;
  }

  public static async findById(id: number): Promise<IAxiosResponse<IDrug>> {
    const response = await axiosInstance.get(`${this.url}/${id}`);
    return response.data;
  }

  public static async findSimilarDrugsById(
    id: number
  ): Promise<IAxiosResponse<IDrug[]>> {
    const response = await axiosInstance.get(`${this.url}/similar/${id}`);
    return response.data;
  }

  public static async findAllByPagination(
    page: number,
    size: number,
    brandid: number,
    subcategoryid: number,
    searchText: string
  ): Promise<IAxiosResponse<IDrugPagination>> {
    let pathUrl = `${this.url}?page=${page}&size=${size}`;
    pathUrl = brandid !== 0 ? `${pathUrl}&brand=${brandid}` : pathUrl;
    pathUrl =
      subcategoryid !== 0 ? `${pathUrl}&subcategory=${subcategoryid}` : pathUrl;
    pathUrl = searchText !== "" ? `${pathUrl}&search=${searchText}` : pathUrl;
    const response = await axiosInstance.get(pathUrl);
    return response.data;
  }
}
