/* eslint-disable @typescript-eslint/no-unused-vars */
import { IRootState } from "..";
import { IPrescription } from "src/models";
import * as Types from "src/redux/types";

export default class PrescriptionActions {
  public static load =
    (prescriptions: IPrescription[]) =>
    async (
      dispatch: DispatchType<Action<IPrescription[]>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.PRESCRIPTIONS_LOAD,
        payload: prescriptions,
      });

  public static save =
    (prescription: IPrescription) =>
    async (
      dispatch: DispatchType<Action<IPrescription>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.PRESCRIPTIONS_SAVE,
        payload: prescription,
      });

  public static delete =
    (id: number) =>
    async (
      dispatch: DispatchType<Action<number>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.PRESCRIPTIONS_DELETE,
        payload: id,
      });

  public static update =
    (prescription: IPrescription) =>
    async (
      dispatch: DispatchType<Action<IPrescription>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.PRESCRIPTIONS_EDIT,
        payload: prescription,
      });

  public static reset =
    () =>
    async (dispatch: DispatchType<Action<any>>, getState: () => IRootState) =>
      dispatch({
        type: Types.PRESCRIPTIONS_RESET,
      });
}
