import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "..";
import { HomeActions } from "../actions";
import { HomeService } from "../services";

const useHome = () => {
  const dispatch = useDispatch();
  const { homeData, isLoaded } = useAppSelector((state) => state.homeData);

  const [loading, setLoading] = useState<boolean>(false);
  const getHomePageData = async () => {
    try {
      setLoading(true);
      const response = await HomeService.getHomePageData();
      if (response.response) {
        dispatch(HomeActions.load(response.response) as any);
      }
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };
  return {
    loading,
    homeData,
    isLoaded,
    getHomePageData,
  };
};

export default useHome;
