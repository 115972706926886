import { combineReducers } from "redux";
import AuthReducer from "./AuthReducer";
import AddressReducer from "./AddressReducer";
import DeliveryChargesReducer from "./DeliveryChargesReducer";
import StoreQrDiscountReducer from "./StoreQrDiscountReducer";
import ShopsReducer from "./ShopsReducers";
import HomeReducer from "./HomeReducer";
import WishlistReducer from "./WishlistReducer";
import CartReducer from "./CartReducer";
import DrugPaginationReducer from "./DrugPaginatinReducer";
import BrandPaginationReducer from "./BrandPaginationReducer";
import OrderReducer from "./OrderReducer";
import PrescriptionReducer from "./PrescriptionReducer";
import TopBrandReducer from "./TopBrandReducer";

export default combineReducers({
  pharmWebsiteAuth: AuthReducer,
  pharmWebsiteAddress: AddressReducer,
  deliveryCharges: DeliveryChargesReducer,
  storeQrDiscount: StoreQrDiscountReducer,
  shops: ShopsReducer,
  homeData: HomeReducer,
  pharmWishlist: WishlistReducer,
  pharmCart: CartReducer,
  drugPagination: DrugPaginationReducer,
  brandPagination: BrandPaginationReducer,
  pharmOrders: OrderReducer,
  prescriptions: PrescriptionReducer,
  topBrands: TopBrandReducer,
});
