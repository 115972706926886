/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useCart, useOrders } from "src/redux/hooks";
import moment from "moment";
import { useContextProvider } from "src/provider/AppContextProvider";
import { Empty } from "src/components";

const TabOrders = () => {
  const { findAllByUserId, orders, isLoaded, isLoggedIn } = useOrders();
  const { isEmpty } = useCart();
  const { setTrackingOrder } = useContextProvider();

  useEffect(() => {
    (async () => {
      if (isLoggedIn && !isLoaded) {
        await findAllByUserId();
      }
    })();
  }, [isLoggedIn, isLoaded]);
  return (
    <div className="bhoechie-tab-content active">
      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th>Order</th>
              <th>Date</th>
              <th>Status</th>
              <th>Total</th>
              <th>Actions</th>
              <th>Track</th>
            </tr>
          </thead>
          <tbody>
            {isLoaded && orders.length > 0 ? (
              orders.map((order) => (
                <tr key={order.id}>
                  <td>#{order.id}</td>
                  <td>{moment(order.createdAt).format("MMMM DD , YYYY")}</td>
                  <td>
                    <span
                      className={`order-status status-${order.orderStatus.toLowerCase()}`}
                    >
                      {order.orderStatus}
                    </span>
                  </td>
                  <td>
                    Rs {order.total + order.deliveryCharges} for{" "}
                    {order.DrugOrderItems.length} item
                  </td>
                  <td>
                    <Link
                      to={`invoice/${order.id}`}
                      className="btn-small d-block"
                    >
                      <i className="fas fa-print"></i> View Invoice
                    </Link>
                  </td>
                  <td>
                    <a
                      className="btn-small d-block"
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#orderTrackModal"
                      onClick={() => setTrackingOrder?.(order)}
                    >
                      <i className="fas fa-print"></i> Track Order
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <div>
                <Empty
                  title="No order found"
                  description="Look like you have not place any order yet."
                  buttonText="Place New Order"
                  buttonLink={
                    isLoggedIn && !isEmpty ? "/checkout" : "/products"
                  }
                />
              </div>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TabOrders;
