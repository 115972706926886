import { IBrand } from "src/models";
import * as Types from "src/redux/types";

type State = {
  brands: IBrand[];
  isLoaded: boolean;
};

const initialState: State = {
  brands: [],
  isLoaded: false,
};

const TopBrandReducer = (
  state = initialState,
  action: Action<IBrand[]>
): State => {
  const { type, payload } = action;
  switch (type) {
    case Types.TOP_BRAND_LOAD: {
      const data = payload as IBrand[];
      return {
        ...state,
        brands: data,
        isLoaded: true,
      };
    }

    default:
      return state;
  }
};

export default TopBrandReducer;
