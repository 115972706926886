import { IDrug } from "src/models";
import * as Types from "src/redux/types";

type State = {
  drugs: IDrug[];
  isLoaded: boolean;
};

const initialState: State = {
  drugs: [],
  isLoaded: false,
};

const WishlistReducer = (
  state = initialState,
  action: Action<IDrug[] | IDrug | number>
): State => {
  const { type, payload } = action;
  switch (type) {
    case Types.PHAR_WISHLIST_LOAD: {
      const data = payload as IDrug[];

      return {
        ...state,
        drugs: data,
        isLoaded: true,
      };
    }

    case Types.PHAR_WISHLIST_ADD_ITEM: {
      const data = payload as IDrug;
      return {
        ...state,
        drugs: [...state.drugs, data],
      };
    }

    case Types.PHAR_WISHLIST_ITEM_REMOVE: {
      const id = payload as number;
      return {
        ...state,
        drugs: state.drugs.filter((product) => product.id !== id),
      };
    }

    case Types.PHAR_WISHLIST_CLEAR: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default WishlistReducer;
