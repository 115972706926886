/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";

const TermOfServices = () => {
  return (
    <section className="terms-of-service-area ptb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div className="terms-of-service-content">
              {/* <img src="assets/img/blog/blog-img3.jpg" alt="image" /> */}
              <p>
                <i>
                  This Terms of Service was last updated on August 01, 2022.
                </i>
              </p>
              <h3>
                Najeeb Pharmacy - Free Delivery Islamabad Terms of Service
              </h3>
              <p>
                Our website address is:{" "}
                <strong>http://najeebpharmacy.com</strong>
              </p>
              <p>
                Please read the following Term of Service carefully. The Term of
                Service grant us certain rights and also describe how you may
                manage the information you shown to others via the "Najeeb
                Pharmacy". Meanwhile, please also view the Privacy Policy at our
                site to verify.
              </p>
              <h3>Requirements to Use the Services</h3>
              <p>
                Here are some of the promises you make to us in this Agreement:
              </p>
              <p>
                Minimum Age for Eligibility : You must be the Minimum Age to use
                our Services.
              </p>
              <p>
                You must be thirteen (13) years or older (the "Minimum Age") to
                use the Services. If you are between the ages of thirteen (13)
                and eighteen (18), you must have parental consent to use the
                Services.
              </p>
              <p>
                If law requires you to be older in order for Najeeb Pharmacy
                Islamabad to provide the Services to you, including the
                collection, storage, then the Minimum Age is the legally
                required older age. The Services are not for use by anyone under
                the age of 13 years.
              </p>
              <p>
                The Najeeb Pharmacy Privacy Policy further describes what
                information you may share with Najeeb Pharmacy and other Users
                and how Najeeb Pharmacy online service may use such information.
              </p>

              <h3>Data Collected and Usage</h3>
              <p>
                You agree that Najeeb Pharmacy, free delivery in Islamabad in
                accordance with the Najeeb Pharmacy Privacy Policy You further
                agree and guarantee that, if You have opted in to participate in
                the Enhanced Search functionality, we may collect, use certain
                information regarding the location & storage contained in Your
                device("Location Permission & Gallery permission") in accordance
                with the Najeeb Pharmacy, The following Collected data is to
                provide, user a best search result: Privacy Policy in order to
                provide the Services. By allowing Contact Information to be
                collected, you give Najeeb Pharmacy a right to use that Contact
                Information as a part of the Service and you guarantee that you
                have any and all permissions required to share such Contact
                Information with us. You may opt-out to prevent the sharing of
                Contact Information at any time from Setting.
              </p>
              <h3>Third-party disclosure</h3>
              <p>
                We do not sell, trade, or otherwise transfer to outside parties
                your Personally Identifiable Information, unless we provide
                users with advance notice. The "Company" will keep the "Compiled
                Data", "Non-identifiable Data", the "Company" will not share
                your "Personal Data" with any third party for any purpose.
              </p>
              <p>
                In order to improve the "Najeeb Pharmacy Service" quality, you
                agree that the "Company" may make your "Personal Data" available
                to the external technical developers who have entered into the
                non-disclosure agreement with the "Company".
              </p>
              <p>
                This does not include website hosting partners and other parties
                who assist us in operating our website, conducting our business,
                or serving our users, so long as those parties agree to keep
                this information confidential. We may also release information
                when its release is appropriate to comply with the law, enforce
                our site policies, or protect ours or others' rights, property
                or safety.
              </p>
            </div>
          </div>

          {/* <div className="col-lg-4 col-md-12">
            <aside className="widget-area">
              <section className="widget widget_insight">
                <ul>
                  <li>
                    <a href="about.html">About Us</a>
                  </li>
                  <li>
                    <a href="contact.html">Contact Us</a>
                  </li>
                  <li>
                    <a href="purchase-guide.html">Purchase Guide</a>
                  </li>
                  <li>
                    <a href="privacy-policy.html">Privacy Policy</a>
                  </li>
                  <li className="active">
                    <a href="terms-of-service.html">Terms of Service</a>
                  </li>
                </ul>
              </section>
            </aside>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default TermOfServices;
