/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { IAddress, IDrug, IOrder, IOrderItem, IPrescription } from "src/models";

interface IContextType {
  showSearchOverlay: boolean;
  drug?: IDrug;
  address?: IAddress;
  trackingOrder?: IOrder;
  editOrderItem?: IOrderItem;
  prescription?:IPrescription,
  setEditOrderItem?: (orderItem: IOrderItem) => void;
  setTrackingOrder?: (order?: IOrder) => void;
  setAddress?: (address?: IAddress) => void;
  setDrug?: (drug: IDrug) => void;
  setShowSearchOverlay?: (show: boolean) => void;
  setPrescription? : (prescription?: IPrescription) => void
}

export const AppContext = React.createContext<IContextType>({
  showSearchOverlay: false,
});

export const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // Getting the device color theme, this will also work with react-native-web
  //const colorScheme = useColorScheme(); // Can be dark | light | no-preference

  /*
   * To enable changing the app theme dynamicly in the app (run-time)
   * we're gonna use useState so we can override the default device theme
   */
  const [prescription , setPrescription] = React.useState<IPrescription>()
  const [editOrderItem, setEditOrderItem] = React.useState<IOrderItem>();
  const [trackingOrder, setTrackingOrder] = React.useState<IOrder>();
  const [drug, setDrug] = React.useState<IDrug>();
  const [showSearchOverlay, setShowSearchOverlay] =
    React.useState<boolean>(false);
  const [address, setAddress] = React.useState<IAddress>();

  // Listening to changes of device appearance while in run-time

  const defaultValue = {
    drug: drug,
    address: address,
    showSearchOverlay: showSearchOverlay,
    trackingOrder: trackingOrder,
    editOrderItem: editOrderItem,
    prescription: prescription,
    setShowSearchOverlay: (show: boolean) => {
      setShowSearchOverlay(show);
    },
    // Chaning color schemes according to theme
    // Overrides the isDark value will cause re-render inside the context.
    setDrug: (drug: IDrug) => {
      setDrug(drug);
    },
    setAddress: (address?: IAddress) => {
      setAddress(address);
    },
    setTrackingOrder: (order?: IOrder) => {
      console.log('set order ', order)
      setTrackingOrder(order);
    },
    setEditOrderItem: (orderItem: IOrderItem) => {
      setEditOrderItem(orderItem);
    },
    setPrescription : (prescription?: IPrescription) => {
      setPrescription(prescription)
    }
  };

  return (
    <AppContext.Provider value={defaultValue as any}>
      {children}
    </AppContext.Provider>
  );
};

export const useContextProvider = () => React.useContext(AppContext);
