import { useFormik } from "formik";
import React from "react";
import { TextInput } from "src/components";
import { IContactUs } from "src/models";
import { useAuth, useContactUs } from "src/redux/hooks";
import swal from "sweetalert";
import * as Yup from "yup";

const ContactForm = () => {
  const { user } = useAuth();
  const { loading, sendMessage } = useContactUs();
  const initialValues: IContactUs = {
    name: user?.name || "",
    email: "",
    phone: user?.phone || "",
    subject: "",
    message: "",
    isRead: false,
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
    email: Yup.string()
      .trim()
      .email("Email is required")
      .required("Email is required"),
    phone: Yup.string().trim().required("Phone is required"),
    subject: Yup.string().trim().required("Subject is required"),
    message: Yup.string().trim().required("Message is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async () => {
      swal({
        title: `Are you sure to send us a message?`,
        icon: "warning",
        buttons: [true, true],
        dangerMode: true,
      }).then(async (willSave) => {
        if (willSave) {
          await sendMessage(
            values,
            () => {
              swal(
                `Success! Your message has been sent successfully. We will contact you shortly!`,
                {
                  icon: "success",
                }
              );
            },
            (error: string) => {
              swal(`OOPS! ${error}!`, {
                icon: "error",
              });
            }
          );
        }
      });
    },
  });

  const { values, errors, touched, handleSubmit, getFieldProps } = formik;

  const getFieldError = (fieldName: string) => {
    const hasError = (errors as any)[fieldName];
    const hastouched = (touched as any)[fieldName];

    return hasError && hastouched ? (
      <span style={{ fontSize: 12, color: "red" }}>{hasError}</span>
    ) : (
      <></>
    );
  };
  return (
    <form id="contactForm" onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <TextInput
            label="Full Name"
            showRequired
            {...getFieldProps("name")}
            isError={errors.name !== undefined && touched.name !== undefined}
            errorMessage={errors.name}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <TextInput label="Email" showRequired {...getFieldProps("email")} />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <TextInput
            label="Mobile No"
            showRequired
            {...getFieldProps("phone")}
          />
        </div>

        <div className="col-lg-6 col-md-6 col-sm-6">
          <TextInput
            label="Subject"
            showRequired
            {...getFieldProps("subject")}
          />
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="form-group">
            <label>Message</label>
            <textarea
              id="message"
              className="form-control"
              cols={30}
              rows={6}
              {...getFieldProps("message")}
            ></textarea>
            <div className="help-block with-errors">
              {getFieldError("message")}
            </div>
          </div>
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12">
          <button type="submit" className="default-btn">
            Send Message
          </button>
          <div id="msgSubmit" className="h3 text-center hidden"></div>
          <div className="clearfix"></div>
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
