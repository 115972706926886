import React from "react";
import "./index.css";
import App from "./App";

import { store, persistor } from "./redux";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { AppContextProvider } from "./provider/AppContextProvider";

const Main = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={<div>loading website</div>} persistor={persistor}>
        <AppContextProvider>
          <App />
        </AppContextProvider>
      </PersistGate>
    </Provider>
  );
};

export default Main;
