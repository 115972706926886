import { useState } from "react";
import { useDispatch } from "react-redux";
import { IOrder, IOrderItem } from "src/models";
import { useAppSelector } from "src/redux";
import { OrderActions } from "src/redux/actions";
import { OrderService } from "src/redux/services";
const useOrders = () => {
  const dispatch = useDispatch();
  const {
    pharmWebsiteAuth: { isLoggedIn },
    pharmOrders: { isLoaded, orders },
  } = useAppSelector((state) => state);
  const [loading, setLoading] = useState<boolean>(false);

  const save = async (
    order: IOrder,
    onSuccess: () => void,
    onError: (error: string) => void
  ) => {
    try {
      setLoading(true);
      const response = await OrderService.save(order);
      if (response.response) {
        dispatch(OrderActions.save(response.response) as any);
        onSuccess();
      } else {
        onError(response.message);
      }
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  const findAllByUserId = async () => {
    try {
      setLoading(true);
      const response = await OrderService.findAllByUserId();
      response.response &&
        dispatch(OrderActions.load(response.response) as any);
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  const editOrderItem = async (
    data: {
      order: IOrder;
      orderItem: IOrderItem;
    },
    onSuccess: () => void
  ) => {
    try {
      setLoading(true);
      const response = await OrderService.editItem(data);
      response.response && dispatch(OrderActions.update(data.order) as any);
      onSuccess?.();
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  // const findById = async (id: number): Promise<IOrder | undefined> => {
  //   try {
  //     const order = orders.find((order) => order.id === id);
  //     if (order !== undefined) {
  //       return order;
  //     }
  //     setLoading(true);

  //     const response = await OrderService.findById(id);
  //     return response.response;
  //   } catch (err: any) {
  //     console.log(err);
  //     return undefined;
  //     // if (err && err.response && err.response.data) {
  //     //   setError(err.response.data);
  //     // } else {
  //     //   setError({
  //     //     message: err.message,
  //     //   });
  //     // }
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const resetOrders = () => {
    dispatch(OrderActions.reset() as any);
  };

  const removeOrderItem = async (
    data: {
      order: IOrder;
      orderItem: IOrderItem;
    },
    onSuccess: () => void
  ) => {
    try {
      setLoading(true);
      const response = await OrderService.removeItem(data);
      response.response && dispatch(OrderActions.update(data.order) as any);
      onSuccess?.();
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  const cancelOrder = async (order: IOrder, onSuccess: () => void) => {
    try {
      setLoading(true);
      const response = await OrderService.cancel(order.id);
      response.response && dispatch(OrderActions.update(order) as any);
      onSuccess?.();
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  const findById = async (orderId: number) => {
    try {
      setLoading(true);
      const response = await OrderService.findById(orderId);
      if(response.response){
        dispatch(OrderActions.save(response.response) as any)
      }
    } catch (err: any) {
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    isLoggedIn,
    isLoaded,
    orders,
    findAllByUserId,
    editOrderItem,
    removeOrderItem,
    cancelOrder,
    resetOrders,
    save,
    findById
  };
};

export default useOrders;
