/* eslint-disable @typescript-eslint/no-unused-vars */
import { IRootState } from "..";
import { IAddress, IDrug, IDrugPagination } from "src/models";
import * as Types from "src/redux/types";

export default class DrugActions {
  public static load =
    (pageData: IDrugPagination) =>
    async (
      dispatch: DispatchType<Action<IDrugPagination>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.DRUG_PAGE_LOAD,
        payload: pageData,
      });
}
