/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const ShoppingWishlistModal = () => {
  return (
    <div
      className="modal right fade shoppingWishlistModal"
      id="shoppingWishlistModal"
      tabIndex={-1}
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <button
            type="button"
            className="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i className="bx bx-x"></i>
            </span>
          </button>

          <div className="modal-body">
            <h3>My Wishlist (3)</h3>

            <div className="products-cart-content">
              <div className="products-cart d-flex align-items-center">
                <div className="products-image">
                  <a href="#">
                    <img
                      src="/assets/img/products/products-img1.jpg"
                      alt="image"
                    />
                  </a>
                </div>

                <div className="products-content">
                  <h3>
                    <a href="#">Coronavirus Face Mask</a>
                  </h3>
                  <div className="products-price">
                    <span>1</span>
                    <span>x</span>
                    <span className="price">$39.00</span>
                  </div>
                </div>
                <a href="#" className="remove-btn">
                  <i className="bx bx-trash"></i>
                </a>
              </div>

              <div className="products-cart d-flex align-items-center">
                <div className="products-image">
                  <a href="#">
                    <img
                      src="/assets/img/products/products-img2.jpg"
                      alt="image"
                    />
                  </a>
                </div>

                <div className="products-content">
                  <h3>
                    <a href="#">Protective Gloves</a>
                  </h3>
                  <div className="products-price">
                    <span>1</span>
                    <span>x</span>
                    <span className="price">$99.00</span>
                  </div>
                </div>
                <a href="#" className="remove-btn">
                  <i className="bx bx-trash"></i>
                </a>
              </div>

              <div className="products-cart d-flex align-items-center">
                <div className="products-image">
                  <a href="#">
                    <img
                      src="/assets/img/products/products-img3.jpg"
                      alt="image"
                    />
                  </a>
                </div>

                <div className="products-content">
                  <h3>
                    <a href="#">Infrared Thermometer Gun</a>
                  </h3>
                  <div className="products-price">
                    <span>1</span>
                    <span>x</span>
                    <span className="price">$90.00</span>
                  </div>
                </div>
                <a href="#" className="remove-btn">
                  <i className="bx bx-trash"></i>
                </a>
              </div>
            </div>

            <div className="products-cart-subtotal">
              <span>Subtotal</span>

              <span className="subtotal">$228.00</span>
            </div>

            <div className="products-cart-btn">
              <a href="#" className="default-btn">
                View Shopping Cart
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShoppingWishlistModal;
