import React from "react";

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  showRequired?: boolean;
  isError?: boolean;
  errorMessage?: string;
}

const TextInput: React.FC<IProps> = ({
  label,
  showRequired,
  isError,
  errorMessage,
  ...rest
}) => {
  return (
    <div className="form-group">
      <label>
        {label} {showRequired && <span className="required">*</span>}
      </label>
      <input type="text" className="form-control" {...rest} />
      {isError ? (
        <div className="help-block with-errors">{errorMessage}</div>
      ) : undefined}
    </div>
  );
};

TextInput.defaultProps = {
  showRequired: true,
};

export default TextInput;
