/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Utils from "src/helper";
import { IOrder, IOrderItem, ISaleType } from "src/models";
import { useContextProvider } from "src/provider/AppContextProvider";
import PriceText from "../PriceText";
import swal from "sweetalert";
import { useDeliveryCharges, useOrders } from "src/redux/hooks";

const EditOrderItemModal = () => {
  const [order, setOrder] = useState<IOrder>();
  const [orderItem , setOrderItem] = useState<IOrderItem>()
  const { isLoaded, getDeliveryCharges, deliveryCharge } = useDeliveryCharges();
  const { removeOrderItem, editOrderItem, orders } = useOrders();
  const {
    trackingOrder,
    editOrderItem: ctxOrderItem,
    setTrackingOrder,
  } = useContextProvider();

  const linkTo = `/products/${orderItem?.Drug.id}`;
  const [selectedSaleType, setSelectedSaleType] = useState<
    ISaleType | undefined
  >(orderItem?.Drug?.DrugSales[0]);
  const [quantity, setQuantity] = useState<string>("1");

  useEffect(() => {
    if(trackingOrder && ctxOrderItem){
      console.log('alert order and orderItem changed')
      const order = orders.find(o => o.id ===trackingOrder.id)
      if(order){
        setOrder(order)
        setOrderItem(order.DrugOrderItems.find(i => i.id===ctxOrderItem?.id))
      }


    }
  } , [trackingOrder, ctxOrderItem])

  useEffect(() => {
    if(orderItem){
      setQuantity(orderItem.quantity.toString())
    }
  } , [orderItem])


  const incrementQuantity = () => {
    const q = Utils.getIntQuantity(quantity);
    setQuantity((q + 1).toString());
  };
  const decrementQuantity = () => {
    const q = Utils.getIntQuantity(quantity);
    if (q > 1) {
      setQuantity((q - 1).toString());
    }
  };

  const onQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQuantity(value);
  };

  const onSaleTypeChange = (e: any) => {
    const sale = orderItem?.Drug?.DrugSales.find(
      (sale) => sale.id.toString() === e.target.value
    );
    if (sale) {
      setSelectedSaleType(sale);
    }
  };

  const removeItem = () => {
    if (order && orderItem) {
      const newOrderItems = order.DrugOrderItems.filter(
        (it) => it.id !== orderItem.id
      );
      const newOrder = {
        ...order,
        DrugOrderItems: newOrderItems,
      };
      let total = Utils.calculateDrugOrderTotal(newOrder);
      newOrder.deliveryCharges =
        total >= deliveryCharge.minAmount ? 0 : deliveryCharge.charges;
      newOrder.total = total;
      newOrder.subTotal = total;
      newOrder.grandTotal = total;
      swal({
        title: `Are you sure to remove this item from your order?`,
        icon: "warning",
        buttons: [true, true],
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          await removeOrderItem(
            { order: newOrder!, orderItem: orderItem! },
            () => {
              swal(`Success! Your order item has been removed!`, {
                icon: "success",
              });
              setTrackingOrder?.(newOrder);
            }
          );
        }
      });
    }
  };
  const editItem = () => {
    if (order && orderItem && selectedSaleType) {
      const newOrderItem = {
        ...orderItem,
        quantity: Utils.getIntQuantity(quantity),
        price: selectedSaleType.price,
        saleType: selectedSaleType.title,
        discount: selectedSaleType.discount,
      };
      const newOrderItems = order.DrugOrderItems.map((it) =>
        it.id === newOrderItem.id ? newOrderItem : it
      );
      const newOrder = {
        ...order,
        DrugOrderItems: newOrderItems,
      };
      let total = Utils.calculateDrugOrderTotal(newOrder);
      newOrder.deliveryCharges =
        total >= deliveryCharge.minAmount ? 0 : deliveryCharge.charges;
      newOrder.total = total;
      newOrder.subTotal = total;
      newOrder.grandTotal = total;
      swal({
        title: `Are you sure to change quantity of this item?`,
        icon: "warning",
        buttons: [true, true],
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          await editOrderItem(
            { order: newOrder!, orderItem: orderItem! },
            () => {
              swal(
                `Success! Your order item quantity has been changed successfully!`,
                {
                  icon: "success",
                }
              );
              setTrackingOrder?.(newOrder);
            }
          );
        }
      });
    }
  };

  useEffect(() => {
    if (orderItem) {
      setSelectedSaleType(orderItem?.Drug?.DrugSales[0]);
      setQuantity(orderItem.quantity.toString());
    }
  }, [orderItem]);

  useEffect(() => {
    (async () => {
      if (!isLoaded) {
        await getDeliveryCharges();
      }
    })();
  }, [isLoaded]);
  return (
    <div
      className={`modal fade productsQuickView`}
      id="editOrderItemModal"
      tabIndex={-1}
      role="dialog"
      aria-hidden={"false"}
      aria-modal={"true"}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <button
            type="button"
            className="close"
            data-bs-dismiss="modal"
            aria-label="Close"
            data-bs-toggle="modal"
            data-bs-target="#orderTrackModal"
          >
            <span aria-hidden="true">
              <i className="bx bx-x"></i>
            </span>
          </button>

          <div className="row align-items-center">
            <h3>Edit Order Item</h3>
            <div className="cart-table table-responsive">
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th scope="col">Product</th>
                    <th scope="col">Name</th>
                    <th scope="col">Unit Price</th>
                    <th scope="col">Quantity</th>
                    <th scope="col">Sale Type</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>

                <tbody>
                  <tr>
                    <td className="product-thumbnail">
                      <a href="#">
                        <img
                          src={Utils.getImagesPath(orderItem?.Drug?.images)}
                          alt="item"
                        />
                      </a>
                    </td>

                    <td className="product-name">
                      <a href="#">{orderItem?.Drug?.name}</a>
                    </td>
                    <td className="product-price">
                      {selectedSaleType && (
                        <PriceText saleType={selectedSaleType} />
                      )}
                    </td>

                    <td className="product-quantity">
                      <div className="input-counter">
                        <span className="minus-btn" onClick={decrementQuantity}>
                          <i className="bx bx-minus"></i>
                        </span>
                        <input
                          type="text"
                          min="1"
                          value={quantity}
                          onChange={onQuantityChange}
                        />
                        <span className="plus-btn" onClick={incrementQuantity}>
                          <i className="bx bx-plus"></i>
                        </span>
                      </div>
                    </td>
                    <td className="product-quantity">
                      {(orderItem?.Drug?.DrugSales || []).length > 1 ? (
                        <select
                          value={selectedSaleType?.id}
                          onChange={onSaleTypeChange}
                        >
                          {orderItem?.Drug?.DrugSales?.map((sale) => (
                            <option value={sale.id}>
                              {sale.title.toLocaleUpperCase()}
                            </option>
                          ))}
                        </select>
                      ) : (
                        <span className="unit-amount">
                          {selectedSaleType?.title?.toLocaleUpperCase()}
                        </span>
                      )}
                    </td>

                    <td className="product-subtotal">
                      <a className="default-btn" href="#" onClick={editItem}>
                        <i className="flaticon-trolley"></i> Edit Item
                      </a>

                      <a onClick={removeItem} className="remove">
                        <i className="bx bx-trash"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditOrderItemModal;
