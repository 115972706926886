import React, { useState } from "react";
import Utils from "src/helper";
import { IDrug, ISaleType } from "src/models";
import { useCart } from "src/redux/hooks";
import swal from "sweetalert";

interface IProps {
  drug: IDrug;
  saleType: ISaleType;
  onChangeQty?: (q: string) => void;
}

const AddToCart: React.FC<IProps> = ({ drug, saleType, onChangeQty }) => {
  const { addCartItem } = useCart();
  const [quantity, setQuantity] = useState<string>("1");

  const incrementQuantity = () => {
    const q = Utils.getIntQuantity(quantity);
    setQuantity((q + 1).toString());
    onChangeQty?.((q + 1).toString());
  };
  const decrementQuantity = () => {
    const q = Utils.getIntQuantity(quantity);
    if (q > 1) {
      setQuantity((q - 1).toString());

      onChangeQty?.((q - 1).toString());
    }
  };

  const addToCart = () => {
    const q = Utils.getIntQuantity(quantity);
    addCartItem({
      drug: drug,
      quantity: q,
      saleType,
    });
    swal(`Success! "${drug.name}" is added to your cart!`, {
      icon: "success",
    });
  };

  const onQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    setQuantity(value);
  };
  return (
    <div className="products-add-to-cart">
      <div className="input-counter">
        <span className="minus-btn" onClick={decrementQuantity}>
          <i className="bx bx-minus"></i>
        </span>
        <input
          type="text"
          value={quantity}
          min="1"
          onChange={onQuantityChange}
        />
        <span className="plus-btn" onClick={incrementQuantity}>
          <i className="bx bx-plus"></i>
        </span>
      </div>

      <button type="button" className="default-btn" onClick={addToCart}>
        <i className="flaticon-trolley"></i> Add to Cart
      </button>
    </div>
  );
};

export default AddToCart;
