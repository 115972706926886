/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { PriceText } from "src/components";
import config from "src/config/config";
import Utils from "src/helper";
import { IDrug, ISaleType } from "src/models";
import { useContextProvider } from "src/provider/AppContextProvider";
import { useCart, useWishlist } from "src/redux/hooks";
import swal from "sweetalert";

interface IProps {
  drug: IDrug;
}

const WishlistDrugItem: React.FC<IProps> = ({ drug }) => {
  const { id, name, DrugSales, images } = drug;
  const { removeWishlistItem } = useWishlist();
  const [selectedSaleType, setSelectedSaleType] = useState<ISaleType>(
    DrugSales[0]
  );
  const { setDrug } = useContextProvider();
  const linkTo = `/products/${id}`;

  const onSaleTypeChange = (e: any) => {
    const sale = DrugSales.find(
      (sale) => sale.id.toString() === e.target.value
    );
    if (sale) {
      setSelectedSaleType(sale);
    }
  };

  const { addCartItem } = useCart();
  const [quantity, setQuantity] = useState<string>("1");

  const incrementQuantity = () => {
    const q = Utils.getIntQuantity(quantity);
    setQuantity((q + 1).toString());
  };
  const decrementQuantity = () => {
    const q = Utils.getIntQuantity(quantity);
    if (q > 1) {
      setQuantity((q - 1).toString());
    }
  };

  const addToCart = () => {
    const q = Utils.getIntQuantity(quantity);
    addCartItem({
      drug: drug,
      quantity: q,
      saleType: selectedSaleType!,
    });
    swal(`Success! "${drug.name}" is added to your cart!`, {
      icon: "success",
    });
  };

  const onQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setQuantity(value);
  };
  return (
    <tr>
      <td className="product-thumbnail">
        <Link to={linkTo}>
          <img src={config.BASE_URL + images[0].path} alt="item" />
        </Link>
      </td>

      <td className="product-name">
        <Link to={linkTo}>{name}</Link>
      </td>
      <td className="product-price">
        <PriceText saleType={selectedSaleType} />
      </td>

      <td className="product-quantity">
        <div className="input-counter">
          <span className="minus-btn" onClick={decrementQuantity}>
            <i className="bx bx-minus"></i>
          </span>
          <input
            type="text"
            min="1"
            value={quantity}
            onChange={onQuantityChange}
          />
          <span className="plus-btn" onClick={incrementQuantity}>
            <i className="bx bx-plus"></i>
          </span>
        </div>
      </td>
      <td className="product-quantity">
        {DrugSales.length > 1 ? (
          <select value={selectedSaleType.id} onChange={onSaleTypeChange}>
            {DrugSales.map((sale) => (
              <option value={sale.id}>{sale.title.toLocaleUpperCase()}</option>
            ))}
          </select>
        ) : (
          <span className="unit-amount">
            {selectedSaleType?.title?.toLocaleUpperCase()}
          </span>
        )}
      </td>

      <td className="product-subtotal">
        <a className="default-btn" href="#" onClick={addToCart}>
          <i className="flaticon-trolley"></i> Add to Cart
        </a>

        <a onClick={() => removeWishlistItem(id)} className="remove">
          <i className="bx bx-trash"></i>
        </a>
      </td>
    </tr>
  );
};

export default WishlistDrugItem;
