/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import config from "src/config/config";
import Utils from "src/helper";
import { IOrder } from "src/models";
import { useContextProvider } from "src/provider/AppContextProvider";
import "./style.css";
import TrackStep from "./TrackStep";

interface IProps {
  order: IOrder;
}

const Content: React.FC<IProps> = ({ order }) => {
  const { orderStatus, DrugOrderItems, id, Admin } = order;
  const { setEditOrderItem } = useContextProvider();
  const isEditable = ["New", "Pending"].includes(orderStatus);

  return (
    <div className="container">
      <article className="card">
        <div className="card-body">
          <h6>Order ID: #{id}</h6>
          <article className="card">
            <div className="card-body row">
              <div className="col">
                <strong>Estimated Delivery time:</strong> <br />
              </div>
              <div className="col">
                <strong>Shipping BY:</strong> <br /> {Admin?.storeName}, |
                <i className="fa fa-phone"></i> {Admin?.phone}
              </div>
              <div className="col">
                <strong>Status:</strong> <br />{" "}
                {orderStatus === "Delivered"
                  ? "Delivered"
                  : orderStatus === "Declined"
                  ? "Declined"
                  : !["New", "Pending"].includes(orderStatus)
                  ? "Picked by the courier"
                  : "Pending"}
              </div>
              <div className="col">
                <strong>Tracking #:</strong> <br /> {id}
              </div>
            </div>
          </article>
          <div className="track">
            <TrackStep
              title="Order confirmed"
              icon="fa-check"
              isActive={["New", "Pending", "In-Progress", "Delivered"].includes(
                orderStatus
              )}
            />
            <TrackStep
              title="Picked by courier"
              icon="fa-user"
              isActive={["New", "Pending", "In-Progress", "Delivered"].includes(
                orderStatus
              )}
            />
            <TrackStep
              title="On the way"
              icon="fa-truck"
              isActive={["In-Progress", "Delivered"].includes(orderStatus)}
            />
            <TrackStep
              title="Ready for pickup"
              icon="fa-box"
              isActive={["Delivered"].includes(orderStatus)}
            />
          </div>
          <hr />
          <ul className="row">
            {DrugOrderItems.map((item) => (
              <li className="col-md-4">
                <figure className="itemside mb-3">
                  <div className="aside">
                    <img
                      src={Utils.getImagesPath(item.Drug.images)}
                      className="img-sm border"
                      alt={item.Drug.name}
                    />
                    {isEditable && (
                      <a
                        href="#"
                        data-bs-toggle="modal"
                        data-bs-target="#editOrderItemModal"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        className="edit-button"
                        onClick={() => setEditOrderItem?.(item)}
                      >
                        <i className="fa fa-edit" />
                      </a>
                    )}
                  </div>
                  <figcaption className="info align-self-center">
                    <p className="title">
                      {item.Drug.name} <br /> {item.Drug.DrugType.title}
                    </p>
                    <span className="text-muted">
                      Rs
                      {Utils.roundTo1Digit(
                        Utils.getDicountPrice(item.price, item.discount)
                      )}
                    </span>
                  </figcaption>
                </figure>
              </li>
            ))}
          </ul>
          <hr />
          <button
            className="btn btn-warning"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <i className="fa fa-chevron-left"></i> Back to orders
          </button>
        </div>
      </article>
    </div>
  );
};

export default Content;
