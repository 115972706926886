import React from "react";
import { createStore, applyMiddleware } from "redux";

import thunkMiddleware from "redux-thunk";
import logger from "redux-logger";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import rootReducer from "./reducers";
import { composeWithDevTools } from "redux-devtools-extension";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
const persistConfig = {
  key: "root",
  storage: storage,
  whitelist: [
    "pharmWebsiteAuth",
    "settings",
    "pharmWishlist",
    "pharmCart",
    "homeData",
  ], // which reducer want to store
};
const middlewares = [thunkMiddleware, logger];
const pReducer = persistReducer(persistConfig, rootReducer);
const middleWareEnhancer = applyMiddleware(...middlewares);
const composerEnhancer = composeWithDevTools({
  trace: true,
  traceLimit: 25,
});
const store = createStore(pReducer, composerEnhancer(middleWareEnhancer));
const persistor = persistStore(store as any);

export type IRootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<IRootState> = useSelector;
export { persistor, store };
