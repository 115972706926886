/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Utils from "src/helper";
import { IOrder, IOrderItem } from "src/models";
import { useContextProvider } from "src/provider/AppContextProvider";

interface IProps {
  item: IOrderItem;
  order: IOrder;
}

const OrderDrugItem: React.FC<IProps> = ({ item, order }) => {
  const { setTrackingOrder, setEditOrderItem } = useContextProvider();
  const { id, Drug, quantity, saleType, discount, price } = item;
  const isEditable = ["New", "Pending"].includes(order.orderStatus);
  return (
    <tr>
      {isEditable && (
        <td>
          <a
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#editOrderItemModal"
            onClick={() => {
              setTrackingOrder?.(order);
              setEditOrderItem?.(item);
            }}
          >
            Edit
          </a>
        </td>
      )}
      <td>{Drug.name}</td>
      <td>{Drug.DrugType.title}</td>
      <td>
        {quantity}x{saleType}
      </td>
      <td>Rs {Utils.roundTo1Digit(price)}</td>
      <td>Rs {Utils.roundTo1Digit(Utils.getDicountPrice(price, discount))}</td>
    </tr>
  );
};

export default OrderDrugItem;
