import { IAxiosResponse, IDrug } from "src/models";
import axiosInstance from "./axios";

export default class WishlistService {
  private static readonly url: string = "/pharmacy/drugs/ids";
  public static async findAllDrugs(
    ids: number[]
  ): Promise<IAxiosResponse<IDrug[]>> {
    const response = await axiosInstance.post(`${this.url}`, { ids });
    return response.data;
  }
}
