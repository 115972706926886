import React from 'react'
import EditAddressContent from 'src/components/AddressModal/EditAddressContent'

const AddNewAddress = () => {
  return (
   <div className="bhoechie-tab-content active">
        <EditAddressContent title="Add New Delivery Address"/>
   </div>
  )
}

export default AddNewAddress