import { useFormik } from "formik";
import React, { useEffect } from "react";
import { IAddress } from "src/models";
import { useContextProvider } from "src/provider/AppContextProvider";
import { useAddress, useAuth } from "src/redux/hooks";
import * as Yup from "yup";
import swal from "sweetalert";
import TextInput from "../TextInput";

const NewAddressModal = () => {
  const styleMarginTop = { marginTop: 10 };
  const { user } = useAuth();
  const { saveAddress, loading, editAddress } = useAddress();
  const initialValues: IAddress = {
    id: 0,
    name: user?.name || "",
    phone: user?.phone || "",
    address: "",
    city: "",
    email: "",
    latitude: 0,
    longitude: 0,
    apartment: "",
    isDefault: false,
    UserId: user?.id!,
    gender: "Mr",
    type: "Home",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phone: Yup.string().required("Phone number is required"),
    city: Yup.string().required("City is required"),
    apartment: Yup.string().required("Apartment detail is required"),
    address: Yup.string().required("Address is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      swal({
        title: `Are you sure to edit this address?`,
        text: values.address,
        icon: "warning",
        buttons: [true, true],
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          await saveAddress(values, () => {
            swal(`Success! Your address has been edited successfully!`, {
              icon: "success",
            });
          });
        }
      });
    },
  });

  const {
    errors,
    touched,
    values,
    setFieldValue,
    getFieldProps,
    handleSubmit,
  } = formik;

  const onAddressChange = (address: IAddress) => {
    formik.setValues({
      ...formik.values,
      ...address,
    });
  };

  const getFieldError = (fieldName: string) => {
    const hasError = (errors as any)[fieldName];
    const hastouched = (touched as any)[fieldName];

    return hasError && hastouched ? (
      <span style={{ fontSize: 12, color: "red" }}>{hasError}</span>
    ) : (
      <></>
    );
  };

  return (
    <div
      className={`modal fade productsQuickView`}
      id="newAddressModal"
      tabIndex={-1}
      role="dialog"
      aria-hidden={"false"}
      aria-modal={"true"}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <button
            type="button"
            className="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i className="bx bx-x"></i>
            </span>
          </button>

          <div className="row align-items-center">
            <h3>Save New Address</h3>
            <form onSubmit={handleSubmit} noValidate>
              <div className="row" style={styleMarginTop}>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Gender Type</label>

                    <div className="select-box">
                      <select
                        className="form-control"
                        {...getFieldProps("type")}
                      >
                        {["Mr", "Mrs", "Miss"].map((gender, index) => (
                          <option value={gender} key={index}>
                            {gender}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="form-group">
                    <label>Address Type</label>

                    <div className="select-box">
                      <select
                        className="form-control"
                        {...getFieldProps("gender")}
                      >
                        {["Home", "Office", "Other"].map((t, index) => (
                          <option value={t} key={index}>
                            {t}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6" style={styleMarginTop}>
                  <TextInput
                    label="Display Name"
                    showRequired
                    isError={
                      errors.name !== undefined && touched.name !== undefined
                    }
                    errorMessage={errors.name}
                    {...getFieldProps("name")}
                  />
                </div>

                <div className="col-lg-6 col-md-6" style={styleMarginTop}>
                  <TextInput
                    label="Phone Number"
                    showRequired
                    isError={
                      errors.phone !== undefined && touched.phone !== undefined
                    }
                    errorMessage={errors.phone}
                    {...getFieldProps("phone")}
                  />
                </div>

                <div className="col-lg-6 col-md-6" style={styleMarginTop}>
                  <TextInput
                    label="City"
                    showRequired
                    isError={
                      errors.city !== undefined && touched.city !== undefined
                    }
                    errorMessage={errors.city}
                    {...getFieldProps("city")}
                  />
                </div>
                <div className="col-lg-6 col-md-6" style={styleMarginTop}>
                  <TextInput
                    label="House No/Apartment"
                    showRequired
                    isError={
                      errors.apartment !== undefined &&
                      touched.apartment !== undefined
                    }
                    errorMessage={errors.apartment}
                    {...getFieldProps("apartment")}
                  />
                </div>
                <div className="col-lg-12 col-md-6" style={styleMarginTop}>
                  <div className="form-group">
                    <label>
                      Address <span className="required">*</span>
                    </label>
                    <textarea
                      rows={3}
                      className="form-control"
                      {...getFieldProps("address")}
                    />
                    {getFieldError("address")}
                  </div>
                </div>
                <div className="col-lg-12 col-md-12" style={{ marginTop: 20 }}>
                  <div className="col-md-12">
                    <button
                      disabled={loading}
                      type="submit"
                      className="default-btn"
                      name="submit"
                    >
                      Save Address
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewAddressModal;
