import { IAxiosResponse, IHomeData } from "src/models";
import axiosInstance from "./axios";

export default class HomeService {
  private static readonly url: string = "/pharmacy/home";
  public static async getHomePageData(): Promise<IAxiosResponse<IHomeData>> {
    const response = await axiosInstance.get(this.url);
    return response.data;
  }
}
