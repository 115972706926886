/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import React from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";

const Footer = () => {
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required."),
  });

  const formik = useFormik({
    validationSchema,
    initialValues: {
      email: "",
    },
    onSubmit: async (values) => {
      alert(values.email);
    },
  });

  const { getFieldProps, handleSubmit, errors, touched } = formik;

  return (
    <footer className="footer-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-sm-6 col-md-6">
            <div className="single-footer-widget">
              <Link to="/" className="logo d-inline-block">
                <img src="/assets/img/logo.png" alt="image" className="logo" />
              </Link>
              <ul className="footer-contact-info">
                <li>
                  <span>Whatsapp:</span> <a href="#">16768</a>
                </li>
                <li>
                  <span>Phone:</span> <a href="tel:0512107676">051-2107676</a>
                </li>

                <li>
                  <span>Address:</span>{" "}
                  <a href="#" target="_blank">
                    Ground Floor, jamal plaza, F-10 Markaz, islamabd. Markaz,
                    Pakistan
                  </a>
                </li>
              </ul>
              <ul className="social">
                <li>
                  <a href="#" target="_blank">
                    <i className="bx bxl-facebook-square"></i>
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="bx bxl-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="bx bxl-instagram-alt"></i>
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="bx bxl-linkedin-square"></i>
                  </a>
                </li>
                <li>
                  <a href="#" target="_blank">
                    <i className="bx bxl-pinterest"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6 col-md-6">
            <div className="single-footer-widget">
              <h3>Information</h3>

              <ul className="link-list">
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms-condition">Terms & Service</Link>
                </li>
                {/* <li>
                  <Link to="/customer-service">Delivery Information</Link>
                </li>
                <li>
                  <Link to="/customer-service">Orders and Returns</Link>
                </li> */}
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6 col-md-6">
            <div className="single-footer-widget">
              <h3>Customer Care</h3>

              <ul className="link-list">
                <li>
                  <Link to="/products">Shop</Link>
                </li>
                <li>
                  <Link to="/my-account">My Account</Link>
                </li>
                <li>
                  <Link to="/cart">Cart</Link>
                </li>
                <li>
                  <Link to="/wishlist">Wishlist</Link>
                </li>
                {/* <li>
                  <Link to="/contact-us">Newsletter</Link>
                </li>
                <li>
                  <Link to="/purchase-guide">Purchasing Policy</Link>
                </li> */}
              </ul>
            </div>
          </div>

          <div className="col-lg-3 col-sm-6 col-md-6">
            <div className="single-footer-widget">
              <h3>Newsletter</h3>
              <p>
                Sign up for our mailing list to get the latest updates & offers.
              </p>
              <form
                className="newsletter-form"
                onSubmit={handleSubmit}
                noValidate
              >
                <input
                  type="email"
                  className="input-newsletter"
                  placeholder="Enter your email address"
                  {...getFieldProps("email")}
                />
                {errors.email && touched.email && (
                  <span className="error">{errors.email}</span>
                )}
                <button type="submit" className="default-btn">
                  Subscribe Now
                </button>
                <div id="validator-newsletter" className="form-result"></div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom-area">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <p>
                Copyright @2022 NajeebPharmacy. Designed By{" "}
                <a href="#" target="_blank">
                  Xperia Tech
                </a>
              </p>
            </div>

            <div className="col-lg-6 col-md-6">
              <div className="payment-types">
                <ul className="d-flex align-items-center justify-content-end">
                  <li>Available Mobile App:</li>
                  <li>
                    <a
                      target="_blank"
                      href="https://apps.apple.com/us/app/najeeb-mart-pharmacy/id1559907067"
                      className="hover-up mb-sm-2 mb-lg-0"
                    >
                      <img
                        className="active"
                        src="/assets/img/store/app-store.jpg"
                        alt="IOS App"
                        width="150"
                      />
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.najeeb.mart.pharmacy.shopping.online.free.delivery.grocery"
                      className="hover-up mb-sm-2"
                    >
                      <img
                        src="/assets/img/store/google-play.jpg"
                        alt="Android App"
                        width="150"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
