/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { useContextProvider } from "src/provider/AppContextProvider";
import { Link, useParams } from "react-router-dom";
import config from "src/config/config";
import { AddToCart, BuyNow, PriceText } from "src/components";
import { IDrug, ISaleType } from "src/models";
import { useDrugs, useWishlist } from "src/redux/hooks";
import { SingleDrugBox } from "src/items";

const SingleProduct = () => {
  const [cartQty, setCartQty] = useState<string>("1");
  const { isWishlisted, addWishlistItem, removeWishlistItem, isLoggedIn } =
    useWishlist();
  const { findSimilarDrugsById, similarDrugs, findDrugById, loading } =
    useDrugs();
  const { id } = useParams();
  const { drug: contextDrug } = useContextProvider();
  const [drug, setDrug] = useState<IDrug>();
  const [selectedSaleType, setSelectedSaleType] = useState<
    ISaleType | undefined
  >(drug ? drug?.DrugSales[0] : undefined);
  const isProductWishlisted = isWishlisted(drug?.id || -1);
  const heartIcon = isProductWishlisted ? "fa-solid fa-heart" : "bx bx-heart";

  const addOrRemoveWishlist = () => {
    if (isProductWishlisted) {
      removeWishlistItem(parseInt("" + drug?.id));
    } else {
      if (drug) {
        addWishlistItem(drug);
      }
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setSelectedSaleType(drug?.DrugSales[0]);
  }, [drug]);

  useEffect(() => {
    (async () => {
      if (id) {
        await findSimilarDrugsById(parseInt(id));
      }
    })();
  }, [id]);

  useEffect(() => {
    (async () => {
      if (contextDrug === undefined && id) {
        const d = await findDrugById(parseInt(id));
        if (d) {
          setDrug(d);
        }
      } else {
        setDrug(contextDrug);
      }
    })();
  }, [id, contextDrug]);

  const onChangeQty = (q: string) => {
    setCartQty(q);
  };

  return (
    <section className="product-details-area pt-70 pb-40">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-md-12">
            <div className="products-details-image">
              <div className="products-details-image-slides owl-theme ">
                <OwlCarousel items={1}>
                  {drug?.images?.map((image, idx) => {
                    const imageUrl = config.BASE_URL + image.path;
                    const newImage = new Image();
                    newImage.src = imageUrl;
                    const width =
                      newImage.height > newImage.width ? "40%" : "70%";
                    return (
                      <li
                        key={idx}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "row",
                        }}
                      >
                        <img
                          src={newImage.src}
                          alt={`${drug?.name} _ ${idx}`}
                          style={{ maxHeight: 400, width }}
                        />
                      </li>
                    );
                  })}
                </OwlCarousel>
              </div>

              {/* Carousel Thumbs  */}
              {/* <OwlCarousel
                className="owl-thumbs products-details-image-slides-owl-thumbs"
                nav={false}
                dots={false}
                items={4}
              >
                {drug?.images?.map((image, index) => (
                  <div className="owl-thumb-item" key={index}>
                    <img src={Utils.getImagesPath(image)} alt={drug?.name} />
                  </div>
                ))}
              </OwlCarousel> */}
            </div>
          </div>

          <div className="col-lg-7 col-md-12">
            <div className="products-details-desc">
              <h3>
                {drug?.name} {drug?.DrugType.title} {drug?.measurement}
              </h3>

              {selectedSaleType && <PriceText saleType={selectedSaleType} />}

              <div className="products-review">
                <div className="rating">
                  <i className="bx bxs-star"></i>
                  <i className="bx bxs-star"></i>
                  <i className="bx bxs-star"></i>
                  <i className="bx bxs-star"></i>
                  <i className="bx bxs-star"></i>
                </div>
                <a href="#" className="rating-count">
                  3 reviews
                </a>
              </div>

              <ul className="products-info">
                <li>
                  <span>Brand:</span>{" "}
                  <Link to={`/products?brand=${drug?.DrugBrands?.[0].id}`}>
                    {drug?.DrugBrands?.[0].title}
                  </Link>
                </li>
                <li>
                  <span>Availability:</span> <a href="#">Available</a>
                </li>
                <li>
                  <span>Products Type:</span>{" "}
                  <Link to={`/products?type=${drug?.DrugType?.id}`}>
                    {drug?.DrugType?.title}
                  </Link>
                </li>
              </ul>

              <div className="products-size-wrapper">
                <h4>Sale Type:</h4>

                <ul>
                  {drug?.DrugSales?.map((sale) => (
                    <li
                      onClick={() => setSelectedSaleType(sale)}
                      className={
                        sale.id === selectedSaleType?.id ? "active" : ""
                      }
                    >
                      <a style={{ cursor: "pointer" }}>
                        {sale.title.toLocaleUpperCase()}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="products-info-btn">
                {/* <Link to="/customer-service">
                  <i className="bx bxs-truck"></i> Shipping
                </Link> */}
                <Link to="/contact-us">
                  <i className="bx bx-envelope"></i> Ask about this products
                </Link>
              </div>

              {drug && selectedSaleType && (
                <AddToCart
                  drug={drug}
                  saleType={selectedSaleType}
                  onChangeQty={onChangeQty}
                />
              )}

              <div className="wishlist-btn">
                <a onClick={addOrRemoveWishlist}>
                  <i className={heartIcon}></i> Add to Wishlist
                </a>
              </div>

              <div className="buy-checkbox-btn">
                <div className="item">
                  {drug && selectedSaleType && (
                    <BuyNow
                      drug={drug}
                      saleType={selectedSaleType}
                      quantity={cartQty}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="products-details-tabs">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    id="description-tab"
                    data-bs-toggle="tab"
                    href="#description"
                    role="tab"
                    aria-controls="description"
                  >
                    Description
                  </a>
                </li>
              </ul>

              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="description"
                  role="tabpanel"
                >
                  <p>{drug?.description}</p>
                  <table className="table table-bordered">
                    <tbody>
                      <tr>
                        <td>Formula</td>
                        <td>{drug?.formula}</td>
                      </tr>
                      <tr>
                        <td>Measurement</td>
                        <td>
                          {drug?.measurement && drug?.measurement !== " "
                            ? drug.measurement
                            : "None"}
                        </td>
                      </tr>
                      <tr>
                        <td>For Who(Gender)</td>
                        <td>{drug?.gender}</td>
                      </tr>
                      <tr>
                        <td>Product Form</td>
                        <td>{drug?.DrugType.title}</td>
                      </tr>
                      <tr>
                        <td>Age Group</td>
                        <td>
                          {drug?.AgeGroups?.map((age) => age.groupName)?.join(
                            ","
                          )}
                        </td>
                      </tr>
                      {drug?.DrugSpecifications?.map((spec) => (
                        <tr>
                          <td>{spec.title}</td>
                          <td>{spec.description}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="related-products">
        <div className="container">
          <div className="section-title">
            <h2>Related Products</h2>
          </div>

          <div className="products-slides  owl-theme">
            <OwlCarousel
              dots={false}
              nav
              margin={20}
              responsive={{
                0: {
                  items: 1,
                },
                400: {
                  items: 2,
                },
                600: {
                  items: 2,
                },
                700: {
                  items: 3,
                },
                1000: {
                  items: 4,
                },
              }}
            >
              {similarDrugs.map((drug) => (
                <SingleDrugBox drug={drug} key={drug.id} />
              ))}
            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SingleProduct;
