/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Utils from "src/helper";
import { CartModalItem } from "src/items";
import { useCart } from "src/redux/hooks";
import Empty from "../Empty";

const ShoppingCartModal = () => {
  const { items, size, total, deliveryChargeMessage, deliveryChargeAmount } =
    useCart();

  return (
    <div
      className="modal right fade shoppingCartModal"
      id="shoppingCartModal"
      tabIndex={-1}
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <button
            type="button"
            className="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i className="bx bx-x"></i>
            </span>
          </button>

          <div className="modal-body">
            <h3>My Cart ({size})</h3>

            {size > 0 ? (
              <Fragment>
                <div className="products-cart-content">
                  {items.map((item) => (
                    <CartModalItem item={item} key={item.drug.id} />
                  ))}
                </div>

                <div className="products-cart-subtotal">
                  <span>Subtotal</span>

                  <span className="subtotal">
                    Rs {Utils.roundTo1Digit(total)}
                  </span>
                </div>
                <div className="products-cart-subtotal">
                  <span>Delivery Charge</span>
                  {deliveryChargeMessage !== "" && <br />}
                  <p className="delivery-charge">{deliveryChargeMessage}</p>

                  <span className="subtotal">
                    {deliveryChargeAmount === 0
                      ? "FREE"
                      : `Rs ${deliveryChargeAmount}`}
                  </span>
                </div>
                <div className="products-cart-subtotal">
                  <span>Total</span>

                  <span className="subtotal">
                    Rs {Utils.roundTo1Digit(total + deliveryChargeAmount)}
                  </span>
                </div>
                <div className="products-cart-btn">
                  <Link to="/cart" className="default-btn">
                    View Cart
                  </Link>
                </div>
                <br />

                <div className="products-cart-btn">
                  <Link to="/checkout" className="default-btn">
                    Proceed to Checkout
                  </Link>
                </div>
              </Fragment>
            ) : (
              <Empty
                title="Your cart is empty!"
                description="Look like you haven't add any drugs into your cart."
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShoppingCartModal;
