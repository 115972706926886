const API_KEY =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYWNrYWdlTmFtZSI6ImNvbS5uYWplZWIubWFydC5waGFybWFjeS5zaG9wcGluZy5vbmxpbmUuZnJlZS5kZWxpdmVyeS5ncm9jZXJ5IiwiaWF0IjoxNjAxNDYyNDYyfQ.J_UBlexExMk65-4d8kEL6uKL5Ka9FP3i6aU1WkWS2JQ";

const DEV = false;
const HOST = DEV ? "192.168.10.37" : "api.najeebmart.com";
const PORT = DEV ? 5000 : 8080;
const BASE_URL: string = "https://api.najeebmart.com/"; //`http://${HOST}:${PORT}/`;
const config = {
  API_KEY,
  BASE_URL,
  HOST,
  PORT,
};

export default config;
