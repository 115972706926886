import { useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/redux";
import { AddressActions } from "src/redux/actions";
import { AddressService } from "src/redux/services";
import { IAddress } from "src/models";
const useAddress = () => {
  const dispatch = useDispatch();
  const {
    pharmWebsiteAddress: { isLoaded, addresses },
    pharmWebsiteAuth: { isLoggedIn },
  } = useAppSelector((state) => state);
  const [loading, setLoading] = useState<boolean>(false);

  const findAll = async () => {
    try {
      setLoading(true);
      const response = await AddressService.findAll();
      response.response &&
        dispatch(AddressActions.load(response.response) as any);
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  const resetAddresses = () => {
    dispatch(AddressActions.reset() as any);
  };

  const getDefaultAddress = (): IAddress | undefined => {
    const address = addresses.find((item) => item.isDefault === true);
    if (address !== undefined) return address;
    else if ((addresses || []).length > 0) return addresses[0];
    return undefined;
  };

  const removeById = async (id: number, onSuccess: () => void) => {
    try {
      setLoading(true);
      const response = await AddressService.removeById(id);
      response.response && dispatch(AddressActions.delete(id) as any);
      response.response && onSuccess();
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  const findById = async (id: number) => {
    
    try {
      setLoading(true);
      const response = await AddressService.findById(id);
      if(response.response){
        dispatch(AddressActions.save(response.response) as any)
      }
    } catch (err: any) {
    } finally {
      setLoading(false);
    }
  };

  const saveAddress = async (address: IAddress, onSuccess: () => void) => {
    try {
      setLoading(true);
      const response = await AddressService.save(address);
      response.response &&
        dispatch(AddressActions.save(response.response) as any);
      response.response && onSuccess();
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  const editAddress = async (address: IAddress, onSuccess: () => void) => {
    try {
      setLoading(true);
      const response = await AddressService.editAddress(address);
      response.response &&
        dispatch(AddressActions.update(response.response) as any);
      response.response && onSuccess();
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    isLoaded,
    isLoggedIn,
    addresses,
    findAll,
    getDefaultAddress,
    removeById,
    saveAddress,
    editAddress,
    findById
  };
};

export default useAddress;
