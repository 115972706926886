/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import usePagination, { DOTS } from "./usePagination";

interface IProps {
  onPageChange: (page: number) => void;
  totalCount: number;
  siblingCount?: number;
  currentPage: number;
  pageSize: number;
  className?: string;
  hasPrevPage?: boolean;
  hasNextPage?: boolean;
}

const Pagination: React.FC<IProps> = ({
  onPageChange,
  totalCount,
  siblingCount,
  currentPage,
  pageSize,
  className,
  hasNextPage,
  hasPrevPage,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (paginationRange === undefined) return <p>No page founds</p>;

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <div className="pagination-area text-center">
      {/* Left navigation arrow */}
      <a
        className="prev page-numbers"
        onClick={() => (hasPrevPage ? onPrevious() : undefined)}
      >
        <i className="bx bx-chevrons-left"></i>
      </a>

      {paginationRange.map((pageNumber) => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return (
            <a className="page-number dot" href="#" key={pageNumber}>
              ...
            </a>
          );
        }

        // Render our Page Pills
        return (
          <a
            key={pageNumber}
            className={`page-numbers ${
              pageNumber === currentPage ? "current" : ""
            }`}
            onClick={() => onPageChange(parseInt("" + pageNumber))}
          >
            {pageNumber}
          </a>
        );
      })}

      <a
        className="next page-numbers"
        onClick={() => (hasNextPage ? onNext() : {})}
      >
        <i className="bx bx-chevrons-right"></i>
      </a>
    </div>
  );
};

Pagination.defaultProps = {
  siblingCount: 1,
};

export default Pagination;
