/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import Utils from "src/helper";
import { IDrug } from "src/models";
import { useContextProvider } from "src/provider/AppContextProvider";
import "./homeSlideStyle.css";

interface IProps {
  drugs: IDrug[];
}

const HomeSlides: React.FC<IProps> = ({ drugs }) => {
  const { setDrug } = useContextProvider();
  return (
    <section className="home-slides owl-theme">
      <OwlCarousel
        className="owl-theme"
        items={1}
        loop
        margin={10}
        nav
        dots={false}
      >
        {drugs.map((drug) => {
          const {
            id,
            name,
            measurement,
            DrugSales,
            description,
            DrugType: { title: typeTitle },
            images,
          } = drug;
          const imagePath = Utils.getImagesPath(images);
          const linkTo = `/products/${id}`;
          return (
            <div className="single-banner-item" key={`slider-image-` + id}>
              <div className="d-table">
                <div className="d-table-cell">
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-lg-5 col-md-12">
                        <div className="banner-content">
                          <span className="sub-title">
                            {Utils.roundTo1Digit(DrugSales[0].discount)} %off
                          </span>
                          <h1>
                            {name} <span>{typeTitle}</span>
                          </h1>
                          <p>{description}</p>
                          <div className="btn-box">
                            <div className="d-flex align-items-center">
                              <Link
                                to={linkTo}
                                className="default-btn"
                                onClick={() => setDrug?.(drug)}
                              >
                                <i className="flaticon-trolley"></i> Add To Cart
                              </Link>
                              <span className="price">
                                Rs{" "}
                                {Utils.getDicountPrice(
                                  DrugSales[0].price,
                                  DrugSales[0].discount
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-7 col-md-12">
                        <div className="banner-image slide-image text-center">
                          <img
                            src={imagePath}
                            className="main-image "
                            alt={name}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </OwlCarousel>
    </section>
  );
};

export default HomeSlides;
