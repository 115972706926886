/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from "react";
import { Empty, PageTitleArea } from "src/components";
import { WishlistDrugItem } from "src/items";
import { useWishlist } from "src/redux/hooks";

const Wishlist = () => {
  const { drugs, size } = useWishlist();
  return (
    <Fragment>
      <PageTitleArea
        title="Wishlists"
        pages={[{ to: "/", title: "Home" }, { title: "Wishlists" }]}
      />
      <section className="cart-area ptb-70">
        <div className="container">
          {size > 0 ? (
            <form>
              <div className="cart-table table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">Product</th>
                      <th scope="col">Name</th>
                      <th scope="col">Unit Price</th>
                      <th scope="col">Quantity</th>
                      <th scope="col">Sale Type</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    {drugs.map((drug) => (
                      <WishlistDrugItem drug={drug} key={drug.id} />
                    ))}
                  </tbody>
                </table>
              </div>
            </form>
          ) : (
            <Empty
              title="Your wishlist is empty!"
              description="Look like you haven't add any drugs into your wishlist."
            />
          )}
        </div>
      </section>
    </Fragment>
  );
};

export default Wishlist;
