import { IHomeData } from "src/models";
import * as Types from "src/redux/types";

type State = {
  homeData?: IHomeData;
  isLoaded: boolean;
};

const initialState: State = {
  homeData: undefined,
  isLoaded: false,
};

const HomeReducer = (
  state = initialState,
  action: Action<IHomeData>
): State => {
  const { type, payload } = action;
  switch (type) {
    case Types.HOME_DATA_LOAD: {
      const data = payload as IHomeData;
      return {
        ...state,
        homeData: data,
        isLoaded: true,
      };
    }

    default:
      return state;
  }
};

export default HomeReducer;
