import { IPrescription } from "src/models";
import * as Types from "src/redux/types";

type State = {
  prescriptions: IPrescription[];
  isLoaded: boolean;
};

const initialState: State = {
  prescriptions: [],
  isLoaded: false,
};

const PrescriptionReducer = (
  state = initialState,
  action: Action<IPrescription[] | IPrescription | number>
): State => {
  const { type, payload } = action;
  switch (type) {
    case Types.PRESCRIPTIONS_LOAD: {
      const data = payload as IPrescription[];
      return {
        ...state,
        prescriptions: data,
        isLoaded: true,
      };
    }

    case Types.PRESCRIPTIONS_SAVE: {
      const data = payload as IPrescription;
      return {
        ...state,
        prescriptions: [...state.prescriptions, data],
      };
    }

    case Types.PRESCRIPTIONS_DELETE: {
      const id = payload as number;
      return {
        ...state,
        prescriptions: state.prescriptions.filter((order) => order.id !== id),
      };
    }

    case Types.PRESCRIPTIONS_EDIT: {
      const data = payload as IPrescription;
      return {
        ...state,
        prescriptions: state.prescriptions.map((order) =>
          order.id === data.id ? data : order
        ),
      };
    }

    case Types.PRESCRIPTIONS_RESET: {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default PrescriptionReducer;
