import React, { Fragment, useEffect } from "react";
import { Loading, PageTitleArea, Pagination } from "src/components";
import { BrandItem } from "src/items";
import { useBrands } from "src/redux/hooks";

const Brands = () => {
  const {
    findAllByPagination,
    currentPage,
    totalItems,
    hasNextPage,
    hasPrevPage,
    pageSize,
    pageSizes,
    setPageSize,
    brands,
    loading,
  } = useBrands();

  const loadPageData = async (page: number) => {
    window.scrollTo(200, 200);

    await findAllByPagination(page, pageSize);
  };

  useEffect(() => {
    (async () => {
      window.scrollTo(200, 200);

      await findAllByPagination(currentPage, pageSize);
    })();
  }, [currentPage, pageSize]);
  return (
    <Fragment>
      <PageTitleArea
        title="Brands"
        pages={[{ to: "/", title: "Home" }, { title: "Brands" }]}
      />
      <section className="products-area ptb-70">
        <div className="container">
          <div className="row">
            {loading ? (
              <Loading />
            ) : (
              brands.map((brand) => (
                <div className="col-lg-2 col-md-4 col-sm-6" key={brand.id}>
                  <div className="brand-item-box">
                    <BrandItem brand={brand} showTitle />
                  </div>
                </div>
              ))
            )}
            <div className="col-lg-12 col-md-12">
              <Pagination
                onPageChange={(page) => loadPageData(page)}
                totalCount={totalItems}
                currentPage={currentPage}
                pageSize={pageSize}
                hasPrevPage={hasPrevPage}
                hasNextPage={hasNextPage}
                siblingCount={3}
              />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Brands;
