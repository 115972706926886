import React from "react";
import { Link } from "react-router-dom";
import { IDrug } from "src/models";
import { useContextProvider } from "src/provider/AppContextProvider";

interface IProps {
  drug: IDrug;
}

const AddToCartButton: React.FC<IProps> = ({ drug }) => {
  const linkTo = `/shop/product-detail/${drug.id}`;
  const { setDrug } = useContextProvider();
  return (
    <div className="cart-btn">
      <Link
        to={linkTo}
        onClick={() => {
          setDrug?.(drug);
        }}
      >
        <i className="bx bxs-cart-add"></i>
        <span className="tooltip-label">Add to Cart</span>
      </Link>
    </div>
  );
};

export default AddToCartButton;
