import React, { useEffect, useState } from "react";
import { IOrder } from "src/models";
import { useContextProvider } from "src/provider/AppContextProvider";
import Empty from "../Empty";
import Content from "./Content";

const OrderTrackModal = () => {
  const { trackingOrder, setTrackingOrder } = useContextProvider();
  const [order, setOrder] = useState<IOrder>();

  useEffect(() => {
    if (trackingOrder) {
      setOrder(trackingOrder);
    }else{
      setOrder(undefined)
    }
  }, [trackingOrder]);
  return (
    <div
      className={`modal fade productsQuickView`}
      id="orderTrackModal"
      tabIndex={-1}
      role="dialog"
      aria-hidden={"false"}
      aria-modal={"true"}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <button
            type="button"
            className="close"
            data-bs-dismiss="modal"
            aria-label="Close"
            onClick={() => setTrackingOrder?.(undefined)}
          >
            <span aria-hidden="true">
              <i className="bx bx-x"></i>
            </span>
          </button>

          <div className="row align-items-center">
            {order ? (
              <Content order={order} />
            ) : (
              <Empty
                title="No Order Found"
                description="OOPS! Something went wrong no order found."
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderTrackModal;
