import React from "react";
import { Navigate } from "react-router-dom";
import { useAppSelector } from "../redux";

interface IProps {
  redirectTo?: string;
  children?: React.ReactNode;
}

const RequireAuth: React.FC<IProps> = ({ redirectTo, children }) => {
  const { isLoggedIn } = useAppSelector((state) => state.pharmWebsiteAuth);
  return isLoggedIn ? (
    <>{children}</>
  ) : (
    <Navigate to={redirectTo || "/login"} />
  );
};

RequireAuth.defaultProps = {
  redirectTo: "/login",
};

export default RequireAuth;
