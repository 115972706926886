import React, { useEffect } from "react";
import { useContextProvider } from "src/provider/AppContextProvider";
import { usePrescriotions } from "src/redux/hooks";
import moment from "moment";
import { Link } from "react-router-dom";
import { Empty } from "src/components";

const TabPrescriptions = () => {
  const { findAll, prescriptions, isLoaded, isLoggedIn } = usePrescriotions();
  const { setPrescription, setTrackingOrder } = useContextProvider();

  console.log("prescriptions", prescriptions);

  useEffect(() => {
    (async () => {
      if (isLoggedIn && !isLoaded) {
        await findAll();
      }
    })();
  }, [isLoggedIn, isLoaded]);
  return (
    <div className="bhoechie-tab-content active">
      <div className="table-responsive">
        {" "}
        <table className="table">
          <thead>
            <tr>
              <th>Prescription</th>
              <th>Date</th>
              <th>Status</th>
              <th>Actions</th>
              <th>Track</th>
              <th>Invoice</th>
            </tr>
          </thead>
          <tbody>
            {isLoaded && prescriptions.length > 0 ? (
              prescriptions.map((prescription) => (
                <tr key={prescription.id}>
                  <td>#{prescription.id}</td>
                  <td>
                    {moment(prescription.createdAt).format("MMMM DD , YYYY")}
                  </td>
                  <td>
                    <span
                      className={`prescription-status status-${prescription.status.toLowerCase()}`}
                    >
                      {prescription.status}
                    </span>
                  </td>
                  {/* <td>
                  Rs {prescription.total + prescription.deliveryCharges} for{" "}
                  {prescription.DrugOrderItems.length} item
                </td> */}
                  <td>
                    <a
                      className="btn-small d-block"
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#prescriptionDetailModal"
                      onClick={() => setPrescription?.(prescription)}
                    >
                      <i className="fas fa-print"></i> View Prescription
                    </a>
                  </td>
                  <td>
                    <a
                      className="btn-small d-block"
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#orderTrackModal"
                      onClick={() => setTrackingOrder?.(prescription.order)}
                    >
                      <i className="fas fa-print"></i> Track Order
                    </a>
                  </td>
                  <td>
                    {prescription.order ? (
                      <Link
                        to={`/my-account/orders/invoice/${prescription.order.id}`}
                        className="btn-small d-block"
                      >
                        <i className="fas fa-print"></i> View Invoice
                      </Link>
                    ) : (
                      <span>No order created yet</span>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <div>
                <Empty
                  title="No prescription found"
                  description="Look like you have not uploaded any prescription"
                  buttonText="Upload Prescription"
                  buttonLink="/upload-prescription"
                />
              </div>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TabPrescriptions;
