/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Link } from "react-router-dom";
import config from "src/config/config";
import Utils from "src/helper";
import { IDrug } from "src/models";
import { useContextProvider } from "src/provider/AppContextProvider";

interface IProps {
  drugs: IDrug[];
}

const ProductPromotionsArea: React.FC<IProps> = ({ drugs }) => {
  const colors = ["#e3f3f3", "#f5f4db", "#dee9f6"];
  const { setDrug } = useContextProvider();

  const drugList = drugs.length > 3 ? drugs.slice(0, 2) : drugs;

  const generateColor = () => {
    return Math.random().toString(16).substr(-6);
  };
  return (
    <section className="products-promotions-area pb-40">
      <div className="container">
        <div className="row">
          {drugList.map((drug, index) => {
            const { DrugSales } = drug;
            const sale = DrugSales[0];
            return (
              <div
                className="col-lg-4 col-md-6"
                key={`${drug.name}-${drug.DrugType.id}-${drug.id}`}
              >
                <div
                  className="single-products-promotions-box"
                  style={{ backgroundColor: colors[index] }}
                >
                  <img
                    src={config.BASE_URL + drug.images[0]?.path}
                    alt="image"
                  />

                  <div className="content">
                    <span className="sub-title">Special {sale.title}</span>
                    <h5>{drug.name}</h5>
                    <span className="discount">
                      <span>up to</span> {Utils.roundTo1Digit(sale.discount)}%
                      OFF
                    </span>
                    <Link
                      to={`/products/${drug.id}`}
                      className="link-btn"
                      onClick={() => setDrug?.(drug)}
                    >
                      Shop Now <i className="flaticon-next"></i>
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ProductPromotionsArea;
