/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { useContextProvider } from "src/provider/AppContextProvider";
import { useAuth, useCart, useWishlist } from "src/redux/hooks";

const NavBar = () => {
  const { setShowSearchOverlay } = useContextProvider();
  const { isLoggedIn } = useAuth();
  const { size } = useWishlist();
  const { size: cartSize } = useCart();
  return (
    <div className="navbar-area">
      <div className="drodo-responsive-nav">
        <div className="container">
          <div className="drodo-responsive-menu">
            <div className="logo">
              <Link to="/">
                <img src="/assets/img/logo.png" alt="logo" className="logo" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="drodo-nav">
        <div className="container">
          <nav className="navbar navbar-expand-md navbar-light">
            <Link className="navbar-brand" to="/">
              <img src="/assets/img/logo.png" alt="logo" className="logo" />
            </Link>

            <div className="collapse navbar-collapse mean-menu">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to="/" className="nav-link">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/about-us" className="nav-link">
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/products" className="nav-link">
                    Shop
                  </Link>
                </li>
                <li className="nav-item">
                  {isLoggedIn ? (
                    <Link to="/my-account" className="nav-link">
                      My Account
                    </Link>
                  ) : (
                    <Link to="/login" className="nav-link">
                      Login/Register
                    </Link>
                  )}
                </li>
                {isLoggedIn && (
                  <li className="nav-item">
                    <Link to="/upload-prescription" className="nav-link">
                      Upload Prescription
                    </Link>
                  </li>
                )}
                <li className="nav-item">
                  <Link to="/contact-us" className="nav-link">
                    Contact Us
                  </Link>
                </li>
                <li className="nav-item">
                  <a
                    href="https://najeebstore-api.resourceinn.com/recruitment/jobPost/1"
                    target="_blank"
                    className="nav-link"
                  >
                    Job Opportunity
                  </a>
                </li>
              </ul>

              <div className="others-option">
                <div className="option-item">
                  <div className="cart-btn">
                    <Link to="/wishlist">
                      <i className="bx bx-heart"></i>
                      {size > 0 && <span>{size}</span>}
                    </Link>
                  </div>
                </div>

                <div className="option-item">
                  <div className="cart-btn">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#shoppingCartModal"
                    >
                      <i className="bx bx-shopping-bag"></i>
                      <span>{cartSize}</span>
                    </a>
                  </div>
                </div>

                <div className="option-item">
                  <div
                    className="search-btn-box"
                    onClick={() => setShowSearchOverlay?.(true)}
                  >
                    <i className="search-btn bx bx-search-alt"></i>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
