import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";

const HeaderSearch = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const onSubmit = (e: any) => {
    e.preventDefault();

    if (search !== "") {
      let path = `/products?search=${search}`;
      navigate(path);
    }
  };
  return (
    <form className="card card-sm" onSubmit={onSubmit}>
      <div className="card-body row no-gutters align-items-center">
        <div className="col-auto">
          <i className="search-btn bx bx-search-alt h4 text-body"></i>
        </div>

        <div className="col">
          <input
            className="form-control form-control-lg form-control-borderless"
            type="search"
            placeholder="Search drugs here"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <div className="col-auto">
          <button className="btn btn-lg btn-success" type="submit">
            Search
          </button>
        </div>
      </div>
    </form>
  );
};

export default HeaderSearch;
