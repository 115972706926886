import React from "react";
import { IOrder } from "src/models";
import moment from "moment";
import { OrderDrugItem } from "src/items";

interface IProps {
  order: IOrder;
}

const Content: React.FC<IProps> = ({ order }) => {
  const {
    DrugOrderItems,
    id,
    orderStatus,
    name,
    address,
    apartment,
    city,
    createdAt,
    total,
    deliveryCharges,
  } = order;
  const isEditable = ["New", "Pending"].includes(orderStatus);
  return (
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body p-0">
              <div className="row p-5">
                <div className="col-md-6">
                  <img
                    src="/assets/img/logo.png"
                    style={{ height: 80 }}
                    alt="logo"
                  />
                </div>

                <div className="col-md-6 text-right">
                  <p className="font-weight-bold mb-1">Invoice #{id}</p>
                  <p className="text-muted">
                    Due to: {moment(createdAt).format("DD MMM , YYYY")}
                  </p>
                </div>
              </div>

              <hr className="my-5" />

              <div className="row pb-5 p-5">
                <div className="col-md-6">
                  <p className="font-weight-bold mb-4">Client Information</p>
                  <p className="mb-1">{name}</p>
                  <p>
                    {apartment} , {address}
                  </p>
                  <p className="mb-1">{city} Pakistan</p>
                </div>

                <div className="col-md-6 text-right">
                  <p className="font-weight-bold mb-4">Payment Details</p>
                  <p className="mb-1">
                    <span className="text-muted">Method: </span> Cash on
                    Delivery
                  </p>
                </div>
              </div>

              <div className="row p-5">
                <div className="col-md-12">
                  <table className="table">
                    <thead>
                      <tr>
                        {isEditable && (
                          <th className="border-0 text-uppercase small font-weight-bold">
                            Action
                          </th>
                        )}
                        <th className="border-0 text-uppercase small font-weight-bold">
                          Item
                        </th>
                        <th className="border-0 text-uppercase small font-weight-bold">
                          Type
                        </th>
                        <th className="border-0 text-uppercase small font-weight-bold">
                          Quantity
                        </th>
                        <th className="border-0 text-uppercase small font-weight-bold">
                          Unit Cost
                        </th>
                        <th className="border-0 text-uppercase small font-weight-bold">
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {DrugOrderItems.map((item) => (
                        <OrderDrugItem
                          item={item}
                          key={item.id}
                          order={order}
                        />
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="d-flex flex-row-reverse bg-dark text-white p-4">
                <div className="py-3 px-5 text-right">
                  <div className="mb-2">Grand Total</div>
                  <div className="h2 font-weight-light">
                    Rs {total + deliveryCharges}
                  </div>
                </div>

                <div className="py-3 px-5 text-right">
                  <div className="mb-2">Delivery Charges</div>
                  <div className="h2 font-weight-light">
                    {deliveryCharges > 0 ? `Rs ${deliveryCharges}` : "Free"}
                  </div>
                </div>

                <div className="py-3 px-5 text-right">
                  <div className="mb-2">Sub - Total amount</div>
                  <div className="h2 font-weight-light">Rs {total}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="text-light mt-5 mb-5 text-center small">
        by :{" "}
        <a className="text-light" target="_blank" href="http://totoprayogo.com">
          totoprayogo.com
        </a>
      </div>
    </div>
  );
};

export default Content;
