import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  StandaloneSearchBox,
  useJsApiLoader,
} from "@react-google-maps/api";
import Loading from "../Loading";
import { IAddress } from "src/models";

interface IProps {
  address?: IAddress;
  onAddressChange?: (address: IAddress) => void;
}

const containerStyle = {
  width: "100%",
  height: "400px",
};

const MapComponent3: React.FC<IProps> = ({
  address: defaultAddrress,
  onAddressChange,
}) => {
  const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox>();
  const [bounds, setBounds] = useState<google.maps.LatLngBounds>();
  const [isCurrentLocationUpdates, setIsCurrentLocationUpdates] =
    useState<boolean>(true);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBUQd4IMK4AWYpWZvlLqnUiy_grOFO1jy8",
    libraries: ["places"],
  });

  const [currentLocation, setCurrentLocation] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: -3.745,
    lng: -38.523,
  });

  const [map, setMap] = React.useState<google.maps.Map>();
  

  const onLoad = React.useCallback(function callback(map: any) {
    const bounds = new window.google.maps.LatLngBounds(currentLocation);
    map.fitBounds(bounds);
    setBounds(bounds);
    if (isCurrentLocationUpdates) {
      getCurrentLocationUpdates();
    }
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(undefined);
  }, []);

  useEffect(() => {
    if (defaultAddrress) {
      const newLocation = {
        lat: defaultAddrress.latitude,
        lng: defaultAddrress.longitude,
      };
      setCurrentLocation(newLocation);
      map?.setCenter(newLocation);
      if (defaultAddrress.latitude !== 0 && defaultAddrress.longitude !== 0) {
        setIsCurrentLocationUpdates(false);
      }
    }else{
      setIsCurrentLocationUpdates(true)
    }
  }, [defaultAddrress, map]);

  const onMapClick = async (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      setIsCurrentLocationUpdates(false);
      const latLng = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };

      setCurrentLocation(latLng);

      //getReverseGeocodingData(latLng.lat, latLng.lng);
      getReverseGeocodingData(latLng.lat, latLng.lng);
      // await getGeocodeAddress(latLng);
    }
  };

  const getReverseGeocodingData = (lat: number, lng: number) => {
    var latlng = new google.maps.LatLng(lat, lng);
    // This is making the Geocode request
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ latLng: latlng } as any, (results, status) => {
      if (status !== google.maps.GeocoderStatus.OK) {
        alert(status);
      }
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status === google.maps.GeocoderStatus.OK && results !== null) {
        var address = results[0].formatted_address;

        const data = getCityStateCountryFromAddressResult(results[0]);
        onAddressChange?.({
          ...defaultAddrress!,
          latitude: lat,
          longitude: lng,
          city: data.city,
          area: data.city,
          address,
        });
      }
    });
  };

  const getCityStateCountryFromAddressResult = (
    result: any
  ): { city: string; state: string; country: string } => {
    let city = "",
      state = "",
      country = "";
    for (let i = 0; i < result.address_components.length; i++) {
      for (let j = 0; j < result.address_components[i].types.length; j++) {
        switch (result.address_components[i].types[j]) {
          case "locality":
            city = result.address_components[i].long_name;
            break;
          case "administrative_area_level_1":
            state = result.address_components[i].long_name;
            break;
          case "country":
            country = result.address_components[i].long_name;
            break;
        }
      }
    }
    return { city, state, country };
  };

  const getCurrentLocationUpdates = () => {
    if (isCurrentLocationUpdates)
      navigator?.geolocation.getCurrentPosition(
        ({ coords: { latitude: lat, longitude: lng } }) => {
          const pos = { lat, lng };
          setCurrentLocation(pos);
          map?.setCenter(pos);
          getReverseGeocodingData(pos.lat, pos.lng);
        }
      );
  };

  const onPlacesChanged = () => {
    if (searchBox) {
      let results = searchBox.getPlaces() || [];
      for (let i = 0; i < results.length; i++) {
        let place = results[i]?.geometry?.location;
        let address = results[i]?.formatted_address || "";
        const data = getCityStateCountryFromAddressResult(results[0]);
        if (place) {
          onAddressChange?.({
            ...defaultAddrress!,
            latitude: place.lat(),
            longitude: place.lng(),
            city: data.city,
            area: data.city,
            address,
          });
        }
      }
    }
  };

  return isLoaded ? (
    <div>
      <div id="searchbox">
        <StandaloneSearchBox
          onLoad={(searchBox) => setSearchBox(searchBox)}
          onPlacesChanged={onPlacesChanged}
          bounds={bounds}
        >
          <input
            type="text"
            placeholder="Search your place"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `100%`,
              height: `40px`,
              marginTop: 10,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
            }}
          />
        </StandaloneSearchBox>
      </div>
      <br />
      <GoogleMap
        onClick={onMapClick}
        mapContainerStyle={containerStyle}
        center={currentLocation}
        zoom={10}
        onLoad={onLoad}
        onUnmount={onUnmount}
      >
        {/* Child components, such as markers, info windows, etc. */}
        <>
          <Marker position={currentLocation} />
        </>
      </GoogleMap>
    </div>
  ) : (
    <Loading />
  );
};

export default MapComponent3;
