import React, { useEffect, useState } from "react";
import { useShops } from "src/redux/hooks";
import {
  useLoadScript,
  GoogleMap,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";
import { IAdmin } from "src/models";

const containerStyle = {
  width: "100%",
  height: "600px",
};

const ContactUsMap = () => {
  const { shops, findAll, isLoaded: isShopsLoaded } = useShops();

  const [map, setMap] = React.useState<google.maps.Map>();
  const [center, setCenter] = useState({ lat: 33.6844, lng: 73.0479 });
  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBUQd4IMK4AWYpWZvlLqnUiy_grOFO1jy8",
  });
  const [currentShop, setCurrentShop] = useState<IAdmin>();

  useEffect(() => {
    if (shops.length !== 0) {
      setCenter({ lat: shops[0].latitude, lng: shops[0].longitude });
    }
  }, [shops]);

  useEffect(() => {
    (async () => {
      if (!isShopsLoaded) {
        await findAll();
      }
    })();
  }, [isShopsLoaded]);

  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: google.maps.Map) {
    setMap(undefined);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Child components, such as markers, info windows, etc. */}
      <>
        {shops.map((shop) => (
          <Marker
            position={{ lat: shop.latitude, lng: shop.longitude }}
            onClick={() => setCurrentShop(shop)}
          />
        ))}
        {currentShop && (
          <InfoWindow
            position={{ lat: currentShop.latitude, lng: currentShop.longitude }}
            onCloseClick={() => setCurrentShop(undefined)}
          >
            <div>
              <h5>
                <b>{currentShop?.storeName}</b>
              </h5>
              <p>
                <strong>Address : </strong>
                {currentShop?.address}
              </p>
              <p>
                <strong>Phone : </strong>
                {currentShop?.phone}
              </p>
            </div>
          </InfoWindow>
        )}
      </>
    </GoogleMap>
  ) : (
    <></>
  );
};

export default ContactUsMap;
