import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "src/redux";
import { IAxiosResponse, IOtp, IUser } from "src/models";
import { AuthActions } from "src/redux/actions";
import { AuthService, UserService } from "src/redux/services";
let attempt = 0;
const useAuth = () => {
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const { isLoggedIn, user, token } = useAppSelector(
    (state) => state.pharmWebsiteAuth
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [loginResponse, setLoginResponse] = useState<IAxiosResponse<IUser>>();

  const login = async (phoneNumber: number | string) => {
    try {
      setLoading(true);
      const response = await AuthService.login(phoneNumber);
      setLoginResponse(response);
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  const sendOTP = async (phoneNumber: string, otp: string) => {
    try {
      const otpObj: IOtp = {
        from: attempt === 0 ? "Najeeb Mart" : "8584",
        to: phoneNumber,
        type: "plain",
        otp: otp,
        attempt: attempt,
      };
      setLoading(true);
      const response = await AuthService.sendOTP(otpObj);
      attempt = attempt === 0 ? 1 : 0;
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  const updateProfileName = async (
    name: string,
    onSuccess: () => void,
    onError: (message: string) => void
  ) => {
    try {
      setLoading(true);
      const response = await UserService.updateProfileName({
        ...user!,
        name,
      });
      if (response.response) {
        dispatch(AuthActions.update(response.response) as any);
        onSuccess();
      }
    } catch (e: any) {
    } finally {
      setLoading(false);
    }
  };

  const setLoginUser = (user?: IUser, token?: string) => {
    dispatch(
      AuthActions.login({
        user: user,
        token: token,
      }) as any
    );
    navigation("/");
  };

  const logoutUser = () => {
    dispatch(AuthActions.logout() as any);
  };

  return {
    loading,
    login,
    loginResponse,
    isLoggedIn,
    user,
    sendOTP,
    setLoginResponse,
    setLoginUser,
    logoutUser,
    updateProfileName,
  };
};

export default useAuth;
