import React from "react";
import { Link } from "react-router-dom";
import { useAuth } from "src/redux/hooks";

const HotDeals = () => {
  const { isLoggedIn } = useAuth();
  return (
    <section className="hot-deal-area ptb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="hot-deal-content">
              {/* <span className="sub-title">Order</span> */}
              <h2>Order with prescriptions</h2>
              {/* <div
                id="timer"
                className="flex-wrap d-flex justify-content-center"
              >
                <div
                  id="days"
                  className="align-items-center flex-column d-flex justify-content-center"
                ></div>
                <div
                  id="hours"
                  className="align-items-center flex-column d-flex justify-content-center"
                ></div>
                <div
                  id="minutes"
                  className="align-items-center flex-column d-flex justify-content-center"
                ></div>
                <div
                  id="seconds"
                  className="align-items-center flex-column d-flex justify-content-center"
                ></div>
              </div> */}
              <Link
                to={isLoggedIn ? "/upload-prescription" : "/login"}
                className="default-btn"
              >
                <i className="flaticon-trolley"></i> Upload Prescription Now
              </Link>
              <div className="back-text">2021</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HotDeals;
