/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";

interface IProps {
  title: string;
  icon: string;
  to?:string,
  isActive?: boolean;
  onClick?: () => void;
}
const Tab: React.FC<IProps> = ({ title, to, icon, isActive, onClick }) => {
  const classNames = [
    "list-group-item",
    isActive ? "active" : undefined,
    "text-center",
  ]
    .filter((c) => c !== undefined)
    .join(" ");
  return (
    <Link to={to || "#"} className={classNames} onClick={onClick}>
      <h4 className={icon}></h4>
      <br />
      {title}
    </Link>
  );
};

export default Tab;
