import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "..";
import { PrescriptionActions } from "../actions";
import { PrescriptionService } from "../services";

const usePrescriotions = () => {
  const dispatch = useDispatch();
  const {
    pharmWebsiteAuth: { isLoggedIn, user },
    prescriptions: {prescriptions, isLoaded}
  } = useAppSelector((state) => state);
  const [loading, setLoading] = useState<boolean>(false);
  const findAll = async () => {
    try {
      setLoading(true);
      const response = await PrescriptionService.findAll();
      console.log(response)
      if (response.response) {
        dispatch(PrescriptionActions.load(response.response) as any);
      }
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  const uploadPrescription = async (file:File, prescription: any , onUploadProgress: any, onSuccess: () => void, onError: (error: any) => void) => {
    try {
      setLoading(true);
      const response = await PrescriptionService.save(file, prescription, onUploadProgress);
      
      if (response.response) {
        dispatch(PrescriptionActions.save(response.response) as any);
        onSuccess()
      }
      
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
      onError(err)
    } finally {
      setLoading(false);
    }
  };

  const findById = async (prescriptionId: number) => {
    
    try {
      setLoading(true);
      const response = await PrescriptionService.findById(prescriptionId);
      if(response.response){
        dispatch(PrescriptionActions.save(response.response) as any)
      }
    } catch (err: any) {
    } finally {
      setLoading(false);
    }
  };
  return {
    isLoaded,
    prescriptions,
    loading,
    isLoggedIn,
    user,
    findAll,
    uploadPrescription,
    findById
  };
};

export default usePrescriotions;
