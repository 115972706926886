import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/redux";
import { BrandService } from "src/redux/services";
import { IBrand } from "src/models";
import { BrandActions } from "../actions";

const useBrands = () => {
  const pageSizes = [24, 36, 72];
  const {
    currentPage,
    pageData,
    totalItems,
    totalPages,
    hasPrevPage,
    hasNextPage,
    prevPage,
    nextPage,
    pageSize: currentPageSize,
  } = useAppSelector((state) => state.brandPagination);
  const { brands: topBrands, isLoaded: isTopBrandLoaded } = useAppSelector(
    (state) => state.topBrands
  );

  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState<number>(currentPageSize);
  const [brands, setBrands] = useState<IBrand[]>(
    (pageData as any)[currentPage] || []
  );

  const {
    //martWebsiteAddress: { isLoaded, addresses },
    pharmWebsiteAuth: { isLoggedIn },
  } = useAppSelector((state) => state);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setBrands((pageData as any)[currentPage] || []);
  }, [pageData]);

  const findAllByPagination = async (page: number, pageSize: number) => {
    try {
      setLoading(true);
      const response = await BrandService.findAllByPagination(page, pageSize);
      if (response.response) {
        dispatch(BrandActions.load(response.response) as any);
      }
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  const loadTopBrands = async (pageSize: number) => {
    try {
      setLoading(true);
      const response = await BrandService.findAllTopBrands(pageSize);
      console.log("brand response", response.response?.brands);
      if (response.response) {
        dispatch(BrandActions.topBrandsLoad(response.response?.brands) as any);
      }
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  return {
    currentPage,
    brands,
    loading,
    //isLoaded,
    pageData,
    isLoggedIn,
    pageSizes,
    pageSize,
    totalItems,
    totalPages,
    hasPrevPage,
    hasNextPage,
    prevPage,
    nextPage,
    topBrands,
    isTopBrandLoaded,
    setPageSize,
    findAllByPagination,
    loadTopBrands,
  };
};

export default useBrands;
