import { IAdmin, IAxiosResponse, IPrescription } from "src/models";
import axiosInstance from "./axios";

export default class PrescriptionService {
  private static readonly url: string = "/pharmacy/prescription";
  public static async findAll(): Promise<IAxiosResponse<IPrescription[]>> {
    const response = await axiosInstance.get(`${this.url}/user`);
    return response.data;
  }

  public static async findById(prescriptionId: number): Promise<IAxiosResponse<IPrescription>> {
    const response = await axiosInstance.get(`${this.url}/${prescriptionId}`);
    return response.data;
  }

  public static async save(file: File, prescription:any , onUploadProgress: any): Promise<IAxiosResponse<IPrescription>> {
    const data = new FormData();
    data.append('image', file);
    data.append('prescription', JSON.stringify(prescription));
    const response = await axiosInstance.post(this.url , data , {headers: {
        'Content-Type': 'multipart/form-data',
        
      }, onUploadProgress});
    return response.data;
  }
}
