/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from "react";
import { useHome } from "src/redux/hooks";
import BannerCategories from "./components/BannerCategories";
import Brands from "./components/Brands";
import Categories from "./components/Categories";
import FacilityArea from "./components/FacilityArea";
import HomeSlides from "./components/HomeSlides";
import HotDeals from "./components/HotDeals";
import OfferArea from "./components/OfferArea";
import ProductAreas from "./components/ProductAreas";
import ProductPromotionsArea from "./components/ProductPromotionsArea";
import Utils from "src/helper";
import Promotions from "./components/Promotions";

const Home = () => {
  const { getHomePageData, isLoaded, homeData } = useHome();
  useEffect(() => {
    (async () => {
      if (!isLoaded) {
        await getHomePageData();
      }
    })();
  }, [isLoaded]);
  return (
    <Fragment>
      <HomeSlides
        drugs={Utils.getRandomProducts(homeData?.offer.drugs || [], 3)}
      />
      {/* <BannerCategories
        subCategories={Utils.getRandomSubCategogires(
          homeData?.categories?.[0]?.DrugSubCategories || []
        )}
      /> */}
      <Promotions />
      <OfferArea
        drugs={homeData?.offer.drugs || []}
        title={homeData?.offer.title || "Offers"}
      />
      <Categories categories={homeData?.categories || []} />
      <ProductPromotionsArea
        drugs={Utils.getRandomProducts(homeData?.offer.drugs || [], 3)}
      />
      <ProductAreas featureDrugs={homeData?.featureDrugs || []} />
      <HotDeals />
      <Brands
        brands={homeData?.brandList.brands || []}
        title={homeData?.brandList?.title || "Shop by brands"}
      />

      <FacilityArea />
    </Fragment>
  );
};

export default Home;
