/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { IDrug } from "src/models";
import { useWishlist } from "src/redux/hooks";

interface IProps {
  drug: IDrug;
}

const FavoriteButton: React.FC<IProps> = ({ drug }) => {
  const { isWishlisted, addWishlistItem, removeWishlistItem } = useWishlist();
  const isProductWishlisted = isWishlisted(parseInt("" + drug.id));
  const heartIcon = isProductWishlisted ? "fa-solid fa-heart" : "bx bx-heart";

  const addOrRemoveWishlist = () => {
    if (isProductWishlisted) {
      removeWishlistItem(parseInt("" + drug.id));
    } else {
      if (drug) {
        addWishlistItem(drug);
      }
    }
  };
  return (
    <div className="wishlist-btn">
      <a onClick={addOrRemoveWishlist}>
        <i className={heartIcon}></i>
        <span className="tooltip-label">Add to Wishlist</span>
      </a>
    </div>
  );
};

export default FavoriteButton;
