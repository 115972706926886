import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/redux";
import { DrugService } from "src/redux/services";
import { IAddress, IDrug, IDrugPagination } from "src/models";
import { DrugActions } from "../actions";

const useDrugs = () => {
  const pageSizes = [12, 30, 50, 100];
  const {
    currentPage,
    pageData,
    totalItems,
    totalPages,
    hasPrevPage,
    hasNextPage,
    prevPage,
    nextPage,
    pageSize: currentPageSize,
  } = useAppSelector((state) => state.drugPagination);

  const dispatch = useDispatch();
  const [similarDrugs, setSimilarDrugs] = useState<IDrug[]>([]);
  //const [pageData, setPageData] = useState<IProductPagination[]>([]);
  const [pageSize, setPageSize] = useState<number>(currentPageSize);
  const [drugs, setDrugs] = useState<IDrug[]>(
    (pageData as any)[currentPage] || []
  );

  const {
    //martWebsiteAddress: { isLoaded, addresses },
    pharmWebsiteAuth: { isLoggedIn },
  } = useAppSelector((state) => state);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setDrugs((pageData as any)[currentPage] || []);
  }, [pageData]);

  const findAllByPagination = async (
    page: number,
    pageSize: number,
    brandid: number,
    subcategoryid: number,
    searchText: string
  ) => {
    try {
      setLoading(true);
      const response = await DrugService.findAllByPagination(
        page,
        pageSize,
        brandid,
        subcategoryid,
        searchText
      );
      if (response.response) {
        dispatch(DrugActions.load(response.response) as any);
      }
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  const findDrugById = async (id: number): Promise<IDrug | undefined> => {
    const existingProduct = (pageData as any)[currentPage]?.find(
      (drug: IDrug) => drug.id === id
    );
    if (existingProduct) {
      return existingProduct;
    }
    try {
      setLoading(true);
      const response = await DrugService.findById(id);
      return response.response;
    } catch (err: any) {
    } finally {
      setLoading(false);
    }
  };

  const findSimilarDrugsById = async (drugId: number) => {
    try {
      const response = await DrugService.findSimilarDrugsById(drugId);
      response.response && setSimilarDrugs(response.response);
    } catch (err: any) {}
  };

  return {
    currentPage,
    drugs,
    loading,
    //isLoaded,
    pageData,
    isLoggedIn,
    pageSizes,
    pageSize,
    totalItems,
    totalPages,
    hasPrevPage,
    hasNextPage,
    prevPage,
    nextPage,
    similarDrugs,
    findSimilarDrugsById,
    setPageSize,
    findAllByPagination,
    findDrugById,
  };
};

export default useDrugs;
