import React from "react";
import { useContextProvider } from "src/provider/AppContextProvider";
import Empty from "../Empty";
import Content from "./Content";

const InoviceModal = () => {
  const { trackingOrder } = useContextProvider();
  return (
    <div
      className={`modal fade productsQuickView`}
      id="inoviceModal"
      tabIndex={-1}
      role="dialog"
      aria-hidden={"false"}
      aria-modal={"true"}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <button
            type="button"
            className="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i className="bx bx-x"></i>
            </span>
          </button>

          <div className="row align-items-center">
            {trackingOrder ? (
              <Content order={trackingOrder} />
            ) : (
              <Empty
                title="No Order Found"
                description="OOPS! Something went wrong no order found."
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InoviceModal;
