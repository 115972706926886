/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import config from "src/config/config";
import { IBrand } from "src/models";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import { BrandItem } from "src/items";

interface IProps {
  brands: IBrand[];
  title: string;
}

const Brands: React.FC<IProps> = ({ brands, title }) => {
  return (
    <section className="brands-area pt-70 pb-40">
      <div className="container">
        <div className="section-title">
          <h2>{title} </h2>
          <Link to="/brands" className="see-more">
            See More
          </Link>
        </div>

        <div className="products-slides owl-theme">
          <OwlCarousel
            items={6}
            margin={10}
            nav
            dots={false}
            responsive={{
              0: {
                items: 1,
              },
              400: {
                items: 2,
              },
              600: {
                items: 2,
              },
              700: {
                items: 3,
              },
              1000: {
                items: 6,
              },
            }}
          >
            {brands.map((brand) => (
              <BrandItem brand={brand} key={brand.id} />
            ))}
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
};

export default Brands;
