/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";

const ProductFilterModal = () => {
  return (
    <div
      className="modal left fade productsFilterModal"
      id="productsFilterModal"
      tabIndex={-1}
      role="dialog"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <button
            type="button"
            className="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i className="bx bx-x"></i> Close
            </span>
          </button>

          <div className="modal-body">
            <div className="woocommerce-widget-area">
              <div className="woocommerce-widget price-list-widget">
                <h3 className="woocommerce-widget-title">Filter By Price</h3>

                <div className="collection-filter-by-price">
                  <input
                    className="js-range-of-price"
                    type="text"
                    data-min="0"
                    data-max="1055"
                    name="filter_by_price"
                    data-step="10"
                  />
                </div>
              </div>

              <div className="woocommerce-widget color-list-widget">
                <h3 className="woocommerce-widget-title">Color</h3>

                <ul className="color-list-row">
                  <li className="active">
                    <a href="#" title="Black" className="color-black"></a>
                  </li>
                  <li>
                    <a href="#" title="Red" className="color-red"></a>
                  </li>
                  <li>
                    <a href="#" title="Yellow" className="color-yellow"></a>
                  </li>
                  <li>
                    <a href="#" title="White" className="color-white"></a>
                  </li>
                  <li>
                    <a href="#" title="Blue" className="color-blue"></a>
                  </li>
                  <li>
                    <a href="#" title="Green" className="color-green"></a>
                  </li>
                  <li>
                    <a
                      href="#"
                      title="Yellow Green"
                      className="color-yellowgreen"
                    ></a>
                  </li>
                  <li>
                    <a href="#" title="Pink" className="color-pink"></a>
                  </li>
                  <li>
                    <a href="#" title="Violet" className="color-violet"></a>
                  </li>
                  <li>
                    <a
                      href="#"
                      title="Blue Violet"
                      className="color-blueviolet"
                    ></a>
                  </li>
                  <li>
                    <a href="#" title="Lime" className="color-lime"></a>
                  </li>
                  <li>
                    <a href="#" title="Plum" className="color-plum"></a>
                  </li>
                  <li>
                    <a href="#" title="Teal" className="color-teal"></a>
                  </li>
                </ul>
              </div>

              <div className="woocommerce-widget brands-list-widget">
                <h3 className="woocommerce-widget-title">Brands</h3>

                <ul className="brands-list-row">
                  <li>
                    <a href="#">Gucci</a>
                  </li>
                  <li>
                    <a href="#">Virgil Abloh</a>
                  </li>
                  <li>
                    <a href="#">Balenciaga</a>
                  </li>
                  <li className="active">
                    <a href="#">Moncler</a>
                  </li>
                  <li>
                    <a href="#">Fendi</a>
                  </li>
                  <li>
                    <a href="#">Versace</a>
                  </li>
                </ul>
              </div>

              <div className="woocommerce-widget woocommerce-ads-widget">
                <img src="/assets/img/ads.jpg" alt="image" />

                <div className="content">
                  <span>New Arrivals</span>
                  <h3>Modern Electronic Thermometer</h3>
                  <a href="#" className="default-btn">
                    <i className="flaticon-trolley"></i> Shop Now
                  </a>
                </div>

                <a href="#" className="link-btn"></a>
              </div>

              <div className="woocommerce-widget best-seller-widget">
                <h3 className="woocommerce-widget-title">Best Seller</h3>

                <article className="item">
                  <a href="#" className="thumb">
                    <span className="fullimage cover bg1" role="img"></span>
                  </a>
                  <div className="info">
                    <h4 className="title usmall">
                      <a href="#">Thermometer Gun</a>
                    </h4>
                    <span>$99.00</span>
                    <div className="rating">
                      <i className="bx bxs-star"></i>
                      <i className="bx bxs-star"></i>
                      <i className="bx bxs-star"></i>
                      <i className="bx bxs-star"></i>
                      <i className="bx bxs-star"></i>
                    </div>
                  </div>
                  <div className="clear"></div>
                </article>

                <article className="item">
                  <a href="#" className="thumb">
                    <span className="fullimage cover bg2" role="img"></span>
                  </a>
                  <div className="info">
                    <h4 className="title usmall">
                      <a href="#">Protective Gloves</a>
                    </h4>
                    <span>$65.00</span>
                    <div className="rating">
                      <i className="bx bxs-star"></i>
                      <i className="bx bxs-star"></i>
                      <i className="bx bxs-star"></i>
                      <i className="bx bxs-star"></i>
                      <i className="bx bxs-star-half"></i>
                    </div>
                  </div>
                  <div className="clear"></div>
                </article>

                <article className="item">
                  <a href="#" className="thumb">
                    <span className="fullimage cover bg3" role="img"></span>
                  </a>
                  <div className="info">
                    <h4 className="title usmall">
                      <a href="#">Cosmetic Container</a>
                    </h4>
                    <span>$139.00</span>
                    <div className="rating">
                      <i className="bx bxs-star"></i>
                      <i className="bx bxs-star"></i>
                      <i className="bx bxs-star"></i>
                      <i className="bx bxs-star"></i>
                      <i className="bx bx-star"></i>
                    </div>
                  </div>
                  <div className="clear"></div>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductFilterModal;
