import { useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/redux";
import { WishlistActions } from "src/redux/actions";
import { WishlistService } from "src/redux/services";
import { IDrug } from "src/models";
const useWishlist = () => {
  const dispatch = useDispatch();
  const {
    pharmWebsiteAuth: { isLoggedIn },
    pharmWishlist: { isLoaded, drugs },
  } = useAppSelector((state) => state);
  const [loading, setLoading] = useState<boolean>(false);
  const ids = drugs.map((drug) => drug.id);

  const findAll = async () => {
    try {
      setLoading(true);
      const response = await WishlistService.findAllDrugs(ids);
      const drugs = response.response;
      if (drugs) {
        dispatch(WishlistActions.load(drugs) as any);
      }
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  const clear = () => {
    dispatch(WishlistActions.clear() as any);
  };

  const addWishlistItem = (drug: IDrug) => {
    dispatch(WishlistActions.addItem(drug) as any);
  };

  const removeWishlistItem = (id: number) => {
    dispatch(WishlistActions.removeItem(id) as any);
  };

  const isWishlisted = (id: number): boolean => {
    return drugs.find((drug) => drug.id === id) !== undefined;
  };

  return {
    loading,
    isLoggedIn,
    isLoaded,
    drugs,
    size: drugs.length,
    isEmpty: drugs.length === 0,
    isWishlisted,
    clear,
    findAll,
    addWishlistItem,
    removeWishlistItem,
  };
};

export default useWishlist;
