import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import config from "src/config/config";
import Utils from "src/helper";
import { ICartItem, ISaleType } from "src/models";
import { useContextProvider } from "src/provider/AppContextProvider";
import { useCart } from "src/redux/hooks";
import swal from "sweetalert";

interface IProps {
  item: ICartItem;
}

const CartItem: React.FC<IProps> = ({ item }) => {
  const {
    drug: { id, name, measurement, images, DrugSales },
    quantity,
    saleType,
  } = item;
  const [selectedSaleType, setSelectedSaleType] = useState<ISaleType>();
  const linkTo = `/products/${id}`;
  const { setDrug } = useContextProvider();
  const { removeItem, updateCartItem } = useCart();

  useEffect(() => {
    setSelectedSaleType(item.saleType);
  }, [item]);

  const onQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const qty = Utils.getIntQuantity(value);
    updateCartItem({ ...item, quantity: qty, saleType: selectedSaleType! });
  };

  const onSelectChangeHandle = (e: any) => {
    const { value } = e.target;
    const stype = DrugSales.find((sale) => sale.id.toString() === value);
    if (stype) {
      updateCartItem({
        ...item,
        quantity: quantity,
        saleType: stype,
      });
    }
  };

  const incrementQuantity = () => {
    updateCartItem({
      ...item,
      quantity: quantity + 1,
      saleType: selectedSaleType!,
    });
  };
  const decrementQuantity = () => {
    if (quantity > 1) {
      updateCartItem({
        ...item,
        quantity: quantity - 1,
        saleType: selectedSaleType!,
      });
    }
  };
  return (
    <tr>
      <td className="product-thumbnail">
        <Link to={linkTo} onClick={() => setDrug?.(item.drug)}>
          <img src={config.BASE_URL + images[0].path} alt={name} />
        </Link>
      </td>

      <td className="product-name">
        <Link to={linkTo} onClick={() => setDrug?.(item.drug)}>
          {name}
        </Link>
      </td>

      <td className="product-price">
        {(selectedSaleType?.discount || 0) > 0 && (
          <span
            className="old-price"
            style={{ textDecoration: "line-through" }}
          >
            Rs{selectedSaleType?.price}{" "}
          </span>
        )}
        <span className="unit-amount">
          Rs
          {Utils.getDicountPrice(
            selectedSaleType?.price || 0,
            selectedSaleType?.discount || 0
          )}
        </span>
      </td>

      <td className="product-quantity">
        <div className="input-counter">
          <span className="minus-btn" onClick={decrementQuantity}>
            <i className="bx bx-minus"></i>
          </span>
          <input
            type="text"
            min="1"
            value={quantity}
            onChange={onQuantityChange}
          />
          <span className="plus-btn" onClick={incrementQuantity}>
            <i className="bx bx-plus"></i>
          </span>
        </div>
      </td>
      <td className="select-box">
        {DrugSales.length > 1 ? (
          <select value={selectedSaleType?.id} onChange={onSelectChangeHandle}>
            {DrugSales.map((sale) => (
              <option value={sale.id}>{sale.title.toLocaleUpperCase()}</option>
            ))}
          </select>
        ) : (
          <span>{selectedSaleType?.title?.toLocaleUpperCase()}</span>
        )}
      </td>

      <td className="product-subtotal">
        <span className="subtotal-amount">
          Rs {Utils.roundTo1Digit((selectedSaleType?.price || 0) * quantity)}
        </span>

        <a href="#" className="remove" onClick={() => removeItem(id)}>
          <i className="bx bx-trash"></i>
        </a>
      </td>
    </tr>
  );
};

export default CartItem;
