/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Link } from "react-router-dom";

interface IProps {
  title: string;
  description: string;
  buttonText?: string;
  buttonLink?: string;
}

const Empty: React.FC<IProps> = ({
  title,
  description,
  buttonText,
  buttonLink,
}) => {
  return (
    <section className="error-area ptb-100">
      <div className="container">
        <div className="error-content">
          <h3>{title}</h3>
          <p>{description}</p>
          <Link to={buttonLink || "/"} className="default-btn">
            <i className="flaticon-left-chevron"></i> {buttonText}
          </Link>
        </div>
      </div>
    </section>
  );
};

Empty.defaultProps = {
  buttonLink: "/",
  buttonText: "Back to Homepage",
};

export default Empty;
