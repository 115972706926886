/* eslint-disable jsx-a11y/anchor-is-valid */
import { useFormik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { PageTitleArea, TextInput } from "src/components";
import { IAddress, IOrder } from "src/models";
import { useAddress, useAuth, useCart, useOrders } from "src/redux/hooks";
import * as Yup from "yup";
import swal from "sweetalert";
import Utils from "src/helper";

const Checkout = () => {
  const { save, loading } = useOrders();
  const { orderItems, total, deliveryChargeAmount, items } = useCart();
  const styleMarginTop = { marginTop: 10 };
  const { user } = useAuth();
  const { addresses, isLoaded, isLoggedIn, findAll, getDefaultAddress } =
    useAddress();
  const [address, setAddress] = useState<IAddress | undefined>(
    getDefaultAddress()
  );
  useEffect(() => {
    (async () => {
      if (isLoggedIn && !isLoaded) {
        await findAll();
      }
    })();
  }, [isLoggedIn, isLoaded]);

  useEffect(() => {
    if (address) {
      formik.setValues({
        ...formik.values,
        ...address,
      });
    }
  }, [address]);

  useEffect(() => {
    setAddress(getDefaultAddress());
  }, [addresses]);

  const initialValues: IAddress = {
    id: 0,
    name: user?.name || "",
    phone: user?.phone || "",
    address: "",
    city: "",
    email: "",
    latitude: 0,
    longitude: 0,
    apartment: "",
    isDefault: false,
    UserId: user?.id!,
    gender: "Mr",
    type: "Home",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phone: Yup.string().required("Phone number is required"),
    city: Yup.string().required("City is required"),
    apartment: Yup.string().required("Apartment detail is required"),
    address: Yup.string().required("Address is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      const order: IOrder = {
        orderStatus: "New",
        subTotal: total,
        total: total,
        deliveryCharges: deliveryChargeAmount,
        grandTotal: total,
        DrugOrderItems: orderItems,
        UserId: user?.id!,
        isReceived: false,
        isCancel: false,
        address: values.address,
        apartment: values.apartment,
        city: values.city,
        longitude: values.longitude,
        latitude: values.latitude,
        name: values.name,
        phone: values.phone,
        shipping: 0,
        tax: 0,
        id: 0,
        discount: 0,
        content: "",
      };
      swal({
        title: `Are you sure to place order?`,
        text: values.address,
        icon: "warning",
        buttons: [true, true],
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          await save(
            order,
            () => {
              swal(`Success! Your order has been placed successfully!`, {
                icon: "success",
              });
            },
            (error: string) => {
              swal(`OOPS! ${error}. try to use another address!`, {
                icon: "error",
              });
            }
          );
        }
      });
    },
  });

  const {
    errors,
    touched,
    values,
    setFieldValue,
    getFieldProps,
    handleSubmit,
  } = formik;

  const onAddressChange = (e: any) => {
    const { value } = e.target;
    setAddress(addresses.find((a) => "" + a.id === value));
  };
  const getFieldError = (fieldName: string) => {
    const hasError = (errors as any)[fieldName];
    const hastouched = (touched as any)[fieldName];

    return hasError && hastouched ? (
      <span style={{ fontSize: 12, color: "red" }}>{hasError}</span>
    ) : (
      <></>
    );
  };
  return (
    <Fragment>
      <PageTitleArea
        title="Checkout"
        pages={[{ to: "/", title: "Home" }, { title: "Checkout" }]}
      />
      <section className="checkout-area ptb-70">
        <div className="container">
          <form onSubmit={handleSubmit} noValidate>
            <div className="row">
              <div className="col-lg-5 col-md-12">
                <div className="billing-details">
                  <h3 className="title">Billing Details</h3>
                  <div className="payment-box"></div>
                  <div className="row">
                    {addresses.length > 1 && (
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label>Change Address</label>

                          <div className="select-box">
                            <select
                              className="form-control"
                              value={address?.id}
                              onChange={onAddressChange}
                            >
                              {addresses.map((address) => (
                                <option value={address.id}>
                                  {address.apartment + " " + address.address}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Gender Type</label>

                        <div className="select-box">
                          <select
                            className="form-control"
                            {...getFieldProps("type")}
                          >
                            {["Mr", "Mrs", "Miss"].map((gender) => (
                              <option value={gender}>{gender}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label>Address Type</label>

                        <div className="select-box">
                          <select
                            className="form-control"
                            {...getFieldProps("gender")}
                          >
                            {["Home", "Office", "Other"].map((t) => (
                              <option value={t}>{t}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-6 col-md-6" style={styleMarginTop}>
                      <TextInput
                        label="Display Name"
                        showRequired
                        isError={
                          errors.name !== undefined &&
                          touched.name !== undefined
                        }
                        errorMessage={errors.name}
                        {...getFieldProps("name")}
                      />
                    </div>

                    <div className="col-lg-6 col-md-6" style={styleMarginTop}>
                      <TextInput
                        label="Phone Number"
                        showRequired
                        isError={
                          errors.phone !== undefined &&
                          touched.phone !== undefined
                        }
                        errorMessage={errors.phone}
                        {...getFieldProps("phone")}
                      />
                    </div>

                    <div className="col-lg-6 col-md-6" style={styleMarginTop}>
                      <TextInput
                        label="City"
                        showRequired
                        isError={
                          errors.city !== undefined &&
                          touched.city !== undefined
                        }
                        errorMessage={errors.city}
                        {...getFieldProps("city")}
                      />
                    </div>
                    <div className="col-lg-6 col-md-6" style={styleMarginTop}>
                      <TextInput
                        label="House No/Apartment"
                        showRequired
                        isError={
                          errors.apartment !== undefined &&
                          touched.apartment !== undefined
                        }
                        errorMessage={errors.apartment}
                        {...getFieldProps("apartment")}
                      />
                    </div>
                    <div className="col-lg-12 col-md-6" style={styleMarginTop}>
                      <div className="form-group">
                        <label>
                          Address <span className="required">*</span>
                        </label>
                        <textarea
                          rows={3}
                          className="form-control"
                          {...getFieldProps("address")}
                        />
                        {getFieldError("address")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-7 col-md-12">
                <div className="order-details">
                  <h3 className="title">Your Order</h3>

                  <div className="order-table table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th scope="col">Product Name</th>
                          <th scope="col">Qty</th>
                          <th scope="col">Unit Price</th>
                          <th scope="col">Total</th>
                        </tr>
                      </thead>

                      <tbody>
                        {items.map((item) => (
                          <tr>
                            <td className="product-name">
                              <a href="#">
                                {item.drug.name} {item.drug.DrugType.title}
                              </a>
                            </td>
                            <td className="product-total">
                              <span className="subtotal-amount">
                                {item.quantity}
                              </span>
                            </td>
                            <td className="product-total">
                              <span className="subtotal-amount">
                                Rs{" "}
                                {Utils.getDicountPrice(
                                  item.saleType.price,
                                  item.saleType.discount
                                )}
                              </span>
                            </td>
                            <td className="product-total">
                              <span className="subtotal-amount">
                                Rs{" "}
                                {Utils.roundTo1Digit(
                                  Utils.getDicountPrice(
                                    item.saleType.price,
                                    item.saleType.discount
                                  ) * item.quantity
                                )}
                              </span>
                            </td>
                          </tr>
                        ))}

                        <tr>
                          <td colSpan={2}></td>
                          <td className="order-subtotal">
                            <span>Subtotal</span>
                          </td>

                          <td className="order-subtotal-price">
                            <span className="order-subtotal-amount">
                              Rs {Utils.roundTo1Digit(total)}
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={2}></td>
                          <td className="order-shipping">
                            <span>Delivery Charges</span>
                          </td>

                          <td className="shipping-price">
                            <span>
                              {deliveryChargeAmount > 0
                                ? `Rs ${deliveryChargeAmount}`
                                : "FREE"}
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <td colSpan={2}></td>
                          <td className="total-price">
                            <span>Order Total</span>
                          </td>

                          <td className="product-subtotal">
                            <span className="subtotal-amount">
                              Rs{" "}
                              {Utils.roundTo1Digit(
                                total + deliveryChargeAmount
                              )}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <div className="payment-box">
                    <div className="payment-method">
                      <p>
                        <input
                          checked
                          type="radio"
                          id="cash-on-delivery"
                          name="radio-group"
                        />
                        <label htmlFor="cash-on-delivery">
                          Cash on Delivery
                        </label>
                      </p>
                    </div>
                    <button
                      type="submit"
                      className="default-btn"
                      disabled={loading}
                    >
                      <i className="flaticon-tick"></i>Place Order<span></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </Fragment>
  );
};

export default Checkout;
