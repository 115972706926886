import React, { Fragment, useEffect, useState } from 'react'
import { PageTitleArea, TextInput } from 'src/components'
import ImageUploader from 'react-images-upload';
import { useAddress, useAuth, usePrescriotions } from 'src/redux/hooks';
import * as Yup from 'yup'
import { useFormik } from 'formik';
import swal from 'sweetalert'
import { IAddress } from 'src/models';

const UploadPrescription = () => {
  const [uploadProgress, setProgress] = useState<number>()
  const {uploadPrescription, } = usePrescriotions()
    const [file, setFile] = useState<File>()
    const { user } = useAuth();
    const { addresses, isLoaded, isLoggedIn, findAll, getDefaultAddress } =
    useAddress();
  const [address, setAddress] = useState<IAddress | undefined>(
    getDefaultAddress()
  );
  useEffect(() => {
    (async () => {
      if (isLoggedIn && !isLoaded) {
        await findAll();
      }
    })();
  }, [isLoggedIn, isLoaded]);
  useEffect(() => {
    setAddress(getDefaultAddress());
  }, [addresses]);
  const initialValues = {
    name: user?.name || "",
    phone: user?.phone || "",
    note: '',
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().trim().required("Name is required"),
    
    phone: Yup.string().trim().required("Phone is required"),
  });
    const onDrop = (files : File[]) => {
        if((files || []).length > 0){
            const file = files[0]
            setFile(file)
        }else{
           setFile(undefined)
        }
    }

    const onUploadProgress = (progressEvent:any) => {
      const {loaded, total} = progressEvent;
      let percent = Math.floor((loaded * 100) / total);
      setProgress(percent);
      // if(percent===100){
      //     setTimeout(()=> {
      //         setProgress(0)
      //         setShowProgress(false)
      //     }, 1000)
      // }
    };

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) => {
            const data = {
                id: 0,
                declinedReason: '',
                name: values.name,
                phone: values.phone,
                status: 'Uploaded',
                content: values.note,
                address: address?.address,
                city: address?.city,
                apartment: address?.apartment,
                latitude: address?.latitude,
                longitude: address?.longitude,
                userId: user?.id,
                adminId: 0,
              };
              
          swal({
            title: `Are you sure upload this prescription?`,
            icon: "warning",
            buttons: [true, true],
            dangerMode: true,
          }).then(async (willSave) => {
            if (willSave) {
               await uploadPrescription(file! , data, onUploadProgress, () => {
                swal(
                  `Success! Your prescription uploaded successfully!`,
                  {
                    icon: "success",
                  }
                );
               } , (error) => {})
            }
          });
        },
      });

      const { values, errors, touched, handleSubmit, getFieldProps } = formik;

      const getFieldError = (fieldName: string) => {
        const hasError = (errors as any)[fieldName];
        const hastouched = (touched as any)[fieldName];
    
        return hasError && hastouched ? (
          <span style={{ fontSize: 12, color: "red" }}>{hasError}</span>
        ) : (
          <></>
        );
      };

      const onAddressChange = (e: any) => {
        const { value } = e.target;
        setAddress(addresses.find((a) => "" + a.id === value));
      };
  return (
    <Fragment>
    <PageTitleArea
      title="Prescription Order"
      pages={[{ to: "/", title: "Home" }, { title: "Prescription Order" }]}
    />
    <section className="customer-service-area ptb-70">
      <div className="container">
        <div>
        <ImageUploader
        singleImage
                withIcon={true}
                buttonText='Choose images'
                onChange={onDrop}
                imgExtension={['.jpg',  '.png']}
                accept="jpg|png"
                maxFileSize={5242880}
                withPreview
            />
        </div>
        {file && <form id="contactForm" onSubmit={handleSubmit} noValidate>
      <div className="row">
        <div className="col-lg-6 col-md-6 col-sm-6">
          <TextInput
            label="Full Name"
            showRequired
            {...getFieldProps("name")}
            isError={errors.name !== undefined && touched.name !== undefined}
            errorMessage={errors.name}
          />
        </div>

      

        <div className="col-lg-6 col-md-6 col-sm-6">
          <TextInput
            label="Mobile No"
            showRequired
            {...getFieldProps("phone")}
          />
        </div>

        {addresses.length > 1 && (
                      <div className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label>Change Address</label>

                          <div className="select-box">
                            <select
                              className="form-control"
                              value={address?.id}
                              onChange={onAddressChange}
                            >
                              {addresses.map((address) => (
                                <option value={address.id}>
                                  {address.apartment + " " + address.address}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    )}

        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="form-group">
            <label>Note</label>
            <textarea
              id="note"
              className="form-control"
              cols={30}
              rows={6}
              {...getFieldProps("note")}
            ></textarea>
            <div className="help-block with-errors">
              {getFieldError("note")}
            </div>
          </div>
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12">
          <button type="submit" className="default-btn">
            Upload Prescription
          </button>
          <div id="msgSubmit" className="h3 text-center hidden"></div>
          <div className="clearfix"></div>
        </div>
      </div>
    </form>}
        <br />
        <div className="customer-service-content">
          <h3>
            <i className="bx bx-gift"></i>Why upload prescription
          </h3>
          <ul>
            <li>Never lose the digital copy of your prescription. It will be with you wherever you go.</li>
            <li>Is your prescription hard to understand?. MedicalStore pharmacists will help you in placing your order.</li>
            <li>Detail from your prescription are not shared with any third party.</li>
            <li>
             Government regulation require a prescription for ordering some medicine.
            </li>
            
          </ul>
          <h3>
            <i className="bx bx-credit-card-front"></i> Valid Prescriotion Guide
          </h3>
         
          <ul>
            <li><img src='./assets/img/prescriptionguide.jpg' /></li>
            <li>
              Include details of doctor and patient + clinic visit detail.
            </li>
            <li>
             Medicine will be dispensed as per prescription.
            </li>
            
          </ul>
          
        </div>
      </div>
    </section>
  </Fragment>
  )
}

export default UploadPrescription