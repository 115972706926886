import React, { Fragment, useEffect, useState } from "react";
import { Empty, PageTitleArea } from "src/components";
import { useContextProvider } from "src/provider/AppContextProvider";
import Content from "src/components/InoviceModal/Content";
import { IOrder } from "src/models";
import { useParams } from "react-router-dom";
import { useOrders } from "src/redux/hooks";

const Inovice = () => {
  const {findById, loading, orders , isLoaded} = useOrders()
  const {id} = useParams()
  const orderId = parseInt(""+id)
  const [order, setOrder] = useState<IOrder>();

  useEffect(() => {
    (async () => {
      if(!isLoaded){
        await findById(orderId)
      }else{
        const existingOrder = orders.find(
          (order: IOrder) => order.id === orderId
        );

        setOrder(existingOrder)
      }
    })()
  }, [orderId, isLoaded]);

  useEffect(() => {
    const order = orders.find((o) => o.id ===orderId)
    setOrder(order)
  } , [orders])

  


  return order ? (
    <Content order={order}/>
  ) : (
    <Empty title="No order found" description="OOPS! something went wrong" buttonLink="/my-account/orders"/>
  );
};

export default Inovice;
