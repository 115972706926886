// user
export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_UPDATE = "LOGIN_UPDATE";
export const LOGOUT = "LOGOUT";

export const ADDRESSES_LOAD = "ADDRESSES_LOAD";
export const ADDRESS_SAVE = "ADDRESS_SAVE";
export const ADDRESS_DELETE = "ADDRESS_DELETE";
export const ADDRESS_EDIT = "ADDRESS_EDIT";
export const ADDRESS_SET_DEFAULT = "ADDRESS_SET_DEFAULT";
export const ADDRESS_RESET = "ADDRESS_RESET";

export const ORDERS_LOAD = "ORDERS_LOAD";
export const ORDERS_SAVE = "ORDERS_SAVE";
export const ORDERS_DELETE = "ORDERS_DELETE";
export const ORDERS_EDIT = "ORDERS_EDIT";
export const ORDERS_RESET = "ORDERS_RESET";

export const PRESCRIPTIONS_LOAD = "PRESCRIPTIONS_LOAD";
export const PRESCRIPTIONS_SAVE = "PRESCRIPTIONS_SAVE";
export const PRESCRIPTIONS_DELETE = "PRESCRIPTIONS_DELETE";
export const PRESCRIPTIONS_EDIT = "PRESCRIPTIONS_EDIT";
export const PRESCRIPTIONS_RESET = "PRESCRIPTIONS_RESET";

export const DRUG_PAGE_LOAD = "DRUG_PAGE_LOAD";
export const BRAND_PAGE_LOAD = "BRAND_PAGE_LOAD";
export const TOP_BRAND_LOAD = "TOP_BRAND_LOADED";

export const PHAR_CART_CLEAR = "PHAR_CART_CLEAR";
export const PHAR_CART_LOAD = "PHAR_CART_LOAD";
export const PHAR_CART_ADD_ITEM = "PHAR_CART_ADD_ITEM";
export const PHAR_CART_UPDATE_ITEM = "PHAR_CART_UPDATE_ITEM";
export const PHAR_CART_REMOVE = "PHAR_CART_REMOVE";
export const PHAR_CART_CHANGE_SALE_TYPE = "PHAR_CART_CHANGE_SALE_TYPE";

export const PHAR_WISHLIST_CLEAR = "PHAR_WISHLIST_CLEAR";
export const PHAR_WISHLIST_LOAD = "PHAR_WISHLIST_LOAD";
export const PHAR_WISHLIST_ADD_ITEM = "PHAR_WISHLIST_ADD_ITEM";
export const PHAR_WISHLIST_ITEM_REMOVE = "PHAR_WISHLIST_ITEM_REMOVE";

export const DELIVERY_CHARGES_LOAD = "DELIVERY_CHARGES_LOAD";

export const PHAR_DISCOUNT_PRODUCT_CATEGORY_LOAD =
  "PHAR_DISCOUNT_PRODUCT_CATEGORY_LOAD";

export const PHAR_BEST_SELLS_PRODUCT_LOAD = "PHAR_BEST_SELLS_PRODUCT_LOAD";

export const PHAR_CATEGORIES_LOAD = "PHAR_CATEGORIES_LOAD";
export const STORE_QR_DISCOUNT_LOAD = "STORE_QR_DISCOUNT_LOAD";
export const SHOPS_LOAD = "SHOPS_LOAD";
export const PHAR_DISCOUNT_PRODUCTS_LOAD = "PHAR_DISCOUNT_PRODUCTS_LOAD";

export const HOME_DATA_LOAD = "HOME_DATA_LOAD";
