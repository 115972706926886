import React from "react";

const Promotions = () => {
  return (
    <section className="banner-categories-area pt-70 pb-40">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div>
              <a href="https://oragreenwater.com" target="_blank">
                <img
                  src="/assets/img/banners/banner-oragreen-pharmacy.jpg"
                  className="promotion-img"
                />
              </a>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div>
              <a href="https://najeebmarketing.com" target="_blank">
                <img
                  src="/assets/img/banners/banner-marketing-pharmacy.jpg"
                  className="promotion-img"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Promotions;
