/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Fragment, useEffect } from "react";
import { PageTitleArea } from "src/components";
import { useShops } from "src/redux/hooks";
import ContactForm from "./components/ContactForm";
import ContactUsMap from "./components/ContactUsMap";

const Contacts = () => {
  const { shops } = useShops();

  return (
    <Fragment>
      <PageTitleArea
        title="Contact Us"
        pages={[{ to: "/", title: "Home" }, { title: "Contact Us" }]}
      />
      <section className="contact-info-area pt-70 pb-40">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-contact-info-box">
                <div className="icon">
                  <i className="flaticon-placeholder"></i>
                </div>
                <h3>Address</h3>
                <p>
                  <a href="#" target="_blank">
                    Ground Floor, jamal plaza, F-10 Markaz, islamabd. Markaz,
                    Pakistan
                  </a>
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-contact-info-box">
                <div className="icon">
                  <i className="flaticon-phone-ringing"></i>
                </div>
                <h3>Phone</h3>

                <p>
                  <a href="tel:0512107676">Tech support: 051-2107676</a>
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-contact-info-box">
                <div className="icon">
                  <i className="flaticon-email"></i>
                </div>
                <h3>Whatsapp</h3>
                <p>
                  <a href="tel:0512107676">051-2107676</a>
                </p>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6 col-md-6">
              <div className="single-contact-info-box">
                <div className="icon">
                  <i className="flaticon-clock"></i>
                </div>
                <h3>Working Hours</h3>
                <p>24/7</p>
              </div>
            </div>
          </div>
          <div className="row">
            {shops.map((shop) => (
              <div className="col-lg-4 col-sm-6 col-md-6">
                <div className="single-contact-info-box">
                  <div className="icon">
                    <i className="flaticon-placeholder"></i>
                  </div>
                  <h3>{shop.storeName}</h3>
                  <p>
                    <a href="#">{shop.address}</a>
                  </p>
                  <abbr title="Phone">Phone:</abbr> {shop.phone}
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="contact-area pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="contact-form">
                <span className="sub-title">Get In Touch</span>
                <h2>We want to provide you with a great experience</h2>
                <ContactForm />
              </div>
            </div>

            <div className="col-lg-4 col-md-12">
              <div className="contact-image text-center">
                <img src="assets/img/contact.png" alt="image" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div id="maps">
        <ContactUsMap />
      </div>
    </Fragment>
  );
};

export default Contacts;
