import { IAxiosResponse, IOtp, IUser } from "src/models";
import axiosInstance from "./axios";

export default class AuthService {
  private static readonly url: string = "";
  public static async login(
    phone: string | number
  ): Promise<IAxiosResponse<IUser>> {
    const response = await axiosInstance.post(`/auth/signin`, {
      phone: phone,
    });
    return response.data;
  }

  public static async sendOTP(opt: IOtp): Promise<IAxiosResponse<any>> {
    const response = await axiosInstance.post(`/auth/otp`, opt);
    return response.data;
  }
}
