/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IAddress } from "src/models";
import { useAddress } from "src/redux/hooks";
import swal from "sweetalert";

const TabAddresses = () => {
  const { isLoaded, isLoggedIn, addresses, findAll, removeById } = useAddress();

  useEffect(() => {
    (async () => {
      if (isLoggedIn && !isLoaded) {
        await findAll();
      }
    })();
  }, [isLoggedIn, isLoaded]);

  const removeAddress = (address: IAddress) => {
    swal({
      title: `Are you sure to delete this address?`,
      text: address.address,
      icon: "warning",
      buttons: [true, true],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        await removeById(address.id, () => {
          swal(`Success! Your address has been removed!`, {
            icon: "success",
          });
        });
      }
    });
  };
  return (
    <div className="bhoechie-tab-content active">
      <div className="row">
      <div className="row" style={{ marginTop: 20 }}>
      {addresses.map((address) => (
        <div className="col-log-3 col-md-4 col-sm-6 col-xs-12">
          <div className="card">
            <div className="card-header">
              <h3 className="mb-0">
                {address.isDefault ? "Shipping Address" : "Address"}
              </h3>
            </div>
            <div className="card-body" key={address.id}>
              <address>
                {address.apartment} {address.address}
              </address>
              <p>{address.city}</p>
              <Link
                to={`edit/${address.id}`}
                className="btn-small"
              >
                Edit
              </Link>{" "}
              |{" "}
              <a onClick={() => removeAddress(address)} className="btn-small">
                Delete
              </a>
            </div>
          </div>
        </div>
      ))}
      <div
        className="col-log-3 col-md-4 col-sm-6 col-xs-12"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Link to="new" className="default-btn" >
          Add New Address
        </Link>
      </div>
    </div>
        {/* defaul address */}
      </div>
    </div>
  );
};

export default TabAddresses;
