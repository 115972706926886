/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Fragment } from "react";
import OwlCarousel from "react-owl-carousel";
import { ICategory } from "src/models";
import config from "src/config/config";
import { Link } from "react-router-dom";

interface IProps {
  categories: ICategory[];
}
const Categories: React.FC<IProps> = ({ categories }) => {
  return (
    <Fragment>
      {categories.map((category) => (
        <section className="categories-area pb-40" key={category.id}>
          <div className="container">
            <div className="section-title">
              <h2>{category.title}</h2>
            </div>
            <div className="products-slides owl-theme">
              <OwlCarousel
                items={6}
                margin={10}
                nav
                dots={false}
                draggable
                responsive={{
                  0: {
                    items: 1,
                  },
                  400: {
                    items: 2,
                  },
                  600: {
                    items: 2,
                  },
                  700: {
                    items: 3,
                  },
                  1000: {
                    items: 6,
                  },
                }}
              >
                {category.DrugSubCategories.map((subCategory) => (
                  <div
                    className="single-categories-box"
                    key={`${subCategory.title}-${subCategory.id}`}
                  >
                    <div style={{ minHeight: 130 }}>
                      <img
                        src={config.BASE_URL + subCategory.image.path}
                        alt="image"
                        className="category-image"
                      />
                    </div>
                    <h3>{subCategory.title}</h3>
                    <Link
                      to={
                        subCategory.id === 1
                          ? `/products`
                          : `/products?subcategory=${subCategory.id}`
                      }
                      className="link-btn"
                    ></Link>
                  </div>
                ))}
              </OwlCarousel>
            </div>
          </div>
        </section>
      ))}
    </Fragment>
  );
};

export default Categories;
