/* eslint-disable jsx-a11y/heading-has-content */
import React, { Fragment, Profiler, useEffect } from "react";
import { Outlet, Route, Routes, useLocation } from "react-router-dom";
import { Center, PageTitleArea } from "src/components";
import { useAuth, useOrders } from "src/redux/hooks";
import Tab from "./Tab";
import TabAddresses from "./TabAddresses";
import TabDashboard from "./TabDashboard";
import TabOrders from "./TabOrders";
import TabPrescriptions from "./TabPrescriptions";
import TabProfile from "./TabProfile";

const MyAccount = () => {
  const { logoutUser } = useAuth();
  const { pathname } = useLocation();
  useEffect(() => {
    // $(document).ready(function () {
    //   $("div.bhoechie-tab-menu>div.list-group>a").click(function (e) {
    //     e.preventDefault();
    //     $(this).siblings("a.active").removeClass("active");
    //     $(this).addClass("active");
    //     var index = $(this).index();
    //     $("div.bhoechie-tab>div.bhoechie-tab-content").removeClass("active");
    //     $("div.bhoechie-tab>div.bhoechie-tab-content")
    //       .eq(index)
    //       .addClass("active");
    //   });
    // });
  }, []);
  return (
    <Fragment>
      <PageTitleArea
        title="My Account"
        pages={[{ to: "/", title: "Home" }, { title: "My Account" }]}
      />
      <div className="container">
        <div className="row">
          <div className="bhoechie-tab-container">
            <div className="col-lg-2 col-md-2 col-sm-3 col-xs-3 bhoechie-tab-menu">
              <div className="list-group">
                <Tab
                  title="Dashboard"
                  icon="fa-solid fa-gauge"
                  to="dashboard"
                  isActive={
                    pathname === "/my-account/dashboard" ||
                    pathname === "/my-account"
                  }
                />
                <Tab
                  title="Profile"
                  icon="fa-solid fa-user"
                  to="profile"
                  isActive={pathname === "/my-account/profile"}
                />
                <Tab
                  title="Addresses"
                  icon="fa-solid fa-address-book"
                  to="addresses"
                  isActive={pathname.includes("/my-account/addresses")}
                />
                <Tab
                  title="Orders"
                  icon="fa-solid fa-list"
                  to="orders"
                  isActive={pathname.includes("/my-account/orders")}
                />
                <Tab
                  title="Prescription"
                  icon="fa-solid fa-list"
                  to="prescriptions"
                  isActive={pathname.includes("/my-account/prescriptions")}
                />
                <Tab
                  title="Logout"
                  icon="fa-solid fa-arrow-right-from-bracket"
                  onClick={() => logoutUser()}
                />
              </div>
            </div>
            <div className="col-lg-10 col-md-10 col-sm-9 col-xs-9 bhoechie-tab">
              <Outlet />
              {/* Dashboard section */}

              {/* Profile section */}

              {/* addresses search */}

              {/* Order section */}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MyAccount;
