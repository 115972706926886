import React from "react";
import { useContextProvider } from "src/provider/AppContextProvider";

const SearchOverlay = () => {
  const { showSearchOverlay, setShowSearchOverlay } = useContextProvider();
  return (
    <div
      className={`search-overlay ${
        showSearchOverlay ? "search-overlay-active" : ""
      }`}
    >
      <div className="d-table">
        <div className="d-table-cell">
          <div className="search-overlay-layer"></div>
          <div className="search-overlay-layer"></div>
          <div className="search-overlay-layer"></div>

          <div
            className="search-overlay-close"
            onClick={() => setShowSearchOverlay?.(false)}
          >
            <span className="search-overlay-close-line"></span>
            <span className="search-overlay-close-line"></span>
          </div>

          <div className="search-overlay-form">
            <form>
              <input
                type="text"
                className="input-search"
                placeholder="Search here..."
              />
              <button type="submit">
                <i className="bx bx-search-alt"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchOverlay;
