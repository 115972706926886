/* eslint-disable @typescript-eslint/no-unused-vars */
import { IRootState } from "..";
import { IHomeData } from "src/models";
import * as Types from "src/redux/types";

export default class HomeActions {
  public static load =
    (homeData?: IHomeData) =>
    async (
      dispatch: DispatchType<Action<IHomeData>>,
      getState: () => IRootState
    ) =>
      dispatch({
        type: Types.HOME_DATA_LOAD,
        payload: homeData,
      });
}
