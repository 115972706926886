/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Fragment } from "react";
import OwlCarousel from "react-owl-carousel";

import { IDrug } from "src/models";
import config from "src/config/config";
import { SingleDrugBox } from "src/items";

interface IProps {
  drugs: IDrug[];
  title: string;
}

const OfferArea: React.FC<IProps> = ({ title, drugs }) => {
  return (
    <Fragment>
      <section className="products-area pb-40">
        <div className="container">
          <div className="section-title">
            <h2>{title}</h2>
          </div>

          <div className="products-slides owl-theme">
            <OwlCarousel
              items={4}
              nav
              dots={false}
              margin={10}
              responsive={{
                0: {
                  items: 1,
                },
                400: {
                  items: 2,
                },
                600: {
                  items: 2,
                },
                700: {
                  items: 3,
                },
                1000: {
                  items: 4,
                },
              }}
            >
              {drugs?.map((drug) => (
                <SingleDrugBox drug={drug} key={drug.id} />
              ))}
            </OwlCarousel>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default OfferArea;
