/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
import QueryString from "qs";
import React, { Fragment, useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Empty, Loading, PageTitleArea, Pagination } from "src/components";
import Utils from "src/helper";
import { SingleDrugBox } from "src/items";
import { useBrands, useDrugs } from "src/redux/hooks";
import Promotions from "../Home/components/Promotions";

const Shop = () => {
  const { search } = useLocation();
  const { id } = useParams();
  const { loadTopBrands, isTopBrandLoaded, topBrands } = useBrands();
  const {
    findAllByPagination,
    currentPage,
    totalItems,
    hasNextPage,
    hasPrevPage,
    pageSize,
    pageSizes,
    setPageSize,
    drugs,
    loading,
  } = useDrugs();

  const loadPageData = async (page: number) => {
    window.scrollTo(200, 200);
    const searchText = getSearchText();
    const brandid = getBrandId();
    const subcategoryid = getSubCategoryId();
    await findAllByPagination(
      page,
      pageSize,
      brandid,
      subcategoryid,
      searchText
    );
  };

  useEffect(() => {
    (async () => {
      if (!isTopBrandLoaded) {
        await loadTopBrands(40);
      }
    })();
  }, [isTopBrandLoaded]);

  useEffect(() => {
    (async () => {
      window.scrollTo(200, 200);
      const brandid = getBrandId();
      const searchText = getSearchText();
      const subcategoryid = getSubCategoryId();
      await findAllByPagination(
        currentPage,
        pageSize,
        brandid,
        subcategoryid,
        searchText
      );
    })();
  }, [currentPage, pageSize, search]);

  const getSearchText = (): string => {
    const searchQuery = Utils.getQueryParamsObj("search", search);
    const searchText = searchQuery !== undefined ? searchQuery.toString() : "";
    return searchText;
  };

  const getBrandId = (): number => {
    const brandid: any = parseInt(
      "" + Utils.getQueryParamsObj("brand", search)
    );
    return brandid ? brandid : 0;
  };

  const getSubCategoryId = (): number => {
    const id: any = parseInt(
      "" + Utils.getQueryParamsObj("subcategory", search)
    );
    return id ? id : 0;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [id]);
  return (
    <Fragment>
      <PageTitleArea
        title="Shop"
        pages={[{ to: "/", title: "Home" }, { title: "Shop" }]}
      />
      <section className="products-area ptb-70">
        <div className="container">
          <Promotions />
          <div className="row">
            <div className="col-lg-3 col-md-12">
              <div className="woocommerce-widget-area">
                <div className="woocommerce-widget brands-list-widget">
                  <h3 className="woocommerce-widget-title">Brands</h3>

                  <ul className="brands-list-row">
                    {topBrands.map((brand) => (
                      <li
                        key={brand.id}
                        className={getBrandId() === brand.id ? "active" : ""}
                      >
                        <Link to={`/products?brand=${brand.id}`}>
                          {brand.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-9 col-md-12">
              <div className="drodo-grid-sorting row align-items-center">
                <div className="col-lg-6 col-md-6 result-count">
                  <p>
                    We found <span className="count">{totalItems}</span> drugs
                    available for you
                  </p>

                  <span className="sub-title d-lg-none">
                    <a
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#productsFilterModal"
                    >
                      <i className="bx bx-filter-alt"></i> Filter
                    </a>
                  </span>
                </div>

                <div className="col-lg-6 col-md-6 ordering">
                  <div className="select-box">
                    <label>Sort By:</label>
                    <select>
                      <option>Default</option>
                      <option>Popularity</option>
                      <option>Latest</option>
                      <option>Price: low to high</option>
                      <option>Price: high to low</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="row">
                {loading ? (
                  <Loading />
                ) : (
                  drugs.map((drug) => (
                    <div className="col-lg-3 col-md-4 col-sm-6" key={drug.id}>
                      <SingleDrugBox drug={drug} />
                    </div>
                  ))
                )}

                <div className="col-lg-12 col-md-12">
                  <Pagination
                    onPageChange={(page) => loadPageData(page)}
                    totalCount={totalItems}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    hasPrevPage={hasPrevPage}
                    hasNextPage={hasNextPage}
                    siblingCount={3}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Shop;
