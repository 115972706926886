/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import config from "src/config/config";
import Utils from "src/helper";
import { IDrug, ISaleType } from "src/models";
import { useContextProvider } from "src/provider/AppContextProvider";
import AddToCart from "../AddToCart";
import Error404 from "../Error404";
import PriceText from "../PriceText";

interface IProps {
  drug: IDrug;
}

const ModalContent: React.FC<IProps> = ({ drug }) => {
  const { id, name, images, DrugSales, DrugBrands, DrugType } = drug;
  const [selectedSaleType, setSelectedSaleType] = useState<ISaleType>(
    DrugSales[0]
  );
  const imageUrl = config.BASE_URL + images[0]?.path;
  const linkTo = `/products/${id}`;
  useEffect(() => {
    setSelectedSaleType(drug.DrugSales[0]);
  }, [drug]);

  return (
    <div className="modal-content">
      <button
        type="button"
        className="close"
        data-bs-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">
          <i className="bx bx-x"></i>
        </span>
      </button>

      <div className="row align-items-center">
        <div className="col-lg-6 col-md-6">
          <div className="products-image">
            <img src={imageUrl} alt={name} />
          </div>
        </div>

        <div className="col-lg-6 col-md-6">
          <div className="products-content">
            <h3>
              <Link to={linkTo}>{drug?.name}</Link>
            </h3>

            <PriceText saleType={selectedSaleType} />

            <div className="products-review">
              <div className="rating">
                <i className="bx bxs-star"></i>
                <i className="bx bxs-star"></i>
                <i className="bx bxs-star"></i>
                <i className="bx bxs-star"></i>
                <i className="bx bxs-star"></i>
              </div>
              <a href="#" className="rating-count">
                3 reviews
              </a>
            </div>

            <ul className="products-info">
              <li>
                <span>Brand:</span>{" "}
                <Link to={`/shop?brand=${DrugBrands[0].id}`}>
                  {DrugBrands[0].title}
                </Link>
              </li>
              <li>
                <span>Availability:</span> Available
              </li>
              <li>
                <span>Products Type:</span>{" "}
                <Link to={`/shop?type=${DrugType.id}`}>{DrugType.title}</Link>
              </li>
            </ul>

            <div className="products-size-wrapper">
              <h4>Sale Type:</h4>

              <ul>
                {DrugSales.map((sale) => (
                  <li
                    key={sale.id}
                    onClick={() => setSelectedSaleType(sale)}
                    className={sale.id === selectedSaleType.id ? "active" : ""}
                  >
                    <a style={{ cursor: "pointer" }}>
                      {sale.title.toLocaleUpperCase()}
                    </a>
                  </li>
                ))}
              </ul>
            </div>

            <AddToCart drug={drug} saleType={selectedSaleType} />

            <Link to={linkTo} className="view-full-info">
              or View Full Info
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProductQuickViewModal: React.FC = () => {
  const { drug } = useContextProvider();

  return (
    <div
      className={`modal fade productsQuickView`}
      id="productsQuickView"
      tabIndex={-1}
      role="dialog"
      aria-hidden={"false"}
      aria-modal={"true"}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        {drug ? (
          <ModalContent drug={drug} />
        ) : (
          <div className="modal-content">
            {" "}
            <Error404 />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductQuickViewModal;
