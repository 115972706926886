/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  StandaloneSearchBox,
  Marker,
  LoadScript,
  InfoWindow,
  useLoadScript,
  useJsApiLoader,
} from "@react-google-maps/api";
import { IAddress } from "src/models";
import Geocode from "react-geocode";
import Loading from "../Loading";

interface ILatLng {
  lat: number;
  lng: number;
}

interface IProps {
  defaultAddrress?: IAddress;
  onAddressChange?: (address: IAddress) => void;
}
const key = "AIzaSyBUQd4IMK4AWYpWZvlLqnUiy_grOFO1jy8";
let isCurrentLocationAddressFound: boolean = false;
Geocode.setApiKey(key);
Geocode.setLanguage("en");
Geocode.setRegion("pk");
Geocode.enableDebug();
const MapComponent2: React.FC<IProps> = ({
  defaultAddrress,
  onAddressChange,
}) => {
  const [isMounted, setIsMounted] = useState(false);

  const infoWindowRef = useRef<any>();
  const mapRef = useRef<any>();
  const [currentLocation, setCurrentLocation] = useState<ILatLng>({
    lat: 0,
    lng: 0,
  });
  const [bounds, setBounds] = useState<google.maps.LatLngBounds>();
  const [searchBox, setSearchBox] = useState<google.maps.places.SearchBox>();
  const [showInfoWindow, setShowInfoWindow] = useState<boolean>(false);
  const [currentAddress, setCurrentAddress] = useState<IAddress>();
  const [isCurrentLocationUpdates, setIsCurrentLocationUpdates] =
    useState<boolean>(true);

  useEffect(() => setIsMounted(true), []);

  const getCurrentLocationUpdates = () => {
    if (isCurrentLocationUpdates)
      navigator?.geolocation.getCurrentPosition(
        ({ coords: { latitude: lat, longitude: lng } }) => {
          const pos = { lat, lng };
          setCurrentLocation(pos);
          getReverseGeocodingData(pos.lat, pos.lng);
        }
      );
  };

  useEffect(() => {
    if (defaultAddrress) {
      setCurrentLocation({
        lat: defaultAddrress.latitude,
        lng: defaultAddrress.longitude,
      });
      setCurrentAddress({ ...defaultAddrress });
      if (defaultAddrress.latitude !== 0 && defaultAddrress.longitude !== 0) {
        setIsCurrentLocationUpdates(false);
      }
    }

    return () => {
      setCurrentLocation({ lat: 0, lng: 0 });
    };
  }, [defaultAddrress]);

  const onPlacesChanged = () => {
    if (searchBox) {
      let results = searchBox.getPlaces() || [];
      for (let i = 0; i < results.length; i++) {
        let place = results[i]?.geometry?.location;
        let address = results[i]?.formatted_address || "";
        const data = getCityStateCountryFromAddressResult(results[0]);
        if (place) {
          onAddressChange?.({
            ...defaultAddrress!,
            latitude: place.lat(),
            longitude: place.lng(),
            city: data.city,
            area: data.city,
            address,
          });
        }
      }
    }
  };

  const onMapClick = async (e: google.maps.MapMouseEvent) => {
    if (e.latLng) {
      setIsCurrentLocationUpdates(false);
      const latLng = {
        lat: e.latLng.lat(),
        lng: e.latLng.lng(),
      };

      setCurrentLocation(latLng);

      //getReverseGeocodingData(latLng.lat, latLng.lng);
      getReverseGeocodingData(latLng.lat, latLng.lng);
      // await getGeocodeAddress(latLng);
    }
  };

  const getReverseGeocodingData = (lat: number, lng: number) => {
    var latlng = new google.maps.LatLng(lat, lng);
    // This is making the Geocode request
    var geocoder = new google.maps.Geocoder();
    geocoder.geocode({ latLng: latlng } as any, (results, status) => {
      if (status !== google.maps.GeocoderStatus.OK) {
        alert(status);
      }
      // This is checking to see if the Geoeode Status is OK before proceeding
      if (status === google.maps.GeocoderStatus.OK && results !== null) {
        var address = results[0].formatted_address;

        const data = getCityStateCountryFromAddressResult(results[0]);
        onAddressChange?.({
          ...defaultAddrress!,
          latitude: lat,
          longitude: lng,
          city: data.city,
          area: data.city,
          address,
        });
      }
    });
  };

  const getCityStateCountryFromAddressResult = (
    result: any
  ): { city: string; state: string; country: string } => {
    let city = "",
      state = "",
      country = "";
    for (let i = 0; i < result.address_components.length; i++) {
      for (let j = 0; j < result.address_components[i].types.length; j++) {
        switch (result.address_components[i].types[j]) {
          case "locality":
            city = result.address_components[i].long_name;
            break;
          case "administrative_area_level_1":
            state = result.address_components[i].long_name;
            break;
          case "country":
            country = result.address_components[i].long_name;
            break;
        }
      }
    }
    return { city, state, country };
  };

  const onInfoWindowContentChanged = () => {};

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: key,
    libraries: ["places"],
  });

  const [map, setMap] = React.useState<google.maps.Map>();

  const onLoad = React.useCallback(function callback(map: google.maps.Map) {
    const bounds = new window.google.maps.LatLngBounds(currentLocation);
    map.fitBounds(bounds);
    google.maps.event.addListener(map, "bounds_changed", () => {
      setBounds(map.getBounds());
    });
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: google.maps.Map) {
    setMap(undefined);
  }, []);

  console.log("currentLocation", currentLocation);
  return isLoaded ? (
    <div>
      <div id="searchbox">
        <StandaloneSearchBox
          onLoad={(searchBox) => setSearchBox(searchBox)}
          onPlacesChanged={onPlacesChanged}
          bounds={bounds}
        >
          <input
            type="text"
            placeholder="Customized your placeholder"
            style={{
              boxSizing: `border-box`,
              border: `1px solid transparent`,
              width: `91%`,
              height: `40px`,
              padding: `0 12px`,
              borderRadius: `3px`,
              boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
              fontSize: `14px`,
              outline: `none`,
              textOverflow: `ellipses`,
              marginRight: 20,
            }}
          />
        </StandaloneSearchBox>
      </div>
      <br />
      <div style={{ marginTop: 40 }}>
        <GoogleMap
          ref={mapRef}
          center={currentLocation}
          zoom={18}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onClick={onMapClick}
          mapContainerStyle={{ height: "400px", width: "100%" }}
        >
          <>
            {currentLocation && (
              <Marker
                position={currentLocation}
                onClick={() => setShowInfoWindow(!showInfoWindow)}
              />
            )}
            {showInfoWindow === true && (
              <InfoWindow
                ref={infoWindowRef}
                onContentChanged={onInfoWindowContentChanged}
                position={currentLocation}
                onCloseClick={() => setShowInfoWindow(false)}
              >
                <div>
                  <h5>
                    <b>{currentAddress?.name}</b>
                  </h5>
                  <p>
                    <strong>Address : </strong>
                    {currentAddress?.address}
                  </p>
                  <p>
                    <strong>Phone : </strong>
                    {currentAddress?.phone}
                  </p>
                  <p>
                    <strong>Email : </strong>
                    {currentAddress?.email}
                  </p>
                </div>
              </InfoWindow>
            )}
          </>
          {/* {this.state.markers.map((mark, index) => (
            <Marker key={index} position={mark} />
          ))} */}
        </GoogleMap>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

export default memo(MapComponent2);
