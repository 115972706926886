import React from "react";

interface IProps {
  title: string;
  icon: string;
  isActive?: boolean;
}

const TrackStep: React.FC<IProps> = ({ title, icon, isActive }) => {
  const classNames = ["step"];
  if (isActive) {
    classNames.push("active");
  }
  return (
    <div className={classNames.join(" ")}>
      <span className="icon">
        <i className={`fa ${icon}`}></i>
      </span>
      <span className="text">{title}</span>
    </div>
  );
};

export default TrackStep;
