/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { AddToCartButton, FavoriteButton, PriceText } from "src/components";
import config from "src/config/config";
import Utils from "src/helper";
import { IDrug, ISaleType } from "src/models";
import { useContextProvider } from "src/provider/AppContextProvider";

interface IProps {
  drug: IDrug;
}

const SingleDrugBox: React.FC<IProps> = ({ drug }) => {
  const { id, DrugSales, images, name, DrugType, measurement } = drug;
  const imageUrl = config.BASE_URL + images[0]?.path;
  const image = new Image();
  image.src = imageUrl;
  const width = image.height > image.width ? "40%" : "70%";
  const { setDrug } = useContextProvider();
  const [selectedSaleType, _] = useState<ISaleType>(DrugSales[0]);
  const { discount } = selectedSaleType;
  const linkTo = `/products/${id}`;

  return (
    <div className="single-products-box">
      <div className="image">
        <Link
          to={linkTo}
          onClick={() => {
            setDrug?.(drug);
          }}
          className="image-container"
        >
          <img
            src={Utils.getImagesPath(images)}
            alt={name}
            className="product-image"
            style={{ width: "80%", maxHeight: 200, objectFit: "contain" }}
          />
        </Link>

        {discount > 0 && (
          <div className="new">{Utils.roundTo1Digit(discount)}% off</div>
        )}

        <div className="buttons-list">
          <ul>
            <li>
              <AddToCartButton drug={drug} />
            </li>
            <li>
              <FavoriteButton drug={drug} />
            </li>
            <li>
              <div className="quick-view-btn">
                <a
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#productsQuickView"
                  onClick={() => {
                    setDrug?.(drug);
                  }}
                >
                  <i className="bx bx-search-alt"></i>
                  <span className="tooltip-label">Quick View</span>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div className="content">
        <h6>
          <Link
            to={linkTo}
            onClick={() => {
              setDrug?.(drug);
            }}
          >
            {name} {DrugType.title} {measurement}
          </Link>
        </h6>
        <PriceText saleType={selectedSaleType} />
      </div>
    </div>
  );
};

export default SingleDrugBox;
