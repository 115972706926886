import React from 'react'
import config from 'src/config/config';
import { useContextProvider } from 'src/provider/AppContextProvider'

const PrescriptionModalDetail = () => {
    const {prescription} = useContextProvider()
    const imageUrl = config.BASE_URL + prescription?.image?.path;
  return (
    <div
      className={`modal fade productsQuickView`}
      id="prescriptionDetailModal"
      tabIndex={-1}
      role="dialog"
      aria-hidden={"false"}
      aria-modal={"true"}
      style={{width:'50%', transform: 'translate(-50%, -50%)', top:'50%', left:'50%'}}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <button
            type="button"
            className="close"
            data-bs-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">
              <i className="bx bx-x"></i>
            </span>
          </button>

          <div className="modal-body">
          <div className="row align-items-center">
        
          <div className="products-image">
            <img src={imageUrl} alt={'prescription image'} style={{width:'100%'}}/>
         
        </div>
        
        </div>
            </div>
        </div>
      </div>
    </div>
   
            
  )
}

export default PrescriptionModalDetail