/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import { Link } from "react-router-dom";

const Error404 = () => {
  return (
    <section className="error-area ptb-70">
      <div className="container">
        <div className="error-content">
          <img src="/assets/img/error.png" alt="image" />
          <h3>Error 404 : Page Not Found</h3>
          <p>
            The page you are looking for might have been removed had its name
            changed or is temporarily unavailable.
          </p>
          <Link to="/" className="default-btn">
            <i className="flaticon-left-chevron"></i> Back to Homepage
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Error404;
