import { IDrugPagination } from "src/models";
import * as Types from "src/redux/types";

type State = {
  pageData: {};
  totalItems: number;
  totalPages: number;
  currentPage: number;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  nextPage: number | null;
  prevPage: number | null;
  pageSize: number;
};

const initialState: State = {
  pageData: {},
  totalItems: 0,
  totalPages: 0,
  currentPage: 1,
  hasNextPage: true,
  hasPrevPage: false,
  nextPage: 2,
  prevPage: null,
  pageSize: 12,
};

const DrugPaginationReducer = (
  state = initialState,
  action: Action<IDrugPagination | number>
): State => {
  const { type, payload } = action;
  switch (type) {
    case Types.DRUG_PAGE_LOAD: {
      const data = payload as IDrugPagination;
      const {
        totalItems,
        totalPages,
        currentPage,
        hasNextPage,
        hasPrevPage,
        nextPage,
        prevPage,
        pageSize,
      } = data;
      return {
        ...state,
        totalItems,
        totalPages,
        currentPage,
        hasNextPage,
        hasPrevPage,
        nextPage,
        prevPage,
        pageSize,
        pageData: {
          ...state.pageData,
          [data.currentPage]: [...data.drugs],
        },
      };
    }

    default:
      return state;
  }
};

export default DrugPaginationReducer;
