import React from "react";
import Lottie, { Options } from "react-lottie";
import animationData from "./loading.json";

const Loading = () => {
  const defaultOptions: Options = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return <Lottie options={defaultOptions} height={400} width={400} />;
};

export default Loading;
