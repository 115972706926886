import React from "react";
import Utils from "src/helper";
import { ISaleType } from "src/models";

interface IProps {
  saleType: ISaleType;
}

const PriceText: React.FC<IProps> = ({ saleType }) => {
  const { price, discount, title } = saleType;
  return (
    <div className="price">
      {saleType.discount > 0 && (
        <span className="old-price" style={{ textDecoration: "line-through" }}>
          Rs{price}
        </span>
      )}{" "}
      <span className="new-price">
        Rs
        {Utils.getDicountPrice(price, discount)} / {title}
      </span>
    </div>
  );
};

export default PriceText;
