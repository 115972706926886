import React, { Fragment, useEffect, useState } from "react";
import logo from "./logo.svg";

import "./App.css";

import AppRoutes from "./routes";
import { useDeliveryCharges } from "./redux/hooks";
import { BrowserRouter } from "react-router-dom";

function App() {
  //const history = createBrowserHistory()
  const { getDeliveryCharges, isLoaded } = useDeliveryCharges();

  useEffect(() => {
    (async () => {
      if (!isLoaded) {
        await getDeliveryCharges();
      }
    })();
  }, [isLoaded]);
  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  );
}

export default App;
