import { useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "src/redux";
import { CartActions } from "src/redux/actions";
import { DrugService } from "src/redux/services";
import { ICartItem, IDrug, IOrder, IOrderItem } from "src/models";
import Utils from "src/helper";
const useCart = () => {
  const dispatch = useDispatch();
  const {
    pharmWebsiteAuth: { isLoggedIn, user },
    deliveryCharges: { deliveryCharge },
    pharmCart: { isLoaded, items },
  } = useAppSelector((state) => state);
  const [loading, setLoading] = useState<boolean>(false);

  const findAll = async () => {
    try {
      setLoading(true);
      const ids = items.map((item) => item.drug.id);
      const response = await DrugService.findAllDrugs(ids);
      const products = response.response;
      if (products) {
        dispatch(
          CartActions.load(products.map((p) => getCartItem(items, p))) as any
        );
      }
    } catch (err: any) {
      console.log(err);
      // if (err && err.response && err.response.data) {
      //   setError(err.response.data);
      // } else {
      //   setError({
      //     message: err.message,
      //   });
      // }
    } finally {
      setLoading(false);
    }
  };

  const clear = () => {
    dispatch(CartActions.clear() as any);
  };

  const addCartItem = (cartItem: ICartItem) => {
    dispatch(CartActions.addItem(cartItem) as any);
  };

  const updateCartItem = (cartItem: ICartItem) => {
    dispatch(CartActions.updateItem(cartItem) as any);
  };

  const getCartItem = (items: ICartItem[], drug: IDrug): ICartItem => {
    const cartItem = items.find((item) => item.drug.id === drug.id);
    return {
      ...cartItem!,
      drug: drug,
    };
  };

  let total = 0;
  items.map(({ saleType: { discount, price }, quantity }) => {
    total += Utils.getDicountPrice(price, discount) * quantity;
  });

  const removeItem = (id: number) => {
    dispatch(CartActions.removeItem(id) as any);
  };

  const isItemExistInCart = (id: number): boolean => {
    return items.find((item) => item.drug.id === id) !== undefined;
  };

  const deliveryChargeMessage =
    total >= deliveryCharge.minAmount
      ? ""
      : `Free Delivery On Shoppin Of Rs ${deliveryCharge.minAmount}`;
  const deliveryChargeAmount =
    total >= deliveryCharge.minAmount ? 0 : deliveryCharge.charges;

  const orderItems: IOrderItem[] = items.map((item) => ({
    Drug: item.drug,
    DrugId: item.drug.id,
    discount: item.saleType.discount,
    price: item.saleType.price,
    quantity: item.quantity,
    saleType: item.saleType.title,
  }));

  return {
    loading,
    isLoggedIn,
    isLoaded,
    items,
    size: items.length,
    isEmpty: items.length === 0,
    total,
    deliveryChargeMessage,
    deliveryChargeAmount,
    isItemExistInCart,
    clear,
    findAll,
    addCartItem,
    updateCartItem,
    removeItem,
    orderItems,
  };
};

export default useCart;
